import Vue from 'vue';
const { validationMixin, default: Vuelidate } = require('vuelidate');
const { required } = require('vuelidate/lib/validators');

Vue.use(Vuelidate);

import datePicker from '../../components/forms/component.date-picker.vue';
import {
    onlyNumbers,
    monthDate,
    isAfterField,
    numbersWithDashSlash,
    noAlphabets,
    numbersWithComma,
    checkAgent
} from '../../helper-functions/helper-functions';
import {
    createUpdateQuoteService,
    initializeFormFromStorage,
    checkExpiredAndTriggerValidation,
    getItemSessionStorage,
    removeKeySessionStorage
} from '../../helper-functions/services';

import { LoginModalMixin } from '../../section-specific/applications/mixins/loginModal';
import { QuoteFormMixin } from '../quote-form/';


Vue.component('quote-form-trip', {    
    components: {
        'date-picker': datePicker
    },
    props: ['ss-criteria', 'is-results-page'],
    mixins: [LoginModalMixin, QuoteFormMixin],
    data: function () {
        return {
            form: {
                citizenshipCountry: '',
                departureDate: '',
                finalPaymentType: '',
                initialTripDepositDate: '',
                primaryDestination: '',
                region: '',
                residenceCountry: 'USA',
                returnDate: '',
                travelerInfos: [
                    {
                        age: null,
                        birthdate: '',
                        tripCost: null
                    }
                ],
                section: window.globalLanguage.section || ''
            },
            formSending: false,
            statesShown: false,
            showTravelers: false,
            travelersOpened: false,
            showByAge: true,
            showAgeSwitch: false,
            showBdSwitch: false,
            travelerLimit: 100,
            showMobileError:false,
            showTripDeposit: false,
            showLoginModalComponent: false
        }
    },
    validations: {
        form: {
            citizenshipCountry: {
                required
            },
            departureDate: {
                required,
                monthDate,
                numbersWithDashSlash,
                noAlphabets
            },
            finalPaymentType: {
                required
            },
            initialTripDepositDate: {
                monthDate,
                required,
                numbersWithDashSlash,
                noAlphabets
            },
            primaryDestination: {
                required
            },
            region: {
                required
            },
            residenceCountry: {
                required
            },
            returnDate: {
                monthDate,
                required,
                numbersWithDashSlash,
                noAlphabets
            },
            travelerInfos: {
                required,
                $each: {
                    age: {
                        required,
                        onlyNumbers
                    },
                    tripCost: {
                        required,
                        numbersWithComma
                    }
                }
            }
        }
    },
    watch: {
        ssCriteria: function (val) {
            this.form = JSON.parse(JSON.stringify(val));
            this.travelersOpened = true;

            if (val.residenceCountry === 'USA') {
                this.statesShown = true;
            }
        }
    },
    created: function() {
        var self = this;
        
         //Add Additionals for - Different plan same person - MyAccount re-purchase
         if(window.ibJS.travellerDetails && window.ibJS.travellerDetails.length) {
            this.form.travelerInfos = [];
            window.ibJS.travellerDetails.map((item, index) => {
                this.form.travelerInfos.push(
                        {   age: (item && item.age) ? item.age : null, 
                            birthdate: item.birthDate ? item.birthDate : '', 
                            tripCost: null
                        }
                    );
            });
            self.travelersOpened = true;
        }

        initializeFormFromStorage.call(this, window.globalLanguage.sectionCriteriaKey, function () {
            self.travelersOpened = true;
        });

        checkExpiredAndTriggerValidation.call(this);

        if (self.form.region && self.form.region != 'OT') {
            self.statesShown = true;
        }

        $(document).ready(function () {
            $('.o-Form').on('click', function () {
                self.showTravelers = false;
                self.showTripDeposit = false;
            });

            $('.js-Stop-propagation').on('click', function (e) {
                e.stopPropagation();
            });
        });

        // when coming form myAccount - see MYACCOUNT-117
        if(window.ibJS && window.ibJS.age && !window.ibJS.travellerDetails && self.form.travelerInfos && Array.isArray(self.form.travelerInfos) && self.form.travelerInfos.length) {
            self.form.travelerInfos[0].age = window.ibJS.age;
            self.travelersOpened = true;
        }
        if(window.ibJS && window.ibJS.citizenship) {
            self.form.citizenshipCountry = window.ibJS.citizenship;
        }
        
        // set message if expiredDate is set to true by submitting the form on creation
        try{            
            if(window.sessionStorage.getItem('expiredDate') == 'true'){
                window.sessionStorage.removeItem('expiredDate');
                createUpdateQuoteService.call(this, window.ibJS.quoteResultsAPIPath, this.form, window.globalLanguage.sectionCriteriaKey, window.ibJS.quoteResultsPagePath);
            }
        } catch(e) {
            createUpdateQuoteService.call(this, window.ibJS.quoteResultsAPIPath, this.form, window.globalLanguage.sectionCriteriaKey, window.ibJS.quoteResultsPagePath);
        }

        // Quote form modal listener - Age block
        this.$root.$on('set-quote-modal-trip-age', (obj, skip) => {
            if (!skip) this.form.travelerInfos = obj;
            this.showTravelers = false;
            document.querySelector('#CountryOfCitizenship').focus();
        })

        const residentCountry = getItemSessionStorage('residenceCosta');
        const destiCountry = getItemSessionStorage('destiCosta');
        //usa is default country
        if (residentCountry || this.form.residenceCountry === 'USA') {
            this.form.residenceCountry = 'USA' ;
            this.checkIfUSA();
        }
        if (destiCountry) {
            this.form.primaryDestination = 'CRI';
        }
        removeKeySessionStorage('residenceCosta');
        removeKeySessionStorage('destiCosta');
    },
    mounted: function() {
        if (window.ibJS && typeof window.ibJS.isResultsPage === 'undefined') {
            checkAgent.call(this, [false]);
        }
    },    
    methods: {
        checkIfUSA: function () {
            var self = this;
            this.$v.form.residenceCountry.$touch();

            if (this.form.residenceCountry === 'USA') {
                this.form.region = '';
                this.statesShown = true;
            } else if (this.form.residenceCountry === '') {
                this.form.region = '';
                this.statesShown = false;
            } else {
                this.form.region = 'OT';
                this.statesShown = false;
            }
        },
        createQuote: function (e) {
            e.preventDefault();

            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.form.travelerInfos = this.form.travelerInfos.filter((item)=>{
                    if(item.birthdate) item.birthYear = parseInt(item.birthdate.split("/")[2]);
                    if(typeof item.tripCost === "string") item.tripCost = item.tripCost.replace(/,/g,'');
                    return item;
                });
                createUpdateQuoteService.call(this, window.ibJS.quoteResultsAPIPath, this.form, window.globalLanguage.sectionCriteriaKey, window.ibJS.quoteResultsPagePath);
                this.$emit('close-edit-quote');
                
                if (ibJS.fullQuoteId) {
                    this.$http.post('/api/myaccount/remove-dup-quote/', {fullQuoteId: ibJS.fullQuoteId || ''});
                }
            } else{
                this.showMobileError = true;
            }
        },
        toggleTravelers: function () {
            this.showTripDeposit = false

            this.showTravelers = true;
            this.travelersOpened = true;
            this.$root.$emit('quote-modal-trip-age', JSON.parse(JSON.stringify(this.form)));
        },
        toggleAge: function() {
            this.showAgeSwitch = false;
            this.showBdSwitch = false;
            this.showByAge = !this.showByAge;
        },
        addTraveler: function() {
            var newTraveler = {
                tripCost: null,
                age: null,
                birthdate: ''
            };

            this.form.travelerInfos.push(newTraveler);
        },
        deleteTraveler: function($index) {
            this.form.travelerInfos.splice($index, 1);
        },
        setAge: function (index) {
            if (this.form.travelerInfos && Array.isArray(this.form.travelerInfos) && this.form.travelerInfos.length) {
                this.form.travelerInfos[index].age = this.getAge(this.form.travelerInfos[index].birthdate);
                this.$v.form.travelerInfos.$each[index].age.$touch();
            }
        },
        getAge: function (dateString) {
            // this function will need to be internationalized when needed in other languages using our method createDateByLangStr in helper functions
            // this will also eventually be moved to helper functions
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
            {
              age--;
            }
            if ((typeof age === "number") && (Math.floor(age) === age) && (0 <= age && age < 200)) {
                return age;
            } else {
                return null;
            }
        },
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
        resetForm: function(e){
            this.showMobileError=false;
        	this.form.primaryDestination='';
            this.form.departureDate='';
            this.form.returnDate='';
            this.form.initialTripDepositDate='';
            this.form.finalPaymentType='';
            this.form.citizenshipCountry='';
            this.form.residenceCountry='USA';
            this.form.region='';
            this.form.travelerInfos= [
                    {
                        age: null,
                        birthdate: '',
                        tripCost: null
                    }
                ];
            this.travelersOpened=false;
            this.$v.$reset();
            this.$root.$emit('reset-quote-modal-trip');
            
            if(this.form.residenceCountry === 'USA'){
                this.checkIfUSA();
            }
            e.preventDefault();
        },
        toggleTripDeposit: function () {
            // Close the other container if open.
            this.showTravelers = false;

            this.showTripDeposit = !this.showTripDeposit;
        },
        resetAgeValidation: function(index){
            if(this.form?.travelerInfos && this.form.travelerInfos.length && this.form.travelerInfos[index] && !(/^\d+$/.test(this.form.travelerInfos[index].age))){
                this.form.travelerInfos[index].age=null;
            }
        }
    }
});

