import datePicker from '../../../components/forms/component.date-picker.vue';
import contactTime from '../../../components/forms/component.contact-time.vue';
import {
    monthDate,
    onlyAlphaNumeric,
    onlyAlpha,
    onlyNumbers,
    getCategoryName,
    convertDateString,
    atleastOneNumber,
    matchTimezone,
    getCountriesFromStorage
} from '../../../helper-functions/helper-functions';
import {
    setItemSessionStorage
} from '../../../helper-functions/services';
import {ibSpinOpts} from '../../../helper-functions/applicationCommonValues';
import OfficeHours from './office-hours';

import PhoneCode from '../../../components/forms/component.phone-code.vue';

require("../../../third-party/select2");
require('../directives/select2');

import {
    setCookieItem,
    getCookieItem
} from '../../../helper-functions/services';

let goAwayEventData = {
    errors: [],
    messageSent: false,
    length: 0,
    htmlEvent: null,
    goawayLabels: {},
    // userPickShowModal: false,
    form: {
        date: '',
        time: '',
        amPM: 'AM',
        timezone: '',
        country: 'USA',
        isdCode: '1',
        phoneNumber: '',
        countryCode: 'USA'
    },
    phnCodeId: '',
    phnCodeParentId: '',
    qResultsLang: window.qResultsLang,
    contactUs: {
        firstName: '',
        lastName: '',
        companyName: '',
        addressStreet1: '',
        addressStreet2: '',
        city: '',
        usState: '',
        postalCode: '',
        country: '',
        phone: '',
        fax: '',
        email: '',
        bestReachTime: '',
        websiteAddr: '',
        queryType: '',
        queryTypeDetail: '',
        purpose: '',
        comments: '',
        verificationCodeUser: ''
    },
    isTouched: false,
    formSending: false,
    allCountries: ibJS.countries ? ibJS.countries : [],
    popularCountries: ibJS.popularCountries ? ibJS.popularCountries : [],
    phoneNumValidated: false,
    phoneNumResch: false,
    phoneReschDetails: {
        time: null,
        timezone: null
    },
    timefetched: false
};

let goAwayEventWhileMounted = function () {
    let affiliateType = window.isHelperVisible;
    // let $isAlreayRegistered = getItemSessionStorage.call(this, 'captureModalEvent');
    let $isAlreayRegistered = getCookieItem('captureModalEvent') === 'true';
    let isApplicationStep6 = window.ibJS.application && window.isApplication ? (window.ibJS.application.currentStep == 6 || window.currentStep == 6) : false;
    let isApplication = window.ibJS.application && window.isApplication ? true : false;
    let isExtensionStep4 = window.isExtensionApplication ? (window.currentStep == 4) : false;
    let isExtension = window.isExtensionApplication ? true : false;
    let isQuoteResults = window.ibJS.fetchQuoteResultsAPIPath ? true : false;
    if ((isApplication || isExtension || isQuoteResults) && !$isAlreayRegistered && !isApplicationStep6 && !isExtensionStep4 && affiliateType != 1 && (!window.globalLanguage.internalIP)) {
        this.htmlEvent = document.getElementsByTagName("html")[0];
        this.htmlEvent.addEventListener("mouseleave", this.goAwayEventTriggerMouseOut);
    }
    let numberOfPhnCodes = $("select[id*='phnCode-goaway']");
    if (numberOfPhnCodes && numberOfPhnCodes.length) {
        this.phnCodeId = 'phnCode' + (numberOfPhnCodes.length + 1);
        this.phnCodeParentId = 'phnCode' + (numberOfPhnCodes.length + 1) + '-parent';
    } else {
        this.phnCodeId = 'phnCode';
        this.phnCodeParentId = 'phnCode-parent';
    }
    const self = this;
    this.$nextTick(() => {
        $('.goawaymodal').bind('click', ()=> {
            document.body.classList.add('is-Locked');
            self.displayGoAwayModal = true;
            if(!self.renderHtml) {
                self.goAwayEventShowModal();
            }
        });
        $('#requestCallbackmodal').bind('click', ()=> {
            document.body.classList.add('is-Locked');
            self.displayGoAwayModal = true;
            if(!self.renderHtml) {
                self.goAwayEventShowModal();
            }
        });
    })
};

let goAwayLoadMouseoutComponent = function () {
    if (this.renderHtml && this.displayGoAwayModal) {
        
        return {
            components: {
                'date-picker': datePicker,
                'contact-time': contactTime,
                'phone-code': PhoneCode
            },
            template: this.renderHtml,
            mixins: [OfficeHours],
            data: () => {
                return this.goAwayEventData
            },
            computed: {
                isValid: function () {
                    let finalData = {
                        // nameRequired: true,
                        nameFormat: true,
                        // date: true,
                        // time: true,
                        phoneRequired: true,
                        phoneOnlyAlphaNumeric: true,
                        phoneAtleastOneNumber: true
                        // countryCode: true,
                        // all: false
                    };
    
                    if (this.isTouched) {
                        // let nameRequired = this.form.name;
                        let nameFormat = onlyAlpha.call(this, this.form.name);
                        // let date = monthDate.call(this, this.form.date);
                        // let time = onlyAlphaNumeric.call(this, this.form.time);
                        let phoneRequired = this.form.phoneNumber;
                        let phoneOnlyAlphaNumeric = onlyAlphaNumeric.call(this, this.form.phoneNumber);
                        let phoneAtleastOneNumber = atleastOneNumber.call(this, this.form.phoneNumber);
                        // let code = this.form.countryCode;
                        finalData = {
                            // nameRequired: nameRequired,
                            nameFormat: nameFormat,
                            // date: date,
                            // time: time,
                            phoneRequired: phoneRequired,
                            phoneOnlyAlphaNumeric: phoneOnlyAlphaNumeric,
                            phoneAtleastOneNumber: phoneAtleastOneNumber,
                            // countryCode: code,
                            all: nameFormat && phoneRequired && phoneOnlyAlphaNumeric && phoneAtleastOneNumber
                        }
                    }
    
                    return finalData;
                }
            },
            created: function () {
                this.$parent.awayFetchSpinner = true;
                if(!this.allCountries || !this.allCountries.length || !this.popularCountries || !this.popularCountries.length) {
                    this.getCountries();
                }
            },
            mounted () {
                if (!this.timefetched) {
                    this.getCurrentCSTTime();
                    this.timefetched = true;
                }
            },
            methods: {
                isValidTime: function(event, val) {
                    if (!/\d/.test(event.key) && (event.key !== ":" || /\./.test(val))) {
                        return event.preventDefault();
                    }
                },
                closeModal: function (e, elm, reset) {
                    let clickArea = $(e.target);

                    if (
                            (!clickArea.hasClass("c-app--body") && clickArea.parent().closest(".c-app--body").length === 0) ||
                            (elm && elm == 'c-icon') ||
                            (elm && elm === 'cancel-button')
                        ) {
                        this.$parent.displayGoAwayModal = false;

                        let goawaymodalElement = document.querySelector('.goawaymodal');
                        if (goawaymodalElement) {
                            goawaymodalElement.style.display = 'flex';
                        }
                    
                        setCookieItem('captureModalEvent', true);

                        document.body.classList.remove('is-Locked');
                        if(this.htmlEvent) this.htmlEvent.removeEventListener("mouseleave", this.goAwayEventTriggerMouseOut);
                    }
                    this.phoneNumResch = false;
                    this.phoneNumValidated = false;
                    this.phoneReschDetails.time = null;
                    this.phoneReschDetails.timezone = null;
                    this.formSending = false;
                    this.isTouched = false;
                    if (reset) {
                        this.form = {
                            date: '',
                            time: '',
                            amPM: 'AM',
                            timezone: '',
                            country: 'USA',
                            isdCode: '1',
                            phoneNumber: '',
                            countryCode: 'USA'
                        };
                    }
                },
                sendInfo: function () {
                    this.isTouched = true;
                    var spinTarget = this.$refs.btnspinner;
                    var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);
                    this.formSending = true;

                    this.$refs.contactTime.$v.$touch();

                    if (this.$refs.contactTime.$v.$invalid) {

                        this.formSending = false;
                        return;
                    }

                    let contactTime = this.$refs.contactTime.getResult();
                    
                    if(contactTime) {
                        if (contactTime.isPast || !contactTime.timezone || !contactTime.userTimeStr || contactTime.isTwoWeeksLater) {

                            this.formSending = false;
                            return;
                        } else {
                            let { userTimeStr, timezone, isPast, isTwoWeeksLater } = contactTime;
                        }
                    }
                    if (this.isValid.all && this.phoneNumValidated) {
                        let requestForm = {};

                        let code = this.popularCountries.filter((item, index) => {
                            return this.form.countryCode == item.value;
                        });

                        if(!code.length) {
                            code = this.allCountries.filter((item, index) => {
                                return this.form.countryCode == item.value;
                            });
                        }

                        let completePhoneNumber = null;
                        if (code[0] && code[0].isdCode) {
                            completePhoneNumber = `+${code[0].isdCode}-${this.form.phoneNumber}`;
                        } else {
                            completePhoneNumber = `+1-${this.form.phoneNumber}`;
                        }
                        // addition of name field, will be passed to first name as it only had 'N/A' before
                        // and will not require BE changes
                        requestForm.callerName = this.form.name ? this.form.name : "N/A";
                        requestForm.phoneNumber = completePhoneNumber;
                        
                        if(window.isApplication || window.isExtensionApplication)  {
                            // Special case for step 1
                            if (ibJS.currentStep === '1') {
                                if (ibJS.extension === null || ibJS.extension) {
                                    let ad = 'Requested through Application Extension: ' + ibJS.companyCode + ' step-1';
                                    requestForm.details = ad;
                                    requestForm.requestThru = ad;
                                } else {
                                    let ad = 'Requested through Applications: ' + ibJS.planConfig.displayNamePlain + ' step-1';
                                    requestForm.details = ad;
                                    requestForm.requestThru = ad;
                                }
                            } else {
                                if (ibJS.extension) {
                                    let ad = 'Requested through Application Extension. Expires ' + convertDateString(ibJS.policyDTO.expirationDate, 'en');
                                    requestForm.details = ad;
                                    requestForm.requestThru = ad;
                                    requestForm.certificateNumber = ibJS.policyDTO.certificateNumber;
                                } else {
                                    let ad = 'Requested through Applications: ' + (ibJS.planName ?  ibJS.planName : ibJS.application.planName);
                                    requestForm.details = ad;
                                    requestForm.requestThru = ad;
                                    requestForm.applicationId = ibJS.application?.fullAppId;

                                    if (requestForm.phoneNumber && atleastOneNumber(requestForm.phoneNumber) && onlyAlphaNumeric(requestForm.phoneNumber) && requestForm.phoneNumber.length > 4) {
                                        let url = `/api/application/save-email-phone/?id=${ibJS.application?.id}&phone=${encodeURIComponent(requestForm.phoneNumber)}`;
                                        this.$http.get(url);
                                        this.$emit('reset-session');
                                    }
                                }
                            }
                        }
                        if (window.ibJS.fullQuoteId) {
                            requestForm.quoteId = window.ibJS.fullQuoteId;
                            let qd = 'Requested through Quotes: ' + getCategoryName(window.ibJS.section, window.globalLanguage);

                            requestForm.details = qd;
                            requestForm.requestThru = qd;
                        }

                        if(!(window.isApplication || window.isExtensionApplication || window.ibJS.fullQuoteId)) {
                            requestForm.requestThru = 'Requested through website';
                        }

                        requestForm.userTimeStr = contactTime && contactTime.userTimeStr ? contactTime.userTimeStr : '';
                        requestForm.whenToCallUserTimezone = contactTime && contactTime.timezone ? contactTime.timezone : '';
                        requestForm.requestPath = window.location.href;

                        if (window.globalLanguage.lang === 'zh') {
                            requestForm.needToCall = 'CHN_GRP';
                        } else if (window.globalLanguage.lang === 'es') {
                            requestForm.needToCall = 'SPN_GRP';
                        } else {
                            requestForm.needToCall = 'ENG_GRP';
                        }

                        let self = this;
                        self.errors = [];
                            
                        this.$http.post('/api/request-call-back/', requestForm)
                            .then(function (res) {
                                if (res.body.status === 'success') {
                                    this.messageSent = true;
                                    setCookieItem('captureModalEvent', true);
                                    if(self.htmlEvent) self.htmlEvent.removeEventListener("mouseleave", this.goAwayEventTriggerMouseOut);
                                } else if (res.body.status === 'error') {
                                    self.errors = res.body.data.allErrorMessages;
                                    this.formSending = false;
                                }
                            }, function (err) {
                                this.$parent.displayGoAwayModal = false;
                                setCookieItem('captureModalEvent', true);
                                if(this.htmlEvent) this.htmlEvent.removeEventListener("mouseleave", this.goAwayEventTriggerMouseOut);
                                console.error(err);
                                this.formSending = false;
                            });
                    } else if (this.isValid.all && !this.phoneNumValidated) {
                        this.phoneReschDetails.time = null;
                        this.phoneReschDetails.timezone = null;
                        let code = this.popularCountries.filter((item, index) => {
                            return this.form.countryCode == item.value;
                        });

                        if(!code.length) {
                            code = this.allCountries.filter((item, index) => {
                                return this.form.countryCode == item.value;
                            });
                        }

                        let completePhoneNumber = null;
                        if (code[0] && code[0].isdCode) {
                            completePhoneNumber = `+${code[0].isdCode}-${this.form.phoneNumber}`;
                        } else {
                            completePhoneNumber = `+1-${this.form.phoneNumber}`;
                        }
                        this.$http.get('/api/check-callback-exit/?phoneNumber='+completePhoneNumber).then(res=>{
                            const responseBody = res.body;
                            this.formSending = false;
                            this.phoneNumValidated = true;
                            if (responseBody.success && responseBody.whenToCallUserTime) {
                                this.phoneNumResch = true;
                                this.phoneReschDetails.time = responseBody.whenToCallUserTime;
                                if (responseBody.whenToCallUserTimeZone) this.phoneReschDetails.timezone = matchTimezone(responseBody.whenToCallUserTimeZone);
                            } else {
                                this.phoneNumResch = false;
                                this.sendInfo();
                            }
                        }, err=>{
                            this.formSending = false;
                            this.phoneNumResch = false;
                        })
                    } else {
                        this.formSending = false;
                    }
                },
                getCountries() {
                    // Get countries data from sessionStorage
                    let masterList = getCountriesFromStorage();
                    if (masterList && masterList.lang === window.globalLanguage.lang) {
                        this.popularCountries = masterList.popularCountries;
                        this.allCountries = masterList.countries;
                    }
                    else {
                        // Get countries data from API
                        this.$http.get('/api/retrieve-countries/' + '?lang=' + window.globalLanguage.lang).then((res) => {
                            if(res && res.body && res.body.data && res.body.data.masterList) {
                                let listObj = res.body.data.masterList;
                                listObj.lang = window.globalLanguage.lang;
                                this.popularCountries = listObj.popularCountries;
                                this.allCountries = listObj.countries;
                                setItemSessionStorage(`masterList_${window.globalLanguage.lang}`, JSON.stringify(listObj));
                            }
                        },err => {
                            console.log(err);
                        });
                    }
                },
                changeCountry(country) {
                    this.form.country = country.country;
                    this.form.isdCode = country.isdCode;
                    this.form.countryCode = country.newCode;
                },
                changePhoneNumber(phoneNumber) {
                    if(phoneNumber.includes('-')) {
                        let arr = phoneNumber.split('-');
                        arr.shift();
                        phoneNumber = arr.join('-');
                    }
                    this.form.phoneNumber = phoneNumber;
                }

            }
        }
    } else {
        return {
            template: '<div></div>'
        }
    }
};

let goAwayEventTriggerMouseOut = function (e) {
    let $yAxis = e.clientY;
    if ($yAxis > 25) return false;
    setTimeout(() => {
        this.goAwayEventShowModal();
    }, 200);
};

let goAwayEventShowModal = function () {
    this.$http.get('/guide/terms?section=goaway&lang=' + window.globalLanguage.lang).then((responseBody) => {
        let $siteFooter = document.querySelector('.c-Site__footer');
        if ($siteFooter) $siteFooter.style.marginBottom = '30px';
        this.renderHtml = responseBody.body;
        this.displayGoAwayModal = true;
        this.isCallMeBackloading = true;
        let goawaywrapper = document.querySelector('.o-away-wrapper');
        // required to prevent locking screen when get request timing doesn't align with close of modal and new request
        if (goawaywrapper && goawaywrapper.style.display !== 'none') {
            document.body.classList.add('is-Locked');
        } else if (!goawaywrapper) {
            document.body.classList.add('is-Locked');
        }
        if (this.htmlEvent) {
            this.htmlEvent.removeEventListener("mouseleave", this.goAwayEventTriggerMouseOut);
        }
    })
};

let goAwayEventWatchScope = function () {    
    setTimeout(() => {
        $(".c-app-share .js-E-rev").ibEProtect();
    }, 100);
};


let GoAwayMixin = {
    data: function () {
        return {
            goAwayEventData: goAwayEventData,
            renderHtml: '',
            displayGoAwayModal: true,
            awayFetchSpinner: false,
            htmlEvent: '',
            isInternalIP: window.globalLanguage.internalIP,
            isCallMeBackloading: false
        }
    },
    mounted: goAwayEventWhileMounted,
    watch: {
        goAwayEventWatchScope: goAwayEventWatchScope
    },
    computed: {
        goAwayLoadMouseoutComponent: goAwayLoadMouseoutComponent
    },
    methods: {
        goAwayEventShowModal:goAwayEventShowModal,
        goAwayEventTriggerMouseOut:goAwayEventTriggerMouseOut
    }
}

export {
    goAwayEventData,
    goAwayEventWhileMounted,
    goAwayLoadMouseoutComponent,
    goAwayEventShowModal,
    goAwayEventTriggerMouseOut,
    goAwayEventWatchScope,
    GoAwayMixin
};