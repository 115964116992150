"use strict";

require('../main').default;
require('../main-vue').default;

import Vue from 'vue';

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

import {resetThumbView, validateEmail, onlyAlphaNumeric, debounce, getCountriesFromStorage, addLinkPresection, convertDateStringByEnDate, checkAgent} from '../helper-functions/helper-functions';
import {setItemSessionStorage, getItemSessionStorage, removeKeySessionStorage} from "../helper-functions/services";
import {travelMedicalPolicyMaxOptions, groupTravelMedicalPolicyMaxOptions} from '../view-specific/quote-form/config';
import PhoneCode from '../components/forms/component.phone-code.vue';
import {ibSpinOpts} from '../helper-functions/applicationCommonValues';

// Third party
var accounting = require('accounting');
var Clipboard = require('clipboard');

$(document).ready(function() {
    try{
        new Clipboard('.js-Clipboard');
    }catch(e){}
    $('.c-Save-quote').on('copy', function (e) {
        // allow copy from the save quote fields
        e.stopPropagation();
    });

    $(window).resize(function() {
        try {
            resetThumbView();
        }catch(e){}
    });

    const footer = document.querySelector('.c-Site__footer');
    if (footer && window.innerWidth < 768) {
        footer.style.marginBottom = '4em';
    }

});

import  {MainVueOpts} from '../main-vue';
import { LoginModalMixin } from '../section-specific/applications/mixins/loginModal';
import { required, requiredIf, email } from 'vuelidate/lib/validators';

// Our Components
import planBlock from '../components/plans/component.main-plan-block-v2.vue';

// Print plan block
import printPlanBlock from '../components/plans/component.print-plan-block.vue'
import printssCriteriaBlock from '../components/plans/component.print-ssCriteria-block.vue'
import FilterQuote from '../components/plans/component.filter-quote.vue'
import SingleSelect from './quote-results/v2/narrow-search-single-select.vue';
import FeatureItem from './quote-results/v2/narrow-search-feature-item.vue';

import comparePlanBlock from '../components/plans/component.compare-plan-block';
import '../components/forms/component.quote-form-age-modal';
import '../components/forms/component.quote-form-group-age-modal';
import '../components/forms/component.quote-form-trip-age-modal';
import '../components/forms/component.quote-form-covid-location';
import Checkbox from '../components/components.checkbox.vue'
require('../section-specific/trip/quote-form_trip').default;
import quoteFormMixin from '../quote-form-mixin';

// import TravelMedical from '../section-specific/quote-form/travel-medical.vue';
// import SaveShare from '../components/forms/component.save-share.vue';

Vue.filter('currency', function (value) {
    if (value === null) {
        return '';
    } else {
        accounting.settings = {
            currency: {
                symbol : "$",   // default currency symbol is '$'
                format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
                decimal : ".",  // decimal point separator
                thousand: ",",  // thousands separator
                precision : 2   // decimal places 0 for none
            },
            number: {
                precision : 0,  // default precision on numbers is 0
                thousand: ",",
                decimal : "."
            }
        }
        return accounting.formatMoney(value);
    }
});

Vue.filter('currencyNoCent', function (value) {
    if (value === null) {
        return '';
    } else {
        accounting.settings = {
            currency: {
                symbol : "$",   // default currency symbol is '$'
                format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
                decimal : ".",  // decimal point separator
                thousand: ",",  // thousands separator
                precision : 0   // decimal places 0 for none
            },
            number: {
                precision : 0,  // default precision on numbers is 0
                thousand: ",",
                decimal : "."
            }
        }
        return accounting.formatMoney(value);
    }
});

Vue.filter('capitalize', function (value) {
    if (value === null) {
        return '';
    } else {
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1)
    }
});

const ibSpinOpts1 = {
    lines: 7 // The number of lines to draw
    , length: 21 // The length of each line
    , width: 6 // The line thickness
    , radius: 15 // The radius of the inner circle
    , scale: 0.3 // Scales overall size of the spinner
    , corners: 1 // Corner roundness (0..1)
    , color: '#fff' // #rgb or #rrggbb or array of colors
    , opacity: 0.45 // Opacity of the lines
    , rotate: 0 // The rotation offset
    , direction: 1 // 1: clockwise, -1: counterclockwise
    , speed: 1.4 // Rounds per second
    , trail: 60 // Afterglow percentage
    , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
    , zIndex: 2e9 // The z-index (defaults to 2000000000)
    , className: 'spinner' // The CSS class to assign to the spinner
    , top: '50%' // Top position relative to parent
    , left: '50%' // Left position relative to parent
    , shadow: false // Whether to render a shadow
    , hwaccel: true // Whether to use hardware acceleration
    , position: 'absolute' // Element positioning
};

const QuoteResults = {
    mixins: [MainVueOpts, LoginModalMixin, quoteFormMixin],
    components: {
        'plan-block': planBlock,
        'print-plan-block': printPlanBlock,
        'print-criteria-block': printssCriteriaBlock,
        'filter-quote': FilterQuote,
        'phone-code': PhoneCode,
        'single-select': SingleSelect,
        'feature-item': FeatureItem,
        'compare-plan-block': comparePlanBlock,
        'v-checkbox': Checkbox,
        'save-share': () => import('../components/forms/component.save-share.vue'),
        'travel-medical': () => import('../section-specific/quote-form/travel-medical.vue')
    },
    props: ['extra-plan-filters', 'narrow-prop','heading-map-prop','plan-types-map','display-tabs','display-tabs-list', "get-list-length", 'quote-results'],
    data: function () {
        return {
            planData: {},
            listsLength: {},
            listsLengthWithNarrow: {
                fixedLength: 0,
                comprehensiveLength: 0,
                allPlansLength: 0,
                bestSellersLength: 0,
            },
            list0: [],
            list1: [],
            list2: [],
            list3: [],
            list4: [],
            heading0: '',
            heading1: '',
            heading2: '',
            heading3: '',
            heading4: '',
            plansText: ibJS.plansText,
            numOfPlansLists : 5,
            plansToCompare: [],
            planTypeListKeyMap: {
                comprehensive: 1,
                fixed: 0
            },
            allCompared: false,
            allMinimized: sessionStorage.getItem('allMinimized') === 'false' ? sessionStorage.getItem('allMinimized') === 'true' : ibJS.allMinimized,
            collapsedSB: sessionStorage.getItem('collapsedSB') === 'false' ? sessionStorage.getItem('collapsedSB') === 'true' : ibJS.collapsedSB,
            forcedCollapse: false,
            compareMinimized: false,
            compareSending: false,
            dataFetched: false,
            editQuoteShown: false,
            emailSent: false,
            emailSending: false,
            extendEmailForm: false,
            maxRecipients: 10,
            narrow: this.narrowProp,
            narrowShown: false,
            numTravelers: null,
            saveShareShown: false,
            server500ErrorShown: false,
            sortBy: sessionStorage.getItem('sort'+window.globalLanguage.section) ||  '',
            ssCriteria: {},
            globalErrors: [],
            fieldErrors: [],
            section: window.globalLanguage.section || '',
            globalSection: null,
            policyMax: 0,
            displaySelectedView: '',
            plansToView: '',
            showTabs: '',
            windowWidth: null,
            currentPageMaxPlansDisplay: 4,
            currentLang: window.globalLanguage.lang,
            incrementDeductible:false,
            decrementDeductible:false,
            recipientEmailValidation: false,
            senderNameValidation: false,
            hideAutofill: false,
            gdprCountries: [],
            isGdprCountry: false,
            myAccountLogin: window.globalExtra ? window.globalExtra.myAccountLogin : false,
            isInternalIP: window.globalLanguage.internalIP,
            showCovidQuarantineFilter: false,
            showPreExisting: false,
            fullQuoteId: null,
            showSaveQuoteBtn: true,
            countries: [],
            states: [],
            qResultsLang: qResultsLang,
            qFormLang: window.qFormLang,
            affiliatevalues: window.ibJS.affiliatevalues,
            defaultStep: 1,
            list0Comp: [],
            list1Comp: [],
            list2Comp: [],
            list3Comp: [],
            list4Comp: [],
            showMobileUpdateResult: false,
            inputSummaryContent: [],
            underwriterDropdown: [],
            plansDropdown: [],
            administratorDropdown: [],
            planTypesDropdown: [],
            ppoNetworkDropdown: [],
            ppoNetworkChecks: [],
            narrowSearchState: null,
            narrowSearchCountry: null,
            showNarrowOrSort: true,
            showFixedHeaderForBestSeller: false,
            showComprehensiveHeaderForBestSeller: false,
            travelersTotal: 0,
            maternityOptions: [
                {
                    text: window.qResultsLang.doesNotMatter,
                    value: false
                },
                {
                    text: window.qResultsLang.required,
                    value: true
                },
            ],
            error365Days: false,
            showQuickEdit: true,
            coverageArea: '',
            lazyLoadButHide: false,
            coverageAreaExcluding: {
                // Plans within it are not supported, so when the choice is selected these plans has to be excluded.
                "2": [], // International Including USA
                "5": [] // USA and During Travel
            }
        }
    },
    computed: {
        displayTabView: function(){
            return this.displayTabs().call(this);
        },
        emailFormShownC: function() {
            if (this.emailSent) {
                return false;
            } else {
                return true;
            }
        },
        showInstantVisaLetter: function(){
            var showInstantVisa = true;
            if(this.section === 'visitorUSA' || this.section === 'newImmigrants' || this.section === 'missionaryTravel'){
                showInstantVisa = false;
            }
            return showInstantVisa;
        },
        showPlanTypeOrPPOorExtendable: function(){
            var showPlanTypePPO = true;
            // Plan Type should be seen only when travel to USA
            if(this.section === 'schengen' || this.section === 'studyAbroad' || this.section === 'travelOutsideUSA' 
                || (this.section === 'missionaryTravel' && (this.planData.travelMedicalCriteria?.primaryDestination !== 'USA' || !this.isCoverageAreaUsa) )
                || ((this.section === 'missionary' || this.section === 'expatriate' || this.section === 'marine') && !this.isUsaCoverageArea) 
                || (this.section === 'covid19' && !this.isCoverageAreaUsa)) {
                showPlanTypePPO = false;
            }
            return showPlanTypePPO;
        },
        showPlanTypeOrMaternity: function(){
            if(this.section === 'opt' ){
                return false;
            } else {
                return true;
            }
        },
        showPlanType() {
            return (this.planTypesDropdown.length > 1 && this.showPlanTypeOrMaternity) || this.narrow.planType;
        },
        showPpoNetwork() {
            if(this.narrow.ppoNetwork) return true;
            if(this.section == 'travelOutsideUSA' || !this.showPlanTypeOrPPOorExtendable) return false;
            return this.ppoNetworkDropdown.length > 1 ;
        },
        showUnderwriter() {
            return this.underwriterDropdown.length > 1 || this.narrow.underwriter;
        },
        showAdministrator() {
            return this.administratorDropdown.length > 1 || this.narrow.administrator;
        },

        isCoverageAreaUsa () {
            if(this.planData && this.planData.travelMedicalCriteria && typeof this.planData.travelMedicalCriteria == 'object' && this.planData.travelMedicalCriteria.coverageArea) {
                const includesUSA = this.planData.travelMedicalCriteria.coverageArea == 1 
                || this.planData.travelMedicalCriteria.coverageArea == 2 
                || this.planData.travelMedicalCriteria.coverageArea == 5;
                return includesUSA;
            } 
            return false;
        },
        isUsaCoverageArea() {
            return this.planData.expatriateHealthCriteria?.coverageArea == 4
        },
        showInstantVisaLetter: function(){
            var showInstantVisa = true;
            if(this.section === 'visitorUSA' || this.section === 'newImmigrants' || this.section === 'missionaryTravel'){
                showInstantVisa = false;
            }
            return showInstantVisa;
        },
        totalNumberPlansDisplay() {
            let res = 0;
            for(let i = 0; i<= 3; i++) {
                if(this[`list${i}Comp`] && this[`list${i}Comp`].length) res += this[`list${i}Comp`].length;
            }
            return res;
        },
        displayBestSeller() {
            return this.displayTabsList && this.displayTabsList.bestSeller && this.listsLengthWithNarrow.bestSellersLength;
        },
        displayComprehensive() {
            return this.displayTabsList && this.displayTabsList.comprehensive && this.listsLengthWithNarrow.comprehensiveLength;
        },
        displayFixed() {
            return this.displayTabsList &&  this.displayTabsList.fixed && this.listsLengthWithNarrow.fixedLength;
        },
        displayQuoteTabs() {
            if(!this.displayTabView || !this.dataFetched || this.noPlansFoundShown || this.error365Days) return false;
            let numberTabs = 0;
            if(this.displayBestSeller) numberTabs += 1;
            if(this.displayComprehensive) numberTabs += 1;
            if(this.displayFixed) numberTabs += 1;
            return numberTabs >= 2;
        },
        disableFilterOptions() {
            const disableOption = (this.globalErrors.length || this.fieldErrors.length || this.noPlansFoundShown || this.error365Days) ? true : false;
            return disableOption;
        }
    },
    watch: {
        allCompared: function(value) {
            var arr = this.planData;

            for ( var i=arr.length-1; i>=0; i--) {
                if (value) {
                    arr[i].compare = true;
                } else {
                    arr[i].compare = false;
                }
            }
        },
        plansToCompare: function (value) {
            if (this.allCompared && value.length === 0) {
                this.allCompared = false;
            }
        },
        editQuoteShown (val) {
            if (val) {
                $('body').addClass('is-Locked--qf');
            } else {
                if (this.globalErrors.length || this.fieldErrors.length) {
                    this.globalErrors = [];
                    this.fieldErrors = [];
                } else if (this.server500ErrorShown) this.server500ErrorShown = false;
                $('body').removeClass('is-Locked--qf');
            }
        },
        saveShareShown (val) {
            if (val) {
                $('body').addClass('is-Locked--qf');
            } else {
                $('body').removeClass('is-Locked--qf');
            }
        },
        sortBy() {
            this.updateAllLists();
        },
        ssCriteria: {
            deep: true,
            handler: function() {
                this.getInputSummaryContent();
                this.narrow.coverageArea = this.ssCriteria.coverageArea ? this.ssCriteria.coverageArea.toString() : '';
            }
        },
        planData: {
            deep: true,
            handler: function() {
                this.getUnderwriterDropdown();
                this.getPlansDropdown();
                this.getAdministratorDropdown();
                this.getPpoNetworkChecks();
                this.getPpoNetworkDropdown();
                this.getPlanTypesDropdown();
                this.getShowNarrowOrSort();
            }
        },
        noPlansFoundShown: function() {
            this.getUnderwriterDropdown();
            this.getPlansDropdown();
            this.getAdministratorDropdown();
            this.getPpoNetworkChecks();
            this.getPpoNetworkDropdown();
            this.getPlanTypesDropdown();
            this.getShowNarrowOrSort();
        },
        'ssCriteria.homeCountry'() {
            this.getNarrowSearchCountry();
        },
        'ssCriteria.region'() {
            this.getNarrowSearchState();
        },
        states: {
            deep: true,
            handler: function() {
                this.getNarrowSearchState();
            }
        },
        'ssCriteria.travelerInfos': {
            deep: true,
            handler: function() {
                this.travelersTotal = this.getTravelersTotal();
            }
        },
        collapsedSB() {
            if(window.innerWidth <= 768 && !this.collapsedSB) { // disable scroll if it is mobile and not collapsed
                document.getElementsByTagName("body")[0].style.overflow = 'hidden';
            }
            if(this.collapsedSB) {
                document.getElementsByTagName("body")[0].style.overflow = 'auto';
            }
        }
    },
    created: function() {
        var self = this;

        // QR family based pre fill fields when travelers are more than one adult
        removeKeySessionStorage('plan-criteria');

        if(ibJS.gdprCountries) {
            self.gdprCountries = ibJS.gdprCountries.map(function (country) {
              return country.newCode;
            });
        }

        for (var key in window.globalSection){
            if(window.globalSection[key].indexOf(window.globalLanguage.section)>-1){
                this.globalSection = key;
                break;
            }
        }
        
        global.eventHub.$on('countries-obtained', (val) => {
            this.countries = val;
            this.countries.forEach((ctr, i)=>{
                this.countries[i].unified = `${ctr.country} ${ctr.newCode} ${ctr.isdCode}`;
            })
        });
        global.eventHub.$on('states-obtained', (val) => {
            this.states = val;
        });
        
        if (!this.countries || !this.countries.length) {
            let masterList = getCountriesFromStorage();
            if (masterList && masterList.lang === window.globalLanguage.lang) {
                this.countries = masterList.countries;
            }
        }

        self.$http.get( window.ibJS.fetchQuoteResultsAPIPath, {params: {section: window.globalLanguage.section, lang: window.globalLanguage.lang} } )
            .then((response) => {
                if(response) {
                    var responseBody = response.body;
                    self.fullQuoteId = responseBody.data?.fullQuoteId;
                    if (responseBody.status === 'error') {
                        if (responseBody.data.refresh) {
                            window.location.href = responseBody.data.redirectTo;
                            return;
                        }
                        if (responseBody.data.noPlansFound) {
                            self.dataFetched = true;
                            self.showSaveQuoteBtn = false;
                            self.noPlansFoundShown = true;
                            self.editQuoteShown = true;
                            /* fill the quote form */
                            self.$parent.initCriteria(self, responseBody);
                            return;
                        }
                        if (responseBody.data.expired) {
                            setItemSessionStorage('expiredDate','true');
                            if(responseBody.data.tripCriteria){
                                window.sessionStorage[window.globalLanguage.sectionCriteriaKey] = JSON.stringify(responseBody.data.tripCriteria);
                            }else if(responseBody.data.travelMedicalCriteria){
                                window.sessionStorage[window.globalLanguage.sectionCriteriaKey] = JSON.stringify(responseBody.data.travelMedicalCriteria);
                            }else if(responseBody.data.studentMedicalCriteria){
                                window.sessionStorage[window.globalLanguage.sectionCriteriaKey] = JSON.stringify(responseBody.data.studentMedicalCriteria);
                            }else if(responseBody.data.multiTripCriteria){
                                window.sessionStorage[window.globalLanguage.sectionCriteriaKey] = JSON.stringify(responseBody.data.multiTripCriteria);
                            }else if(responseBody.data.expatriateHealthCriteria){
                                window.sessionStorage[window.globalLanguage.sectionCriteriaKey] = JSON.stringify(responseBody.data.expatriateHealthCriteria);
                            }else if(responseBody.data.flightAccidentCriteria){
                                window.sessionStorage[window.globalLanguage.sectionCriteriaKey] = JSON.stringify(responseBody.data.flightAccidentCriteria);
                            }else if(responseBody.data.groupTravelMedicalCriteria){
                                window.sessionStorage[window.globalLanguage.sectionCriteriaKey] = JSON.stringify(responseBody.data.groupTravelMedicalCriteria);
                            }
                            window.location.href = responseBody.data.redirectTo;
                            return;
                        }
                        if (responseBody?.data?.globalErrors) {
                            self.globalErrors = responseBody.data.globalErrors;
                        }
                        if (responseBody.data.fieldErrors) {
                            self.fieldErrors = responseBody.data.fieldErrors;
                        }
                    } else {
                        /** @type {PlanData} */
                        const planData = response.body.data;
                        const order = planData.order;
                        const list = [];
                        // For GoAwayCapture 

                        window.ibJS.fullQuoteId = planData.fullQuoteId;
                        window.ibJS.retrievedQuotes = planData.retrievedQuotes;
                        setItemSessionStorage('fullQuoteId', window.ibJS.fullQuoteId || '');
                        //var listTwo = [];
                        if (order) {
                            for(let i = 0; i < order.length; i++){
                                list.push(planData[planData.order[i]]);
                                /** in order to know which list (e.g. list1, list2, ...) is for which plan type */
                                if(this.planTypeListKeyMap && typeof this.planTypeListKeyMap == 'object') {
                                    this.planTypeListKeyMap[planData.order[i]] = i; 
                                }
                            }
                        }

                        /* set original lists (list0, list1, list2..) corresponding to different plan types (Comprehensize, fixed...)*/
                        for(let i = 0; i < list.length; i++){
                            if(list[i] != undefined){
                                const tempList = list[i];

                                for(let j = 0 ; j < tempList.length; j++){
                                    const thisTempList = tempList[j];
                                    if (thisTempList.covidQuarantineCoverage) {
                                        this.showCovidQuarantineFilter = true;
                                    }

                                    if (thisTempList.planId === 755) this.showPreExisting = true;
                                    if([712,713].indexOf(thisTempList.planId) > -1) {
                                        thisTempList.coInsurance = thisTempList.coinsurances && thisTempList.coinsurances.length ? thisTempList.coinsurances[0] : '';
                                    }

                                    thisTempList.compare = false;
                                    if (thisTempList.itemPrice?.price) thisTempList.priceBeforeCancel = thisTempList.itemPrice.price;
                                    thisTempList.keyId = `${thisTempList.planId}-1`;

                                    if(typeof planData.tripLength != 'undefined'){
                                        thisTempList.tripLength = planData.tripLength;
                                    }
                                }

                                
                                self['list' + i] = list[i];
                                
                                if(order && order[0] != 'allPlans'){
                                    self['heading' + i] = self.headingMapProp[order[i]];
                                }
                                self.listsLength[order[i]] = list[i].length;
                            }
                        }


                        self.planData = planData;

                        if (responseBody.data.narrow) {
                            if (!responseBody.data.narrow.specificPlan) {
                                // set specificPlan array for plan selection dropdown if not set
                                responseBody.data.narrow.specificPlan = [];
                            }
                            self.narrow = responseBody.data.narrow;
                        }
                        self.$parent.initCriteria(self, responseBody);

                        this.isGdprCountry = this.gdprCountries.indexOf(this.ssCriteria.citizenshipCountry) > -1 || this.gdprCountries.indexOf(this.ssCriteria.homeCountry) > -1;
                        const criterias = ["tripCriteria","travelMedicalCriteria","studentMedicalCriteria","multiTripCriteria","expatriateHealthCriteria","flightAccidentCriteria","groupTravelMedicalCriteria"];

                        if (self.planData.retrievedQuotes) {
                            for(let i = 0; i < criterias.length; i++){
                                if(criterias[i] in responseBody.data){
        
                                    if (responseBody.data[criterias[i]].viewSize === 1) {
                                        this.allMinimized = true;
                                        setItemSessionStorage('allMinimized', 'true');
                                    } else {
                                        this.allMinimized = false;
                                        setItemSessionStorage('allMinimized', 'false');
                                    }
                                }
                            }
                        }

                        self.dataFetched = true;

                        if(planData.anyPlanBestseller){
                            self.showTabs='true';

                            // Handles display of sessionStorage to display plans
                            var storageSection;
                            try {
                                storageSection = sessionStorage.getItem("storageSection");
                                if (storageSection === self.section && self.showTabs == 'true'){
                                self.plansToView = sessionStorage.getItem("tabToView") ? sessionStorage.getItem("tabToView") : 'all';
                                } else{
                                self.plansToView =  'all';
                                }
                            } catch(e) {
                                console.log("Session storage is disabled");
                                storageSection = "";
                                self.plansToView = "all";
                            }

                            // Handles display of sessionStorage mobile text
                            if(storageSection === self.section){
                            self.displaySelectedView = sessionStorage.getItem("displayTabName") ? sessionStorage.getItem("displayTabName") : 'All Plans';
                            }else{
                            self.displaySelectedView =  'All Plans';
                            }
                        }else{
                            self.displaySelectedView='All Plans';
                            self.plansToView='all';
                        }

                        if (window.sessionStorage[window.globalLanguage.sectionNarrow]) {
                            var sessData = JSON.parse(window.sessionStorage[window.globalLanguage.sectionNarrow]);
                            self.narrow = sessData;
                            this.narrow.viewSize = this.allMinimized ? 1 : 2;
                        }


                        if (planData.covgInlcUsaNotSupported?.length) this.coverageAreaExcluding["2"] = planData.covgInlcUsaNotSupported;
                        if (planData.covgUsaDuringTravelNotSupported?.length) this.coverageAreaExcluding["5"] = planData.covgUsaDuringTravelNotSupported;

                        self.updateAllLists();

                        self.getListsLengthAfterNarrow(true);
                    }
                }
                //Keep spouse from displaying -1 on mobile view when blank.
                if(this.section == 'expatriate' || this.section == 'missionary' || this.section == 'marine') {
                    if(this.ssCriteria.spouseAge === -1) {
                        this.ssCriteria.spouseAge = null;
                    }
                }
            },
            function(response) {
                if(response){
                var responseBody = response.body;
                var errors = {};
                errors.serverErrors = responseBody.data;
                self.showServer500Error(errors);
                $("body").animate({ scrollTop: 0 }, "fast");
                self.dataFetched = true;
                }
            });

        var self = this;
        self.windowWidth = window.innerWidth;

        // Throttle window resize function call
        var delay = 200
            ,run
            ,runBuffer
            ,tid
        ;

        run = function() {
            // This is the part we're most concerned with
            self.windowWidth = window.innerWidth;
        };

        runBuffer = function() {
            clearTimeout(tid);
            tid = setTimeout(()=> {
                run();
                if(window.innerWidth < 768 && self.collapsedSB) {
                    setTimeout(() => {
                        const narrowSearch = document.querySelector('.c-Q-Res-Actions__narrow-search--mbl-initiate');
                        if (narrowSearch) narrowSearch.classList.remove('c-Q-Res-Actions__narrow-search--mbl-initiate');
                        self.showMobileUpdateResult = false;
                    }, 1500);
                }
            }, delay);
        };

        window.addEventListener('resize', runBuffer);

        $(document).ready(function () {
            $('.js-Stop-propagation').on('click', function (e) {
                e.stopPropagation();
            });
        });

        this.$root.$on('loadremarks', (remarks) => {
            if (this.planData) this.planData.remarks = [remarks];
        })

        let innerwidth = window.innerWidth;

        if(innerwidth <= 991 && !this.collapsedSB) {
            this.collapsedSB = true;
        }

        if(innerwidth >= 992 && this.collapsedSB) {
            this.collapsedSB = false;
        }

        if(innerwidth < 768 && this.collapsedSB) {
            setTimeout(() => {
                const narrowSearch = document.querySelector('.c-Q-Res-Actions__narrow-search--mbl-initiate');
                if (narrowSearch) narrowSearch.classList.remove('c-Q-Res-Actions__narrow-search--mbl-initiate');
                this.showMobileUpdateResult = false;
            }, 1500);
        }

        this.$root.$on('show-quick-edit', (val)=>{
            this.showQuickEdit = val;
            this.toggleViewEdit(false);
            Vue.nextTick(()=>{
                this.toggleViewEdit(true);
            })
        });

        this.getUnderwriterDropdown();
        this.getPlansDropdown();
        this.getAdministratorDropdown();
        this.getPpoNetworkChecks();
        this.getPpoNetworkDropdown();
        this.getPlanTypesDropdown();
        this.getNarrowSearchCountry();
        this.getNarrowSearchState();
        this.getShowNarrowOrSort();
        this.getInputSummaryContent();
        this.showFixedHeaderForBestSeller = this.getShowFixedHeaderForBestSeller();
        this.showComprehensiveHeaderForBestSeller = this.getShowComprehensiveHeaderForBestSeller()
        this.travelersTotal = this.getTravelersTotal();
    },
    mounted: function(){
         Vue.nextTick(()=> this.hideAutofill = true);
         setTimeout(() => {
            if($('#compare-all-plans input').is(':checked')) {
                this.allCompared = true;
            }
         }, 2000);
         
        checkAgent.call(this, [false], false);
    },
    methods: {
        renderList(id, ignorePlanTypeFilter = false) {
            var plans = JSON.parse(JSON.stringify(this['list' + id]));
            var self = this;

            if(!plans || !plans.length) return [];

            // sort
            plans = plans.sort(self.sortDefault);
            if (self.sortBy === 'priceLtH') {
                plans = plans.sort(function(planA, planB){
                    let priceA = planA.defaultTotalPrice ? planA.defaultTotalPrice : planA.itemPrice && planA.itemPrice.price ? planA.itemPrice.price : 0;
                    let priceB = planB.defaultTotalPrice ? planB.defaultTotalPrice : planB.itemPrice && planB.itemPrice.price ? planB.itemPrice.price : 0;
                    if(!priceA) return -1;
                    if(!priceB) return 1;
                    return priceA - priceB;
                });
            }
            if (self.sortBy === 'priceHtL') {
                plans = plans.sort(function(planA, planB){
                    let priceA = planA.defaultTotalPrice ? planA.defaultTotalPrice : planA.itemPrice && planA.itemPrice.price ? planA.itemPrice.price : 0;
                    let priceB = planB.defaultTotalPrice ? planB.defaultTotalPrice : planB.itemPrice && planB.itemPrice.price ? planB.itemPrice.price : 0;
                    if(!priceA) return -1;
                    if(!priceB) return 1;
                    return priceA - priceB;
                }).reverse();
            }
            if (self.sortBy === 'underwriter') {
                plans = plans.sort(function(planA, planB) {
                    var carrierA = planA.planDetails.config.carrier.toUpperCase();
                    var carrierB = planB.planDetails.config.carrier.toUpperCase();
                    if (carrierA < carrierB) {
                        return -1;
                    }
                    if (carrierA > carrierB) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (self.sortBy === 'administrator') {
                plans = plans.sort(function(planA, planB) {
                    var adminA = planA.planDetails.config.administrator.toUpperCase();
                    var adminB = planB.planDetails.config.administrator.toUpperCase();
                    if (adminA < adminB) {
                        return -1;
                    }
                    if (adminA > adminB) {
                        return 1;
                    }
                    return 0;
                });
            }

            
            // filters
            plans = this.filterPlans(plans, ignorePlanTypeFilter);

            return plans;
        },
        filterPlans(plans, ignorePlanTypeFilter = false) {
            if (this.narrow.underwriter) {
                plans = plans.filter(plan=> {
                    return plan.planDetails && plan.planDetails.config && plan.planDetails.config.carrier && plan.planDetails.config.carrier.replace(/\s/g, '') === this.narrow.underwriter;
                });
            }
            if (this.narrow.administrator) {
                plans = plans.filter(plan=> {
                    return plan.planDetails && plan.planDetails.config && plan.planDetails.config.administrator &&plan.planDetails.config.administrator.replace(/\s/g, '') === this.narrow.administrator;
                });
            }
            if (this.narrow.specificPlan && this.narrow.specificPlan.length > 0) {
                plans = plans.filter(plan=> {
                    var found = false;
                    var plansSelected = this.narrow.specificPlan;

                    for (var j = plansSelected.length-1; j > -1; j--) {
                        if (plansSelected[j].planId === plan.planId) {
                            found = true;
                            break;
                        }
                    }

                    if (found) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }
            if (this.narrow.preExistingCondition) {
                plans = plans.filter((plan) => {
                    return plan.planId === 755;
                });
            }
            if (this.narrow.preExistCondCoverage) {
                plans = plans.filter(plan => {
                    let preExt = plan.hasOwnProperty('preExisting') ? 
                                    plan.preExisting : 
                                    plan.planDetails.hasOwnProperty('preExisiting') ? 
                                    plan.planDetails.preExisiting : plan.planDetails.preExisting;
                    return preExt && preExt !== '' && preExt !== '-';
                });
            }
            if (this.narrow.extendable) {
                plans = plans.filter(plan => {
                    return (plan.renewable);
                });
            }
            if (this.narrow.refundable) {
                plans = plans.filter(plan => {
                    return (plan.planDetails?.config?.refundable);
                });
            }
            if (this.narrow.mailsInsuranceCard) {
                plans = plans.filter(plan => {
                    return (plan.planDetails?.config?.insuranceCard);
                });
            }
            if (this.narrow.ppoNetworkCheck) {
                if(this.section == "travelOutsideUSA") {
                    plans = plans.filter(plan => {
                        return (plan.planDetails.internationalPPO);
                    });
                } else {
                    plans = plans.filter(plan => {
                        return (plan.planDetails.usaPPO);
                    });
                }
            }
            if(this.narrow.greenCardVisitor) {
                plans = plans.filter(plan => {
                    return (plan.planDetails?.config?.greenCardVisitor);
                });
            }
            if (this.narrow.maternity && this.narrow.maternity.toString()  === 'true'){
                plans = plans.filter(plan => {
                    return (plan.planDetails?.config?.maternityCompliant);
                });
            }
            if (this.narrow.instantVisaLetter){
                plans = plans.filter(plan => {
                    return (plan.planDetails?.config?.instantVisaLetter);
                });
            }
            if (this.narrow.covidCoverage) {
                plans = plans.filter(plan => {
                    if ([161,163].indexOf(plan.planId) > -1) {
                        // INSUBUY-7641	PIU
                        return true;
                    }
                    return (plan.planDetails?.config?.doesPlanCoverCovid);
                });
            }
            if (this.narrow.covidQuarantine) {
                plans = plans.filter(plan => {
                    return (plan.covidQuarantineCoverage);
                });
            }
            if (this.narrow.coverageArea) {
                const planIdsToExclude = ["2", "5"].indexOf(this.narrow.coverageArea) > -1 ? this.coverageAreaExcluding[this.narrow.coverageArea] : [];
                plans = plans.filter(plan => {
                    return planIdsToExclude.indexOf(plan.planId) === -1;
                });
            }

            if(this.extraPlanFilters && typeof(this.extraPlanFilters) == 'function') {
                plans = this.extraPlanFilters(this, plans, ignorePlanTypeFilter);
            }
            return plans; 
        },
        getList0Comp () {
            this.list0Comp = this.renderList(0);
        },
        getList1Comp () {
            this.list1Comp = this.renderList(1);
        },
        getList2Comp() {
            this.list2Comp = this.renderList(2);
        },
        getList3Comp() {
            this.list3Comp = this.renderList(3);
        },
        getList4Comp () {
            this.list4Comp = this.renderList(4);
        },

        getInputSummaryContent() {
            if(!this.ssCriteria) return;
            let res = [];
            let content = '';
            let noOfDays = '(';
            if (this.ssCriteria.durationMonths > 0) {
                noOfDays += `${this.ssCriteria.durationMonths} ${qResultsLang.months} ${this.ssCriteria.durationDays} ${qResultsLang.days}, `
            } else if (this.section === 'trip') {
                this.ssCriteria.durationTotalDays = this.planData.tripLength;
            }
            noOfDays += `${this.ssCriteria.durationTotalDays} ${qResultsLang.days})`;
            // start&end dates
            if(!!this.ssCriteria.startDate && !!this.ssCriteria.endDate) {
                res.push({
                    label: qResultsLang.travelDates,
                    content:  `${this.ssCriteria.startDate} <span class="cov-date-to">${qResultsLang.globalTo} ${this.ssCriteria.endDate}</span> <span class="cov-days"><br>${noOfDays}</span>`
                });
            } else if(!!this.ssCriteria.departureDate && !!this.ssCriteria.returnDate){
                res.push({
                    label: qResultsLang.travelDates,
                    content:  `${this.ssCriteria.departureDate} <span class="cov-date-to">${qResultsLang.globalTo} ${this.ssCriteria.returnDate}</span> <span class="cov-days"><br>${noOfDays}</span>`
                });
            }

            //home country
            if(this.globalSection == 'multitrip' && this.narrowSearchCountry){
                res.push({
                    label: qResultsLang.homeCountry,
                    content:  this.narrowSearchCountry
                });
            }

            // insured information
            if(this.globalSection == 'flight'){
                res.push({
                    label: qResultsLang.numOfTravelers,
                    content: this.numTravelers
                });
            } else if(this.globalSection == 'trip') {
                if(this.ssCriteria.numTravelers !== 0){
                    res.push({
                        label: qResultsLang.numOfTravelers,
                        content: qResultsLang.numTravelers
                    });
                }
            } else if(this.globalSection == 'grouptravelmedical') {
                this.travelersTotal = this.getTravelersTotal();
                
                res.push({
                    label: qResultsLang.ageGroupS,
                    content: this.travelersTotal
                });
            } else if(this.ssCriteria?.travelerInfos && this.ssCriteria?.travelerInfos.length) {
                content = '';
                this.ssCriteria.travelerInfos.forEach((item, i) => {
                    if (i > 0) content += ", ";
                    content += item.age;
                });
                content +=  ' ' + qResultsLang.textYears;
                // if(this.ssCriteria.numChildren != 0) {
                //     content += ', ' + this.ssCriteria.numChildren + ' ' + qResultsLang.textChildren;
                // }
                res.push({
                    label: qResultsLang.quoteAges,
                    content: content
                });
            } else if(!!this.ssCriteria.primaryAge) {
                content = '';
                if(this.ssCriteria.primaryAge != null && this.ssCriteria.primaryAge > 0) {
                    content += this.ssCriteria.primaryAge;
                }
                if(this.ssCriteria.spouseAge != null && this.ssCriteria.spouseAge > 0) {
                    content += ", " + this.ssCriteria.spouseAge;
                }
                if(this.ssCriteria.primaryBday != null && this.ssCriteria.primaryBday > 0) {
                    content += this.ssCriteria.primaryBday;
                }
                if(this.ssCriteria.spouseBday != null && this.ssCriteria.spouseBday > 0) {
                    content += ', ' + ssCriteria.spouseBday;
                }
                content +=  ' ' + qResultsLang.textYears;
                if(this.ssCriteria.numChildren != 0) {
                    content += ', ' + this.ssCriteria.numChildren + ' ' + qResultsLang.textChildren;
                }
                res.push({
                    label: qResultsLang.quoteAges,
                    content: content
                });
            }

            if(this.globalSection == 'expatriatehealth' && this.ssCriteria.maternity !== null) {
                res.push({
                    label: qResultsLang.maternity,
                    content: this.ssCriteria.maternity == 1 ? qResultsLang.yes : qResultsLang.no
                });
            }

            // destination
            if (this.globalSection != 'trip' && (this.globalSection == 'travelmedical' || this.globalSection == 'grouptravelmedical') ) {
                const policyMaxList = this.globalSection == 'grouptravelmedical' ? groupTravelMedicalPolicyMaxOptions : travelMedicalPolicyMaxOptions;
                const selectedPolicyMax = policyMaxList.filter(item=>item.value == +this.ssCriteria.policyMaximum);
                res.push({
                    label: qResultsLang.policyMaximumFull,
                    content: selectedPolicyMax && selectedPolicyMax.length ? selectedPolicyMax[0].displayText : ''
                });
                const age = res[res.length - 2];
                const polMax = res[res.length - 1];
                res.splice(res.length-2, 2, polMax, age);
            } else {
                if(this.globalSection == 'studentmedical' && !!this.ssCriteria.studyCountry) {
                    content = '';
                    if(!!this.ssCriteria.studyCountry && this.ssCriteria.studyCountry !== 'USA') {
                        content += qResultsLang.countriesLabel;
                    } else if((this.ssCriteria.studyCountry === null || this.ssCriteria.studyCountry === '') || this.ssCriteria.studyCountry === 'USA') {
                        content += qResultsLang.countriesLabelUSA;
                    }
                    res.push({
                        label: qResultsLang.destination,
                        content: content
                    });
                } else if(this.globalSection == 'travelmedical' && (!!this.ssCriteria.primaryDestination || this.section === 'visitorUSA')) {
                    content = '';
                    if(!!this.ssCriteria.primaryDestination) {
                        content += qResultsLang.countriesLabel;
                    } else if((this.ssCriteria.primaryDestination === null || this.ssCriteria.primaryDestination === '') && this.section === 'visitorUSA') {
                        content += qResultsLang.countriesLabelUSA;
                    }
                    res.push({
                        label: qResultsLang.destination,
                        content: content
                    });
                } else if(this.globalSection == 'flight' && this.narrowSearchState) {
                    res.push({
                        label: qResultsLang.flightAccidentResidence,
                        content: this.narrowSearchState
                    });
                } else if(this.globalSection == 'trip' && !!this.ssCriteria.primaryDestination){
                    res.push({
                        label: qResultsLang.destination,
                        content: qResultsLang.countriesLabel
                    });
                } else if(this.globalSection == 'expatriatehealth' && !!this.ssCriteria.coverageArea){
                    content = '';
                    switch(this.ssCriteria.coverageArea){
                        case 4:
                            content = qResultsLang.coverageAreaWorlWide;
                            break;
                        case 9:
                            content = qResultsLang.coverageAreaUsExcluding;
                            break;
                        case 5:
                            content = qResultsLang.coverageAreaUsCanadaExcluding;
                            break;
                        case 8:
                            content = qResultsLang.coverageAreaUsCanadaAsiaExcluding;
                            break;
                        default:
                            content = '';
                    }
                    res.push({
                        label: qResultsLang.coverageArea,
                        content: content
                    });
                } else if(this.globalSection == 'grouptravelmedical' && !!this.ssCriteria.coverageArea) {
                    content = '';
                    switch(this.ssCriteria.coverageArea) {
                        case 2: 
                            content = qResultsLang.coverageAreaInternational;
                            break;
                        case 3:
                            content = qResultsLang.coverageAreaExcludingUSA;
                            break;
                        default: 
                            content = '';
                    }
                    res.push({
                        label: qResultsLang.coverageArea,
                        content: content
                    });
                } else if(this.globalSection == 'multitrip' && !!this.ssCriteria.coverageArea) {
                    res.push({
                        label: qResultsLang.coverageArea,
                        content:  this.ssCriteria.coverageArea == 2 ? qResultsLang.internationalIncludingUSA : this.ssCriteria.coverageArea == 3 ? qResultsLang.internationalExcludingUSA : ''
                    });
                }
            }
            this.inputSummaryContent = res;
        },
        getUnderwriterDropdown() {
            var underwriters = [];
            var plans = this.getPlans();

            if (plans.length) {
                for (var i = 0, planLen = plans.length; i < planLen; i++) {
                    var underwriter = plans[i].planDetails.config.carrier ? plans[i].planDetails.config.carrier : null;
                    var newUnderwriter;

                    if (underwriter) {
                        var found = false;

                        for (var j = underwriters.length-1; j > -1; j--) {
                            if (underwriters[j].text === underwriter) {
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            newUnderwriter = {
                                text: underwriter,
                                value: underwriter.replace(/\s/g, '')
                            };
                            underwriters.push(newUnderwriter);
                        }
                    }
                }
            }
            underwriters.sort(function(a,b){
                if(a.text < b.text) return -1;
                if(a.text > b.text) return 1;
                return 0;
            });
            this.underwriterDropdown = underwriters;
        },
        getAdministratorDropdown() {
            var administrators = [];
            var plans = this.getPlans();

            if (plans.length) {
                for (var i = 0, planLen = plans.length; i < planLen; i++) {
                    var administrator = plans[i].planDetails.config.administrator ? plans[i].planDetails.config.administrator : null;
                    var newAdministrator;

                    if (administrator) {
                        var found = false;

                        for (var j = administrators.length-1; j > -1; j--) {
                            if (administrators[j].text === administrator) {
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            newAdministrator = {
                                text: administrator,
                                value: administrator.replace(/\s/g, '')
                            };
                            administrators.push(newAdministrator);
                        }
                    }
                }
            }
            administrators.sort(function(a,b){
                if(a.text < b.text) return -1;
                if(a.text > b.text) return 1;
                return 0;
            });
            this.administratorDropdown = administrators;
        },
        getPlansDropdown() {
            var specificPlans = [];
            var plans = this.getPlans();

            if (plans.length) {
                for (var i = 0, planLen = plans.length; i < planLen; i++) {
                    var planText = plans[i].planDetails.config.displayNamePlain || null;
                    var planId = plans[i].planId || null;

                    var newPlan = {
                        text: planText,
                        planId: planId
                    };

                    specificPlans.push(newPlan);
                }
            }

            this.plansDropdown = specificPlans;
        },
        getPlanTypesDropdown() {
            var self = this;
            if(!self.dataFetched || !this.planTypesMap) {
                return [];
            }
            if(self.noPlansFoundShown || !self.planData || typeof self.planData.order == 'undefined') {
                return [];
            }
            var order = self.planData.order;
            var planTypes = [];
            if (order) {
                for(var i=0;i<order.length;i++) {
                    var planType = order[i];
                    if(planType === 'fixed') {
                        planType = window.ibJS.fixed;
                    } else if(planType === 'comprehensive') {
                        planType = window.ibJS.comprehensive;
                    } else if(planType === 'accidentBenefit') {
                        planType = window.ibJS.accident;
                    }

                    if(self['list'+i].length > 0) {
                        var newPlanType = {
                            text: planType,
                            value : this.planTypesMap[order[i]]
                        };
                        planTypes.push(newPlanType);
                    }
                }
            }
            this.planTypesDropdown = planTypes;
        },
        getPpoNetworkDropdown() {
            var ppoNetworks = [];
            var plans = this.getPlans();

            if (plans.length) {
                for (var i = 0, planLen = plans.length; i < planLen; i++) {
                    var ppoNetwork = plans[i].planDetails.usaPPOName ? plans[i].planDetails.usaPPOName : null;
                    // var newPPONetwork;

                    if (ppoNetwork) {
                        ppoNetworks = this.checkIfBothUnitedNfirstHealth(ppoNetwork, ppoNetworks);
                    }
                }
            }

            this.ppoNetworkDropdown = ppoNetworks;
        },
        getPpoNetworkChecks() {
            var ppoNetworks = [];
            var plans = this.getPlans();

            if (plans.length) {
                for (var i = 0, planLen = plans.length; i < planLen; i++) {
                    var ppoNetwork = plans[i].planDetails.usaPPO;
                    var newPPONetwork;

                    if (ppoNetwork!=null && ppoNetworks.length) {
                        var found = false;

                        for (var j = ppoNetworks.length-1; j > -1; j--) {
                            if (ppoNetworks[j].text === ppoNetwork) {
                                found = true;
                                break;
                            }
                        }

                        if (!found && ppoNetwork != null) {
                            newPPONetwork = {
                                text: ppoNetwork,
                                value: ppoNetwork
                            };
                            ppoNetworks.push(newPPONetwork);
                        }
                    } else if(ppoNetwork != null){
                        newPPONetwork = {
                            text: ppoNetwork,
                            value: ppoNetwork
                        };

                        ppoNetworks.push(newPPONetwork);
                    }else{
                        continue;
                    }
                }
            }

            this.ppoNetworkChecks = ppoNetworks;
        },
        getNarrowSearchState () {
            if(!this.ssCriteria?.region) return;
            let stateAbrv = this.ssCriteria.region;
            let stateFull = null;

            for (var i = this.states.length - 1; i >= 0; i--) {
                const state = this.states[i];
                if (state.code == stateAbrv) {
                    stateFull = state.state;
                    break;
                }
            }

            this.narrowSearchState = stateFull;
        },
        getNarrowSearchCountry () {
            let countryAbrv = this.ssCriteria?.homeCountry;
            let countryFull = null;

            if (this.countries?.length) {
                for (var i = this.countries.length - 1; i >= 0; i--) {
                    const country = this.countries[i];
                    if (country.newCode == countryAbrv) {
                        countryFull = country.country;
                        break;
                    }
                }
            } 

            if(this.narrowSearchCountry != countryFull) {
                this.narrowSearchCountry = countryFull;
                if(this.globalSection == 'multitrip' && this.narrowSearchCountry) this.getInputSummaryContent();
            }

            this.narrowSearchCountry = countryFull;
        },
        getShowFixedHeaderForBestSeller() {
            if(this.list0Comp.length > 0){
                for(let i=0;i<this.list0Comp.length;i++){
                    if(this.list0Comp[i].planDetails.config.bestseller){
                        return true;
                    }
                }
                return false;
            }
            return false;
        },
        getShowNarrowOrSort(){
            var self = this;
            var plans = self.getPlans();
            var showNarrow = true;
            if(plans.length <= 1){
                showNarrow=false;
            }
            this.showNarrowOrSort = showNarrow;
        },
        getShowComprehensiveHeaderForBestSeller() {
            if(this.list1Comp.length > 0){
                for(let i=0;i<this.list1Comp.length;i++){
                    if(this.list1Comp[i].planDetails.config.bestseller){
                        return true;
                    }
                }
                return false;
            }
            return false;
        },
        getTravelersTotal: function() {
            const ssCriteria = this.ssCriteria;
            if (this.section == 'groupTravelMedical' && ssCriteria.hasOwnProperty('travelerInfos')) {
                let travlText = '';
                for (let i = 0; i < ssCriteria.travelerInfos.length; i++) {
                    const traveler = ssCriteria.travelerInfos[i];
                    if (i > 0 && travlText) travlText += ', ';
                    if (traveler.ageGroup == 0) {
                        travlText += qResultsLang.ageGroupTravel0 + ` (${traveler.numOfTraveler})` ;
                    }
                    else if (traveler.ageGroup == 17) {
                        travlText += qResultsLang.ageGroupTravel17 + ` (${traveler.numOfTraveler})` ;
                    }
                    else if (traveler.ageGroup == 18) {
                        travlText += qResultsLang.ageGroupTravel18 + ` (${traveler.numOfTraveler})` ;
                    }
                    else if (traveler.ageGroup == 30) {
                        travlText += qResultsLang.ageGroupTravel30 + ` (${traveler.numOfTraveler})` ;
                    }
                    else if (traveler.ageGroup == 40) {
                        travlText += qResultsLang.ageGroupTravel40 + ` (${traveler.numOfTraveler})` ;
                    }
                    else if (traveler.ageGroup == 50) {
                        travlText += qResultsLang.ageGroupTravel50 + ` (${traveler.numOfTraveler})` ;
                    }
                    else if (traveler.ageGroup == 60) {
                        travlText += qResultsLang.ageGroupTravel60 + ` (${traveler.numOfTraveler})` ;
                    }
                    else if (traveler.ageGroup == 70) {
                        travlText += qResultsLang.ageGroupTravel70 + ` (${traveler.numOfTraveler})` ;
                    }
                    else if (traveler.ageGroup == 80) {
                        travlText += qResultsLang.ageGroupTravel80 + ` (${traveler.numOfTraveler})` ;
                    }
                    
                }
                return travlText;
            } else if (ssCriteria.hasOwnProperty('travelerInfos') && ssCriteria.travelerInfos?.length) {
                return ssCriteria.travelerInfos.reduce((total, traveler) => {
                    if(isNaN(traveler.numOfTraveler)){
                        return total;
                    }
                    return total + Number(traveler.numOfTraveler);
                  }, 0);
            }
            return 0;
        },
        getListsLengthAfterNarrow(atCreated) {
            if (this.getListLength && typeof this.getListLength === 'function') {
                this.$nextTick(() => {
                    const lengths = this.getListLength();
                    this.listsLengthWithNarrow.allPlansLength = lengths.allPlansLength;
                    this.listsLengthWithNarrow.comprehensiveLength = lengths.comprehensiveLength;
                    this.listsLengthWithNarrow.fixedLength = lengths.fixedLength;
                    this.listsLengthWithNarrow.bestSellersLength = lengths.bestSellersLength;
                    if (("Best Sellers" == this.displaySelectedView && !this.listsLengthWithNarrow.bestSellersLength) ||
                        (!this.listsLengthWithNarrow.comprehensiveLength && "Comprehensive" == this.displaySelectedView)||
                        (!this.listsLengthWithNarrow.fixedLength  && "Fixed" == this.displaySelectedView)) {
                        this.displaySelectedView = "All Plans";
                        this.plansToView = 'all';
                        this.narrow.planType = '';
                    }
                    if (atCreated && 
                        !lengths.allPlansLength && 
                        !lengths.comprehensiveLength && 
                        !lengths.fixedLength && 
                        !lengths.bestSellersLength) {
                        this.toggleViewEdit(true);
                    }
                });
            }
        },
        print: function()  {
            if(window.print) {
                try {
                    window.print()
                } catch (e) { alert("Your device does not support print preview which is needed to view the receipt. Please try an alternate device.") }
            } else {
                console.log("Device does not support print.")
            }
        },
        toggleCompareAll: function() {
            if( this.allCompared ) {
                this.allCompared = false; 
                this.cancelCompare();
            }
            else {
                this.allCompared = true; 
                this.compareAll();
            }
        },
        setSort: function (e) {
            // this.$emit('set-sort', e.target.value);
            setItemSessionStorage('sort'+window.globalLanguage.section, e.target.value);
        },
        setWidth (e) {
            const self = this;
            const deb = debounce((e)=>{
                if(e.width > 991) {
                    this.collapsedSB = this.forcedCollapse;
                }
                else if(e.width <= 991 && !this.forcedCollapse) {
                    this.collapsedSB = true;
                }
            }, 150);
            deb.call(this, e);
        },
        resetCaptcha: function () {
            if (this.$refs && this.$refs.captchaimg) {
                this.$refs.captchaimg.src = this.$refs.captchaimg.src + '?' + new Date().valueOf();
            }
        },
        toggleMinimizeAll: function() {
            this.allMinimized = !this.allMinimized;
            this.narrow.viewSize = this.allMinimized ? 1 : 2;
            Vue.nextTick(()=>{
                setItemSessionStorage('allMinimized', this.allMinimized.toString());
            })

        },
        toggleCollapseSB (forceAct) {
            this.collapsedSB = forceAct ? forceAct : !this.collapsedSB;
            if (this.collapsedSB) this.showMobileUpdateResult = false;
            this.forcedCollapse = this.collapsedSB;
            Vue.nextTick(()=>{
                setItemSessionStorage('collapsedSB', this.collapsedSB.toString());
            });
        },
        sortDefault: function(planA, planB) {
            return 0;
        },
        submitCompare: function() {

            var self = this;
            var planIds = [];
            var planTypesList = [];
            var planPolicyMaxList = [];
            var arr = this.plansToCompare;
            var planDeductibles = [];
            var planPrices = [];
            let coinsurances = [];
            let studyCountries =[];

            self.compareSending = true;
            var spinTarget = this.$refs.compareButton;
            var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);

            // build array of plan ids and plan types
            for ( var i=arr.length-1; i>=0; i--) {
                planIds.push(arr[i].planId);
                planTypesList.push(arr[i].planType);
                planPolicyMaxList.push(arr[i].policyMax);
                planDeductibles.push(arr[i].deductible);
                planPrices.push(arr[i].price);
                if([712,713].indexOf(arr[i].planId) > -1){
                    coinsurances.push(arr[i].coInsurance);
                }
                else{
                    coinsurances.push('-1');
                }

                let companyCode = arr[i].displayCode.split('_')[0];

                if(['IMG','WorldTrips'].indexOf(companyCode)>-1){
                    if(["studentmedical","opt","j1Visa","student"].indexOf(this.section)>-1){
                        studyCountries.push(this.ssCriteria.studyCountry || 'USA');
                    }
                }
                else{
                    studyCountries.push('');
                }
            }
            planIds.reverse();
            planTypesList.reverse();
            planPolicyMaxList.reverse();
            planPrices.reverse();
            planDeductibles.reverse();
            coinsurances.reverse();
            studyCountries.reverse();

            if (this.windowWidth < 768) {
             self.currentPageMaxPlansDisplay = 2;
            } else if (this.windowWidth < 992) {
             self.currentPageMaxPlansDisplay = 3;
            } else {
             self.currentPageMaxPlansDisplay = 4;
            }

            var compareData = {
                plans: planIds,
                action: 'freshCompare',
                section: this.section,
                planTypes: planTypesList,
                policyMaximum : this.policyMax,
                numPlansPerPage:  self.currentPageMaxPlansDisplay,
                fullQuoteId : self.planData.fullQuoteId,
                policyMaximums: planPolicyMaxList,
                prices: planPrices,
                deductibles: planDeductibles,
                coinsurances,
                studyCountries
            };
            setItemSessionStorage('fullQuoteId', window.ibJS.fullQuoteId || '');

            self.$http.post(ibJS.compareAPIPath+'?lang='+self.currentLang, compareData)
                .then(
                    function(response) {
                        var responseBody = response.body;
                        if (responseBody.status === 'success') {
                            self.$http.get(window.ibJS.comparePagePath)
                                .then(function(response) {
                                    window.location.href = window.ibJS.comparePagePath;
                                    return;
                                },
                                function(response) {
                                    var responseBody = response.body;
                                    var errors = {};
                                    errors.serverErrors = responseBody.data;
                                    self.showServer500Error(errors);
                                    $("body").animate({ scrollTop: 0 }, "fast");
                                    self.dataFetched = true;
                            });

                        } else {
                            if (responseBody.data?.refresh) {
                                window.location.href = responseBody.data.redirectTo;
                                return;
                            }
                            self.compareSending = false;
                        }
                    },
                    function(response) {
                        var responseBody = response.body;
                        var errors = {};
                        errors.serverErrors = responseBody.data;
                        self.showServer500Error(errors);
                        $("body").animate({ scrollTop: 0 }, "fast");
                        self.compareSending = false;
                    }
                );
        },
        addCompare: function(plan) {

            for(var i=0;i<this.numOfPlansLists;i++) {
                var tempList = this['list' + i + 'Comp'];
                if(tempList.length) {
                    for(var j=tempList.length-1; j>=0;j--) {
                        if(tempList[j].planId == plan.planId) {
                            tempList[j].compare=true;
                       }
                    }
                }
                tempList = this['list' + i];
                if(tempList.length) {
                    for(var j=tempList.length-1; j>=0;j--) {
                        if(tempList[j].planId == plan.planId) {
                            tempList[j].compare=true;
                       }
                    }
                }

            }
            if(!this.plansToCompare.filter(item => item.planId == plan.planId).length) {
                this.plansToCompare.push(plan);
            }
        },
        removeCompare: function(planId) {
            var arr = this.plansToCompare;
            this.allCompared = false;

            // remove plan from plansToCompare array
            for ( var i=arr.length-1; i>=0; i--) {
                if ( arr[i].planId == planId) {
                    arr.splice(i,1);
                }
            }

            // set compare to false for plan in plans array
           for(var i=0;i<this.numOfPlansLists;i++) {
                var tempList = this['list' + i + 'Comp'];
                if(tempList.length) {
                    for(var j=tempList.length-1; j>=0;j--) {
                        if(tempList[j].planId == planId) {
                            tempList[j].compare=false;
                       }
                    }
                }
                tempList = this['list' + i];
                if(tempList.length) {
                    for(var j=tempList.length-1; j>=0;j--) {
                        if(tempList[j].planId == planId) {
                            tempList[j].compare=false;
                       }
                    }
                }

            }
        },
        compareAll: function() {
            var self = this;
            // included the selected plans which from the another tabs
            self.plansToCompare = (self.plansToCompare && self.plansToCompare.length > 0) ? self.plansToCompare : [];
            let allPlans = [];

            for(let i=0; i < this.numOfPlansLists; i++){
                let list =  this['list' + i + 'Comp'];
                list.forEach( x => {
                    // tick the Compare box
                    x.compare = true;
                    let selectedDeductible = 0;
                    //Adding deductible and policymaxium
                    let policyMaximum = x.planBlock && x.planBlock.policymaximum ? x.planBlock.policymaximum : x.policyMaximum;
                    for(let y in x.itemPrices) {
                        if(x.itemPrices[y].price === x.defaultTotalPrice) {
                            selectedDeductible = x.itemPrices[y].deductible;
                            if(x.itemPrices[y].policyMaximum){
                                policyMaximum = parseInt(x.itemPrices[y].policyMaximum);
                            }
                            break;
                        }
                    }

                    // set price note: cancel for any reason logic
                    let thisPlanPrice = x.planBlock && x.planBlock.price ? x.planBlock.price : x.defaultTotalPrice;
                    if ((this.narrow.cancelAnyReason && x.cancelAnyReason) || (this.narrow.cancelCovidReason && x.cancelAnyReason)) {
                        if(x.planId == 676) {
                            let teladocPrice = this.ssCriteria.travelerInfos.length * 2;
                            thisPlanPrice = x.cfarPriceFactor * (thisPlanPrice - teladocPrice) + thisPlanPrice;
                        } else {
                            thisPlanPrice = (x.cfarPriceFactor * thisPlanPrice) + thisPlanPrice;
                        }
                    }

                    // let newPlan = {
                    //     deductible: x.planBlock ? x.planBlock.deductible : selectedDeductible,
                    //     displayCode: x.planDetails.config.displayCode,
                    //     displayName: x.planDetails.config.displayNameWSym || x.planDetails.planName || x.planDetails.config.displayName,
                    //     planColor: x.planDetails.config.planColor,
                    //     planId: x.planId,
                    //     planType: x.planDetails.config.planType,
                    //     policyMax: x.planBlock && x.planBlock.policymaximum ? x.planBlock.policymaximum : x.policyMaximum,
                    //     price: thisPlanPrice,
                    //     planDetails: x.planDetails,
                    //     cancelAnyReason: x.cancelAnyReason,
                    //     cancelWorkReason: x.cancelWorkReason,
                    //     covidCoverage: x.covidCoverage,
                    //     covidQuarantineCoverage: x.covidQuarantineCoverage
                    // }
                    let newPlan = {
                        ...x,
                        deductible: x.planBlock ? x.planBlock.deductible : selectedDeductible,
                        displayCode: x.planDetails.config.displayCode,
                        displayName: x.planDetails.config.displayNameWSym || x.planDetails.planName || x.planDetails.config.displayName,
                        planColor: x.planDetails.config.planColor,
                        planType: x.planDetails.config.planType,
                        policyMax: policyMaximum,
                        price: thisPlanPrice,
                    };
                    if(!allPlans.filter(item => item.planId == newPlan.planId).length) {
                        allPlans.push(newPlan);
                    }
                });
            }

            // allPlans = this.filterPlans(allPlans);

            Vue.nextTick( () => self.allCompared = true );

            const planIds = self.plansToCompare.map(plan => plan.planId);
            self.plansToCompare = [...self.plansToCompare, ...allPlans.filter(plan => planIds.indexOf(plan.planId) == -1)];
        },
        cancelCompare: function() {

            for(var i=0; i < this.numOfPlansLists; i++) {
                var tempList = this['list' + i + 'Comp'];
                if(tempList.length) {
                    for(var j = tempList.length-1; j >= 0; j--) {
                            tempList[j].compare=false;
                    }
                }
                tempList = this['list' + i];
                if(tempList.length) {
                    for(var j = tempList.length-1; j >= 0; j--) {
                            tempList[j].compare=false;
                    }
                }
            }

            this.plansToCompare = [];
            this.allCompared = false;
        },
        updateCompare: function(plan) {
            for(let i = 0; i < this.plansToCompare.length; i++){
                if(this.plansToCompare[i].planId == plan.planId) {
                    this.plansToCompare[i] = plan;
                    this.$forceUpdate();
                    break;
                }
            }
        },
        toggleViewEdit: function (toOpen) {
            this.saveShareShown = false;

            this.editQuoteShown = toOpen != null && typeof toOpen === 'boolean' ? toOpen : !this.editQuoteShown;
            
            this.cancelCompare();

            if(this.section == 'expatriate' || this.section == 'missionary' || this.section == 'marine')
            {
                if(this.ssCriteria.spouseAge == -1)
                {
                    this.ssCriteria.spouseAge = null;
                }
            }
        },
        storeNarrow: function () {
            window.sessionStorage[window.globalLanguage.sectionNarrow] = JSON.stringify(this.narrow);
            this.getListsLengthAfterNarrow();
            this.cancelCompare();
        },
        resetNarrow: function () {
            this.narrow = {
                administrator: '',
                cancelAnyReason: false,
                cancelCovidReason: false,
                cancelWorkReason: false,
                preExistCondWaiver: false,
                specificPlan: [],
                underwriter: '',
                planType: '',
                ppoNetworkCheck: false,
                ppoNetwork: '',
                mailsInsuranceCard: false,
                extendable: false,
                refundable: false,
                acuteOnSetCondition: false,
                maternity:false,
                greenCardVisitor:false,
                coveragearea: this.ssCriteria.coverageArea ? this.ssCriteria.coverageArea.toString() : '',
                covidCoverage: false,
                covidQuarantine: getItemSessionStorage('quarantineCoverageCosta') && window.ibJS.section == "travelOutsideUSA" && this.narrow.covidQuarantine ? true : false
            };
            this.setNarrow();
        },
        showNoPlansFoundError: function (fullQuoteId) {
            this.planData = {};
            this.noPlansFoundShown = true;
            this.showSaveQuoteBtn = false;
            if (fullQuoteId) this.fullQuoteId = fullQuoteId;
            if (window.innerWidth < 768) this.toggleCollapseSB(false);
            let ssCriteria = getItemSessionStorage(window.globalLanguage.sectionCriteriaKey);
            if (ssCriteria) {
                ssCriteria = JSON.parse(ssCriteria);
                if(ssCriteria.currentLang && ssCriteria.currentLang != 'en') {
                    if(ssCriteria.startDate) ssCriteria.startDate = convertDateStringByEnDate(ssCriteria.startDate, ssCriteria.currentLang);
                    if(ssCriteria.endDate) ssCriteria.endDate = convertDateStringByEnDate(ssCriteria.endDate, ssCriteria.currentLang);
                    if(ssCriteria.departureDate) ssCriteria.departureDate = convertDateStringByEnDate(ssCriteria.departureDate, ssCriteria.currentLang);
                    if(ssCriteria.returnDate) ssCriteria.returnDate = convertDateStringByEnDate(ssCriteria.returnDate, ssCriteria.currentLang);
                }
                this.ssCriteria = ssCriteria;
            }
        },
        setBackendErrors: function setBackendErrors(errors) {
            if (errors.fieldErrors) {
                this.fieldErrors = errors.fieldErrors;
            }
            if (errors.globalErrors) {
                this.globalErrors = errors.globalErrors;
            }
        },
        showTripDaysError () {
            this.planData = {};
            this.error365Days = true;
            this.showSaveQuoteBtn = false;
            if (window.innerWidth < 768) this.toggleCollapseSB(false);
        },
        getPlans: function(){
            var self = this;
            if (!self.dataFetched) {
                return [];
            }
            if(self.noPlansFoundShown || typeof self.planData.order == 'undefined'){
                return [];
            }
            var order = self.planData.order;

            var list = [];
            if (order) {
                for(var i=0;i<order.length;i++){
                    list.push(self.planData[self.planData.order[i]]);
                }
            }

            var plans=[];
            for(var i=0; i<list.length;i++){
                if(list[i] != undefined){
                    var tempList = list[i];
                    for(var j=0;j<tempList.length;j++){
                        plans.push(tempList[j]);
                    }
                }
            }
            return plans;
        },
        setPlansToView: function(plansToView) {
            if (plansToView) {
                switch (plansToView) {
                    case 'bestsellers':
                        this.plansToView = 'bestsellers';
                        this.displaySelectedView = 'Best Sellers';
                        if(this.narrow){
                            this.narrow.planType = 3
                        }
                        break;
                    case 'comprehensive':
                        this.plansToView = 'comprehensive';
                        this.displaySelectedView = 'Comprehensive';
                        if(this.narrow){
                            this.narrow.planType = 2
                        }
                        break;
                    case 'fixed':
                        this.plansToView = 'fixed';
                        this.displaySelectedView = 'Fixed';
                        if(this.narrow){
                            this.narrow.planType = 1
                        }
                        break;
                    case 'includingUSA':
                        this.plansToView = 'includingUSA';
                        this.displaySelectedView = 'Including USA';
                        if(this.narrow){
                            this.narrow.planType = 1
                        }
                        break;
                    case 'excludingUSA':
                        this.plansToView = 'excludingUSA';
                        this.displaySelectedView = 'Excluding USA';
                        if(this.narrow){
                            this.narrow.planType = 2
                        }
                        break;
                    case 'evac':
                        this.plansToView = 'evac';
                        this.displaySelectedView = 'Evacuation';
                        break;
                    default:
                        this.plansToView = 'all';
                        this.displaySelectedView = 'All Plans';
                        if(this.narrow){
                            this.narrow.planType = ''
                        }
                }
            } else {
                this.plansToView = 'all';
            }
            this.storeNarrow();
            setItemSessionStorage("storageSection", this.section);
            setItemSessionStorage("tabToView", plansToView);
            setItemSessionStorage("displayTabName", this.displaySelectedView);

        },
        changeDeductible: function(value){
            if(value=='reduce'){
                this.decrementDeductible = true;
            }else{
                this.incrementDeductible = true;
            }
            this.cancelCompare();
        },
        resetDeductible:function(value){
            this.incrementDeductible=value;
            this.decrementDeductible=value;
        },
        toggleSiblingMinimizedState : function (siblingToToggle) {
            this.$emit('toggleSibling',siblingToToggle);
        },
        checkIfBothUnitedNfirstHealth: function (ppoNetwork, ppoNetworkArray) {
            let hasBothType = false;
            let unitedHealthPPO = 'United Healthcare PPO';
            let firstHealthPPO = 'First Health PPO';
            
            if (ppoNetwork === 'United Healthcare PPO or First Health PPO' ||
                ppoNetwork === 'United Healthcare PPO 或 First Health PPO' ||
                ppoNetwork === 'United Healthcare PPO o First Health PPO'
            ) {
                hasBothType = true;
            }

            let allItemsInArray = [];
            let value;
            for(let i = 0, arrayLength = ppoNetworkArray.length ; i < arrayLength; i++) {
                value = ppoNetworkArray[i].value;
                if (allItemsInArray.indexOf(value) === -1) allItemsInArray.push(value);
            }            

            if(hasBothType) {
                if (allItemsInArray.indexOf(unitedHealthPPO) === -1 && !this.isGdprCountry) {
                    ppoNetworkArray.push({
                        text: unitedHealthPPO,
                        value: unitedHealthPPO
                    });
                }
                if (allItemsInArray.indexOf(firstHealthPPO) === -1 && this.isGdprCountry) {
                    ppoNetworkArray.push({
                        text: firstHealthPPO,
                        value: firstHealthPPO
                    });
                }
            } else {
                if (allItemsInArray.indexOf(ppoNetwork) === -1) {
                    ppoNetworkArray.push({
                        text: ppoNetwork,
                        value: ppoNetwork
                    });
                }
            }

            return ppoNetworkArray;
        },
        handleRemarks (e) {
            const termsModal = e.target?.classList.contains('o-Question');
            if (termsModal) {
                const sectionUrl = e.target.getAttribute('data-modal-url');
                this.showModal(sectionUrl);
            }
            if(e.target?.classList.contains('open-edit-modal')) {
                this.toggleViewEdit();
                this.defaultStep = 4; //Go to step 4 dates directly. 
                setTimeout(() => {
                    this.defaultStep = 1;
                }, 2000);
            }
        },
        setNarrow(name, value) {
            if (name) this.narrow[name] = value;
            Vue.nextTick(()=>{
                this.updateAllLists();
                this.storeNarrow();
                this.showMobileUpdateResult = this.collapsedSB ? false : true;
            });
        },
        updateAllLists () {
            this.getList0Comp();
            this.getList1Comp();
            this.getList2Comp();
            this.getList3Comp();
            this.getList4Comp();
        },
        setSpecificPlan() {
            this.$nextTick(() => {
                this.storeNarrow();
                this.showMobileUpdateResult = this.collapsedSB ? false : true;
            })
        },
        toggleSaveShare () {
            this.saveShareShown = !this.saveShareShown;
            if (this.saveShareShown === true) {
                // Send narrow criteria
                this.resetSSErrors();

                var data = this.narrow;
                data.id = this.planData.fullQuoteId;

                // data.piuCovidCheck = this.piuCovidCheck;

                if(this.section !== 'trip' && this.section !== 'flight' && window.sessionStorage[window.globalLanguage.sectionCriteriaKey]){
                    var sessData = JSON.parse(window.sessionStorage[window.globalLanguage.sectionCriteriaKey]);
                    data.citizenshipCountry = sessData.citizenshipCountry;
                    data.startDate = sessData.startDate;
                    data.endDate = sessData.endDate;
                    data.arrivalTime = sessData.arrivalTime || "";
                    data.tripDeparture = sessData.tripDeparture || "";
                    if(sessData.travelerInfos?.length) {
                        data.tripDeparture = JSON.stringify(sessData.travelerInfos);
                    }
                    let allListedPlans = [...this.list0Comp, ...this.list1Comp, ...this.list2Comp, ...this.list3Comp, ...this.list4Comp];
                    data.quoteDeductibles = allListedPlans.map((item) => {
                        let planDetials = {
                            planId: item.planId,
                            deductible: item.planBlock && item.planBlock.deductible ? item.planBlock.deductible : item.defaultDeductible
                        };
                        
                        return planDetials;
                    })
                }

                // Global Big Spinner
                var spinTarget = document.getElementById('save-share-spinner');
                var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);
                setTimeout(() => {
                    this.$http.post(window.ibJS.saveNarrowQuoteAPIPath, data)
                        .then(response => {
                                this.sendingNarrowCriteria = false;
                                var responseBody = response.body;

                                if (responseBody.status === 'error') {
                                    if (responseBody?.data?.globalErrors) {
                                        this.globalErrors = responseBody.data.globalErrors;
                                    }
                                    this.saveShareShown = false;
                                } 
                            },response => {
                                this.saveShareShown = false;
                                var responseBody = response.body;
                                var errors = {};
                                errors.serverErrors = responseBody.data;
                                this.showServer500Error(errors);
                                $("body").animate({ scrollTop: 0 }, "fast");
                            }
                        );
                }, 0);

            }
        },
        changeDefaultTotalPrice(planId, value) {
            for(let i=0; i < this.numOfPlansLists; i++){
                for(let j = 0; j < this['list' + i].length; j++){
                    if(this['list' + i][j].planId == planId) {
                        this['list' + i][j].defaultTotalPrice = value;
                        if (this['list' + i][j]?.keyId) {
                            const keyMatch = this['list' + i][j].keyId.match(/-\d{0,}/g);
                            if (keyMatch && keyMatch.length) {
                                let keyId = keyMatch[0].replace('-','');
                                this['list' + i][j].keyId = `${planId}-${+keyId + 1}`;
                            }
                        }
                        break;
                    }
                }
                for(let j = 0; j < this['list' + i + 'Comp'].length; j++){
                    if(this['list' + i + 'Comp'][j].planId == planId) {
                        this['list' + i + 'Comp'][j].defaultTotalPrice = value;
                        if (this['list' + i + 'Comp'][j]?.keyId) {
                            const keyMatch = this['list' + i + 'Comp'][j].keyId.match(/-\d{0,}/g);
                            if (keyMatch && keyMatch.length) {
                                let keyId = keyMatch[0].replace('-','');
                                this['list' + i + 'Comp'][j].keyId = `${planId}-${+keyId + 1}`;
                            }
                        }
                        break;
                    }
                }
            }
        },
        setPlanBlock(planId, planBlock) {
            for(let i=0; i < this.numOfPlansLists; i++){
                for(let j = 0; j < this['list' + i].length; j++){
                    if(this['list' + i][j].planId == planId) {
                        if(!this['list' + i][j].planBlock) this['list' + i][j].planBlock = {};
                        for(let item of Object.keys(planBlock)) {
                            this['list' + i][j].planBlock[item] = planBlock[item];
                        }
                        break;
                    }
                }
                for(let j = 0; j < this['list' + i + 'Comp'].length; j++){
                    if(this['list' + i + 'Comp'][j].planId == planId) {
                        if(!this['list' + i + 'Comp'][j].planBlock) this['list' + i + 'Comp'][j].planBlock = {};
                        for(let item of Object.keys(planBlock)) {
                            this['list' + i + 'Comp'][j].planBlock[item] = planBlock[item];
                        }
                        break;
                    }
                }
            }
        }
    }
};

export { QuoteResults };
