const txtTo = qFormLang?.fields?.to || 'to';

const travelMedicalPolicyMaxOptions = [
    {
        value: 5,
        displayText: `$10,000 ${txtTo} $25,000`
    },
    {
        value: 20,
        displayText: `$50,000 ${txtTo} $60,000`
    },
    {
        value: 30,
        displayText: '$75,000'
    },
    {
        value: 40,
        displayText: `$100,000 ${txtTo} $110,000`
    },
    {
        value: 50,
        displayText: `$130,000 ${txtTo} $200,000`
    },
    {
        value: 60,
        displayText: '$250,000'
    },
    {
        value: 70,
        displayText: `$500,000 ${txtTo} $550,000`
    },
    {
        value: 80,
        displayText: `$1,000,000 ${txtTo} $1,100,000`
    },
    {
        value: 90,
        displayText: '$2,000,000'
    },
    {
        value: 100,
        displayText: '$5,000,000'
    },
    {
        value: 110,
        displayText: '$8,000,000'
    },
];

const SchengenPolicyMaxOptions = [
    {
        value: 20,
        displayText: `$50,000 ${txtTo} $60,000`
    },
    {
        value: 30,
        displayText: '$75,000'
    },
    {
        value: 40,
        displayText: `$100,000 ${txtTo} $110,000`
    },
    {
        value: 50,
        displayText: `$130,000 ${txtTo} $200,000`
    },
    {
        value: 60,
        displayText: '$250,000'
    },
    {
        value: 70,
        displayText: `$500,000 ${txtTo} $550,000`
    },
    {
        value: 80,
        displayText: `$1,000,000 ${txtTo} $1,100,000`
    },
    {
        value: 90,
        displayText: '$2,000,000'
    },
    {
        value: 100,
        displayText: '$5,000,000'
    },
    {
        value: 110,
        displayText: '$8,000,000'
    },
];

const groupTravelMedicalPolicyMaxOptions = [
    {
        value: 5,
        displayText: `$10,000 ${txtTo} $20,000`
    },
    {
        value: 20,
        displayText: '$50,000'
    },
    {
        value: 40,
        displayText: '$100,000'
    },
    {
        value: 50,
        displayText: `$130,000 ${txtTo} $200,000`
    },
    {
        value: 65,
        displayText: '$250,000'
    },
    {
        value: 70,
        displayText: '$500,000'
    },
    {
        value: 80,
        displayText: `$1,000,000 ${txtTo} $1,100,000`
    },
    {
        value: 90,
        displayText: '$2,000,000'
    },
    {
        value: 100,
        displayText: '$5,000,000'
    },
    {
        value: 110,
        displayText: '$8,000,000'
    },
];

export {
    travelMedicalPolicyMaxOptions,
    SchengenPolicyMaxOptions,
    groupTravelMedicalPolicyMaxOptions,
}