class Travelex {
    constructor () {
        this.Travel_Basic_Parent = 361
        this.Travel_Select_Parent = 362
        this.Travel_Basic_All_Other_States = 662
        this.Travel_Basic_Florida = 663
        this.Travel_Basic_New_York = 664
        this.Travel_Basic_Washington = 665
        this.Travel_Select_All_Other_States = 666
        this.Travel_Select_Florida = 667
        this.Travel_Select_New_York = 668
        this.Travel_Select_Washington = 669
        this.Flight_Insure = 671
        this.Flight_Insure_Plus = 672
    }

    allInAppIds () {
        return [
            this.Travel_Basic_Parent,
            this.Travel_Select_Parent,
            this.Travel_Basic_All_Other_States,
            this.Travel_Basic_Florida,
            this.Travel_Basic_New_York,
            this.Travel_Basic_Washington,
            this.Travel_Select_All_Other_States,
            this.Travel_Select_Florida,
            this.Travel_Select_New_York,
            this.Travel_Select_Washington,
            this.Flight_Insure,
            this.Flight_Insure_Plus
        ]
    }

    isTripInsurance () {
        return [
            this.Travel_Basic_Parent,
            this.Travel_Select_Parent,
            this.Travel_Basic_All_Other_States,
            this.Travel_Basic_Florida,
            this.Travel_Basic_New_York,
            this.Travel_Basic_Washington,
            this.Travel_Select_All_Other_States,
            this.Travel_Select_Florida,
            this.Travel_Select_New_York,
            this.Travel_Select_Washington
        ];
    }

    isFlightInsurance () {
        return [
            this.Flight_Insure,
            this.Flight_Insure_Plus
        ]
    }

    allPlanIds () {
        return [
            this.Travel_Basic_Parent,
            this.Travel_Select_Parent,
            this.Travel_Basic_All_Other_States,
            this.Travel_Basic_Florida,
            this.Travel_Basic_New_York,
            this.Travel_Basic_Washington,
            this.Travel_Select_All_Other_States,
            this.Travel_Select_Florida,
            this.Travel_Select_New_York,
            this.Travel_Select_Washington,
            this.Flight_Insure,
            this.Flight_Insure_Plus
        ]
    }
}

export default Travelex;