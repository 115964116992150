import { 
    convertDateToEN, 
    monthDate, 
    numbersSpacesAndLetters,
    createDateByLangApplications,
    saveApplication,
    onlyAlphaNumeric,
    getParameterByName,
    mobileAppLogout
} from '../../../helper-functions/helper-functions';
import { ibSpinOpts } from '../../../helper-functions/applicationCommonValues';
import { hideValidationsWhileTyping, setItemSessionStorage } from '../../../helper-functions/services';
import { required, email } from 'vuelidate/lib/validators';
import datePicker from '../../../components/forms/component.date-picker.vue';
import Radio from '../../../components/components.radio-button.vue';
// import Vuelidate from 'vuelidate'
// import Vue from 'vue';

// Vue.use(Vuelidate);

// global.jQuery = require('jquery');
// var $ = global.jQuery;
// window.$ = $;

let loginModalLoadMouseoutComponent = function() {
    if (this.renderHtml) {
        return {
            components: {
                'date-picker': datePicker,
                'v-radio': Radio
            },
            template: this.renderHtml,
            data: function() {
                return {
                    loginStatus: '',
                    beError: '', 
                    btnSpinning: false,
                    continueBtnSpinning: false,
                    form: {...form},
                    isFailed: false,
                    isNotAvailable: false,
                    showCertDob: true,
                    affiliateNumber: ibJS.affiliateNumber,
                    landing: false,
                    group: false,           
                    otp: '',
                    affiliateid: window.globalLanguage.affiliateid,
                    myAccountLogin: window.globalLanguage.myAccountLogin,
                    ipAddress: window.globalLanguage.internalIP,
                    showLogin: sessionStorage.getItem('isShowLogin'),
                    memberIdStep2: false,
                    loginType: "certNo",
                    qResultsLang: window.qResultsLang,
                    fPlaceholder: null,
                    isLoggingIn: false,
                    isLocked: false,
                    pendingPeriod: {
                        hr: '',
                        min: ''
                    }
                }
            },
            created: function() {
                this.fPlaceholder = this.qResultsLang.certNoPlaceholder;
                let self = this;

                $("#cert").trigger("focus");

                if(this.showLogin && this.showLogin == 'true') {
                    $(document).ready(function() {
                    }).keyup(function (e) {
                        e.preventDefault();
                        if (e.keyCode == 27) {
                            self.closeModal();
                        }
                    });
                }
            },
            computed: {
                isShowLogin: function() {
                    if(this.showLogin && this.showLogin != 'false') {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            mounted: function () {
                if(this.isShowLogin) {
                    this.datePickerSetup();
                    this.addEventListeners();
                    hideValidationsWhileTyping(['dob']);
                
                    // $('#login-modal').click(e => {
                    //     this.closeModal();
                    // });
                    $('#login-modal-body').click(function (e) {
                        e.stopPropagation();
                    });
                    $('#cert').focus();
                }
            }, 
            validations() {
                let validations = {
                    form: {
                        certNo: {},
                    }
                };

                if(!this.isShowLogin) {
                   delete validations.form.certNo.required;
                   delete validations.form.certNo.numbersSpacesAndLetters;
                }

                if (!this.group) {
                    validations.form.dob = { required, monthDate };
               
                    if (this.loginType === 'memberId') {
                      validations.form.effectiveDate = { required, monthDate };
                    }
                } else {
                    validations.form.email = { required, email, onlyAlphaNumeric };
                }
            
                if (this.loginType === 'email') {
                    validations.form.email = { required, email };
                } else {
                    validations.form.certNo = { required, numbersSpacesAndLetters };
                }

                if (this.loginStatus === 'group-login' || this.loginStatus === 'email-login') {
                    validations.otp = { required, numbersSpacesAndLetters };
                }

                return validations;
            },
            watch: {
                'form.dob': function() {
                  this.beError = '';
                },
                'form.certNo': function() {
                  this.beError = '';
                },
                'loginType': function(val) {
                    if(!this.isFailed && !this.isNotAvailable && !this.isLocked) this.reset();

                    if (val === 'certNo' || this.group) {
                        this.fPlaceholder = this.qResultsLang.certNoPlaceholder;
                      } else if (val === 'memberId') {
                        this.fPlaceholder = this.qResultsLang.memberIdPlaceholder;
                      } else if (val === 'email') {
                        this.fPlaceholder = this.qResultsLang.emailPlaceholder;
                      }
                },
                'group': function() {
                    if(!this.isFailed && !this.isNotAvailable) this.reset();
              
                    if(this.group) this.loginType = 'certNo';
                  }
            },
            methods: {
                logout: function(myAcc) {
                    const currentUrl = window.location.href;
                    let apiUrl = '/myaccount/logout/';
                    let lang = window.globalLanguage.lang;
                    let withLangUrl = apiUrl + "?lang=" + lang;
                    let fromApp = sessionStorage.getItem("fromApp");
                    let url = null;

                    this.btnSpinning = true;
                    const spinTarget = this.$refs.logout;

                    setTimeout(() => {
                        new IBSpinner(ibSpinOpts).spin(spinTarget); 
                    }, 0);

                    mobileAppLogout();

                    if(myAcc) {
                        this.$http.get(withLangUrl).then(res => {
                            this.closeModal();
                            if(this.affiliateid && this.affiliateid !== 'none') {
                                if(currentUrl.includes('.brokersnexus.com')) {
                                    let domain = `${this.affiliateid}.brokersnexus.com`;
                                    url = currentUrl.split(domain);
                                    url = `${url[0]}insubuy.com${url[1]}`;

                                    if(fromApp && ibJS.application) {
                                        let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                        this.$http.get(api).then(res =>{
                                            let url = window.location.href.split(domain);
                                            url = `${url[0]}insubuy.com${url[1]}`;
                                        
                                            window.location.href=url;
                                        } );
                                    } else {
                                        window.location.href=url;
                                    }
        
                                } else if(currentUrl.includes('.brokersnexustest.com')) {
                                    let domain = `${this.affiliateid}.brokersnexustest.com`;
                                    url = currentUrl.split(domain);
                                    url = `${url[0]}insubuytest.com${url[1]}`;

                                    if(fromApp && ibJS.application) {
                                        let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                        this.$http.get(api).then(res =>{
                                            let url = window.location.href.split(domain);
                                            url = `${url[0]}insubuytest.com${url[1]}`;
                                        
                                            window.location.href=url;
                                        } );
                                    } else {
                                        window.location.href=url;
                                    }

                                }  
                                //Some Param
                                else if(currentUrl.indexOf('?') > -1) {
                                    let param = getParameterByName('affiliateid', window.location.href);
                                    let aidParam = getParameterByName('aid', window.location.href);

                                    console.log("aid ", aidParam);

                                    if(param && param !== 'none') {
                                        //case 1/2 --affiliateid param

                                        if(currentUrl.includes('?affiliateid=')) {
                                            url = currentUrl.split('?affiliateid');

                                            if(fromApp && ibJS.application) {
                                            
                                                if(window.location.href.indexOf('&') > -1) {
                                                    let otherParams = currentUrl.substr(currentUrl.indexOf('&'));

                                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                    this.$http.get(api).then(res =>{
                                                        window.location.href=`${url[0]}?affiliateid=none${otherParams}`;
                                                    } );
                                                    
                                                } else {
                                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                    this.$http.get(api).then(res =>{
                                                        window.location.href=`${url[0]}?affiliateid=none`;
                                                    } );
                                                }
                                            } else {
                                                if(window.location.href.indexOf('&') > -1) {
                                                    let otherParams = currentUrl.substr(currentUrl.indexOf('&'));
                                                    window.location.href=`${url[0]}?affiliateid=none${otherParams}`;
                                                } else {
                                                    window.location.href=`${url[0]}?affiliateid=none`;
                                                }
                                            }
                                        } else if(currentUrl.includes('&affiliateid=')) {
                                            url = currentUrl.split('&affiliateid');

                                            if(fromApp && ibJS.application) {
                                            
                                                if(url[1].indexOf('&') > -1) {
                                                    let otherParams = url[1].substr(url[1].indexOf('&'));

                                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                    this.$http.get(api).then(res =>{
                                                        window.location.href=`${url[0]}&affiliateid=none${otherParams}`;
                                                    } );
                                                    
                                                }  else {
                                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                    this.$http.get(api).then(res =>{
                                                        window.location.href=`${url[0]}&affiliateid=none`;
                                                    } );
                                                }
                                            } else {
                                                if(url[1].indexOf('&') > -1) {
                                                    let otherParams = url[1].substr(currentUrl.indexOf('&'));
                                                    window.location.href=`${url[0]}&affiliateid=none${otherParams}`;
                                                } else {
                                                    window.location.href=`${url[0]}&affiliateid=none`;
                                                }
                                            }
                                        }                           
                                       
                                    } else if(aidParam && aidParam !== 'none') {
                                        //case 1/2 --affiliateid param

                                        if(currentUrl.includes('?aid=')) {
                                            url = currentUrl.split('?aid');

                                            if(fromApp && ibJS.application) {
                                            
                                                if(window.location.href.indexOf('&') > -1) {
                                                    let otherParams = currentUrl.substr(currentUrl.indexOf('&'));

                                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                    this.$http.get(api).then(res =>{
                                                        window.location.href=`${url[0]}?aid=none${otherParams}`;
                                                    } );
                                                    
                                                } else {
                                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                    this.$http.get(api).then(res =>{
                                                        window.location.href=`${url[0]}?aid=none`;
                                                    } );
                                                }
                                            } else {
                                                if(window.location.href.indexOf('&') > -1) {
                                                    let otherParams = currentUrl.substr(currentUrl.indexOf('&'));
                                                    window.location.href=`${url[0]}?aid=none${otherParams}`;
                                                } else {
                                                    window.location.href=`${url[0]}?aid=none`;
                                                }
                                            }
                                        } else if(currentUrl.includes('&aid=')) {
                                            url = currentUrl.split('&aid');

                                            if(fromApp && ibJS.application) {
                                            
                                                if(url[1].indexOf('&') > -1) {
                                                    let otherParams = url[1].substr(url[1].indexOf('&'));

                                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                    this.$http.get(api).then(res =>{
                                                        window.location.href=`${url[0]}&aid=none${otherParams}`;
                                                    } );
                                                    
                                                }  else {
                                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                    this.$http.get(api).then(res =>{
                                                        window.location.href=`${url[0]}&aid=none`;
                                                    } );
                                                }
                                            } else {
                                                if(url[1].indexOf('&') > -1) {
                                                    let otherParams = url[1].substr(currentUrl.indexOf('&'));
                                                    window.location.href=`${url[0]}&aid=none${otherParams}`;
                                                } else {
                                                    window.location.href=`${url[0]}&aid=none`;
                                                }
                                            }
                                        }                           
                                       
                                    } else {
                                        //case 3 --> other params
                                        if(fromApp && ibJS.application) {
                                            let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                            this.$http.get(api).then(res =>{
                                                window.location.href=`${window.location.href}&affiliateid=none`;
                                            } );
                                        } else {
                                            window.location.href=`${window.location.href}&affiliateid=none`;
                                        }
                                    }
                                }
                                //No param 
                                else {
                                    if(fromApp && ibJS.application) {
                                        let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                        this.$http.get(api).then(res =>{
                                            window.location.href=`${window.location.href}?affiliateid=none`;
                                        } );
                                    } else {
                                        window.location.href=`${currentUrl}?affiliateid=none`;
                                    }
                                }
                                                              
                            } else {
                                this.closeModal();
                                if(fromApp && ibJS.application) {
                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                    this.$http.get(api).then(res =>{
                                        if(window.location.href.includes('?')) {
                                            let url = window.location.href.split('?');
                                            window.location.href=`${window.location.href}?${url[1]}`;
                                        } else {
                                            window.location.href=currentUrl;
                                        }
                                    } );
                                } else {
                                    if(window.location.href.includes('?')) {
                                        let url = window.location.href.split('?');
                                        window.location.href=`${window.location.href}?${url[1]}`;
                                    } else {
                                        window.location.href=currentUrl;
                                    }
                                }
                            }
                  
                        }).catch(() => console.log('Logout Failed.'));
                    } else {
                        if(this.affiliateid && this.affiliateid !== 'none') {
                            this.closeModal();
                            if(currentUrl.includes('.brokersnexus.com')) {
                                let domain = `${this.affiliateid}.brokersnexus.com`;
                                url = currentUrl.split(domain);
                                url = `${url[0]}insubuy.com${url[1]}`;

                                if(fromApp && ibJS.application) {
                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                    this.$http.get(api).then(res =>{
                                        let url = window.location.href.split(domain);
                                        url = `${url[0]}insubuy.com${url[1]}`;
                                        window.location.href=url;

                                    } );
                                } else {
                                    window.location.href=url;
                                }
    
                                //or 
                                // url[0] = `https://insubuy.com`;
                                // url = url.join();
                            } else if(currentUrl.includes('.brokersnexustest.com')) {
                                let domain = `${this.affiliateid}.brokersnexustest.com`;
                                url = currentUrl.split(domain);
                                url = `${url[0]}insubuytest.com${url[1]}`;

                                if(fromApp && ibJS.application) {
                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`

                                    this.$http.get(api).then(res =>{
                                        let url = window.location.href.split(domain);
                                        url = `${url[0]}insubuytest.com${url[1]}`;

                                        window.location.href=url;
                                    } );
                                } else {
                                    window.location.href=url;
                                }
                            } 
                            //Some Param
                            else if(currentUrl.indexOf('?') > -1) {
                                let param = getParameterByName('affiliateid', window.location.href);
                                let aidParam = getParameterByName('aid', window.location.href);

                                console.log("aid ", aidParam);

                                if(param && param !== 'none') {
                                    //case 1/2 --affiliateid param

                                    if(currentUrl.includes('?affiliateid=')) {
                                        url = currentUrl.split('?affiliateid');

                                        if(fromApp && ibJS.application) {
                                        
                                            if(window.location.href.indexOf('&') > -1) {
                                                let otherParams = currentUrl.substr(currentUrl.indexOf('&'));

                                                let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                this.$http.get(api).then(res =>{
                                                    window.location.href=`${url[0]}?affiliateid=none${otherParams}`;
                                                } );
                                                
                                            } else {
                                                let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                this.$http.get(api).then(res =>{
                                                    window.location.href=`${url[0]}?affiliateid=none`;
                                                } );
                                            }
                                        } else {
                                            if(window.location.href.indexOf('&') > -1) {
                                                let otherParams = currentUrl.substr(currentUrl.indexOf('&'));
                                                window.location.href=`${url[0]}?affiliateid=none${otherParams}`;
                                            } else {
                                                window.location.href=`${url[0]}?affiliateid=none`;
                                            }
                                        }
                                    } else if(currentUrl.includes('&affiliateid=')) {
                                        url = currentUrl.split('&affiliateid');

                                        if(fromApp && ibJS.application) {
                                        
                                            if(url[1].indexOf('&') > -1) {
                                                let otherParams = url[1].substr(url[1].indexOf('&'));

                                                let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                this.$http.get(api).then(res =>{
                                                    window.location.href=`${url[0]}&affiliateid=none${otherParams}`;
                                                } );
                                                
                                            }  else {
                                                let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                this.$http.get(api).then(res =>{
                                                    window.location.href=`${url[0]}&affiliateid=none`;
                                                } );
                                            }
                                        } else {
                                            if(url[1].indexOf('&') > -1) {
                                                let otherParams = url[1].substr(currentUrl.indexOf('&'));
                                                window.location.href=`${url[0]}&affiliateid=none${otherParams}`;
                                            } else {
                                                window.location.href=`${url[0]}&affiliateid=none`;
                                            }
                                        }
                                    }                           
                                   
                                } else if(aidParam && aidParam !== 'none') {
                                    //case 1/2 --affiliateid param

                                    if(currentUrl.includes('?aid=')) {
                                        url = currentUrl.split('?aid');

                                        if(fromApp && ibJS.application) {
                                        
                                            if(window.location.href.indexOf('&') > -1) {
                                                let otherParams = currentUrl.substr(currentUrl.indexOf('&'));

                                                let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                this.$http.get(api).then(res =>{
                                                    window.location.href=`${url[0]}?aid=none${otherParams}`;
                                                } );
                                                
                                            } else {
                                                let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                this.$http.get(api).then(res =>{
                                                    window.location.href=`${url[0]}?aid=none`;
                                                } );
                                            }
                                        } else {
                                            if(window.location.href.indexOf('&') > -1) {
                                                let otherParams = currentUrl.substr(currentUrl.indexOf('&'));
                                                window.location.href=`${url[0]}?aid=none${otherParams}`;
                                            } else {
                                                window.location.href=`${url[0]}?aid=none`;
                                            }
                                        }
                                    } else if(currentUrl.includes('&aid=')) {
                                        url = currentUrl.split('&aid');

                                        if(fromApp && ibJS.application) {
                                        
                                            if(url[1].indexOf('&') > -1) {
                                                let otherParams = url[1].substr(url[1].indexOf('&'));

                                                let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                this.$http.get(api).then(res =>{
                                                    window.location.href=`${url[0]}&aid=none${otherParams}`;
                                                } );
                                                
                                            }  else {
                                                let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                                this.$http.get(api).then(res =>{
                                                    window.location.href=`${url[0]}&aid=none`;
                                                } );
                                            }
                                        } else {
                                            if(url[1].indexOf('&') > -1) {
                                                let otherParams = url[1].substr(currentUrl.indexOf('&'));
                                                window.location.href=`${url[0]}&aid=none${otherParams}`;
                                            } else {
                                                window.location.href=`${url[0]}&aid=none`;
                                            }
                                        }
                                    }                           
                                   
                                } else {
                                    //case 3 --> other params
                                    if(fromApp && ibJS.application) {
                                        console.log("no")
                                        let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                        this.$http.get(api).then(res =>{
                                            window.location.href=`${window.location.href}&affiliateid=none`;
                                        } );
                                    } else {
                                        console.log("yes")
                                        window.location.href=`${window.location.href}&affiliateid=none`;
                                    }
                                }
                            }
                            //No param 
                            else {
                                if(fromApp && ibJS.application) {
                                    let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                    this.$http.get(api).then(res =>{
                                        window.location.href=`${window.location.href}?affiliateid=none`;
                                    } );
                                } else {
                                    window.location.href=`${currentUrl}?affiliateid=none`;
                                }
                            }

                        } else {
                            this.closeModal();                           
                            if(fromApp && ibJS.application) {
                                let api = `https://${location.host}/${ibJS.productName}${window.qResultsLang.reset}`
                                this.$http.get(api).then(res =>{
                                    if(window.location.href.includes('?')) {
                                        let url = window.location.href.split('?');
                                        window.location.href=`${window.location.href}?${url[1]}`;
                                    } else {
                                        window.location.href=currentUrl;
                                    }
                                } );
                            } else {
                                if(window.location.href.includes('?')) {
                                    let url = window.location.href.split('?');
                                    window.location.href=`${window.location.href}?${url[1]}`;
                                } else {
                                    window.location.href=currentUrl;
                                }
                            }
                        }
                    }
                },
                setPolicyType(type) {
                    this.group = (type === 'group');
              
                    this.form = { ...form };
                    this.$v.$reset();
                    this.otp = '';
                    this.loginStatus = '';

                    if(this.group) {
                        this.fPlaceholder = this.qResultsLang.certNoPlaceholder;
                    }
                },
                reset: function() {
                    this.btnSpinning = false;
                    this.continueBtnSpinning = false;
                    this.form = { ...form };
                    this.$v.$reset();
                    this.otp = '';
                    this.loginStatus = '';
                    this.memberIdStep2 = false;
                    this.isFailed = false;
                    this.isLocked = false;
                    this.isNotAvailable = false;
                },
                resetForm() {
                    if (!this.isLoggingIn) {
                        this.reset();
                        this.group = false;
                        this.loginType = 'certNo';
                        this.scrollingToTop();
                    }    
                },
                scrollingToTop: function(e) {
                    $("#login-modal-body").animate({scrollTop: 0}, "fast");
                        $("#close_button").focus(); 
                },
                goToLogin: function(isLogin) {
                    if (isLogin === 'login') {
                        this.showCertDob = true;
                        this.btnSpinning = false;
                        this.$v.$reset();
                        this.continueBtnSpinning = false;
                        this.otp = '';
                        this.loginStatus = '';
                        this.memberIdStep2 = false;
                        this.isFailed = false;
                        this.isLocked = false;
                        this.isNotAvailable = false;
                        // this.resetForm();
                    }
                },
                continueToLoginWrap() {
                    if (this.loginType === 'certNo') {
                      this.continueToLogin();
                    } else {
                      if(!this.$v) return;
                      this.$v.$touch();
              
                      if (!this.$v.$invalid) {
                        this.memberIdStep2 = true;
                        this.$v.$reset();
                      }
                    }
                },
                
                continueToLogin: function() {
                    this.$v.$touch();
            
                    if (!this.$v.$invalid) {
                    this.startSpinner('continue');
                    this.isLoggingIn = true;
                    this.isLocked = false;
            
                    const failedLogin = failType => {
                        if (failType) {
                        this.loginStatus = failType;
                        }
                        this.continueBtnSpinning = false;
                        this.isLoggingIn = false;
                    }
            
                    const payload = {
                        certNo: this.form.certNo
                    };
            
                    this.$http.post('/api/myaccount/validate-certificate/', payload)
                        .then(res => res.json())
                        .then(response => {
                        switch (response.status) {
                            case 'error':
                                if (response.data && (response.data.allErrorMessages[0] === 'No records found' || response.data.allErrorMessages[0] === 'DECLINED, PAYMENT' || response.data.allErrorMessages[0] === 'Declined Payment Login' || response.data.allErrorMessages[0] === 'admin email not found')) {
                                    failedLogin('failed');
                                    this.isFailed = true;
                                    this.showCertDob = false;
                                } else if (response.data && response.data.allErrorMessages[0] === 'MyAccount is not available') {
                                    failedLogin('not-available');
                                    this.isNotAvailable = true;
                                    this.showCertDob = false;
                                } else if (response.data && !isNaN(response.data.allErrorMessages[0])) {
                                    failedLogin('locked');
                                    this.isLocked = true;
                                    this.showCertDob = false;
                                } else {
                                    failedLogin('failed');
                                    this.isFailed = true;
                                    this.showCertDob = false;
                                }
                                // else if (response.data && response.data.allErrorMessages[0]) {
                                // this.beError = response.data.allErrorMessages[0];
                                //     failedLogin();
                                //     this.isFailed = true;
                                //     this.showCertDob = false;
                                // }
                            break;
                            case 'success':
                            this.$v.$reset();
                            this.continueBtnSpinning = false;
                            this.isLoggingIn = false;
            
                            if (response.data && response.data.certificateType == 'normal') {
                                this.group = false;
                                this.landing = false;
                            } else if (response.data && response.data.certificateType == 'group') {
                                this.group = true;
                                this.landing = false;
                            }
                            break;
                        }
            
                        })
                        .catch(res => {
                            let errors = {};
                            if (res && res.data) {
                                errors.serverErrors = res.data.allErrorMessages ? res.data.allErrorMessages[0] : res.body.data;
                                this.$emit('handleerrors', errors);
                            }
                            failedLogin('not-available');
                        });
                    }
                },

                continueToGroupLogin: function() {
                    this.$v.$touch();
              
                    if (!this.$v.$invalid) {
                      this.startSpinner('continueGroup');
                      this.isLoggingIn = true;
              
                      const failedLogin = failType => {
                        if (failType) {
                          this.loginStatus = failType;
                        }
                        this.continueBtnSpinning = false;
                        this.isLoggingIn = false;
                      }

                      if (this.loginType === 'email') {
                        const payload = {
                          email: this.form.email,
                          dob: convertDateToEN(this.form.dob),
                        };
                
                        this.$http.post('/api/myaccount/email-login/', payload)
                          .then(res => res.json())
                          .then(response => {
                            switch (response.status) {
                              case 'error':
                                if (response.data && response.data.allErrorMessages[0] === 'MyAccount is not available') {
                                    failedLogin('not-available');
                                    this.isNotAvailable = true;
                                    this.showCertDob = false;
                                } else if (response.data && response.data.allErrorMessages[0] === 'group') {
                                    // Switch to gruop tab
                                    this.setPolicyType('group');
                                    this.btnSpinning = false;
                                    this.continueBtnSpinning = false;
                                    this.isLoggingIn = false;
                                    this.$nextTick(()=> { 
                                      this.form.email = payload.email;
                                      this.$refs.textCertNumb.focus();
                                    })
                                } else {
                                    failedLogin('failed');
                                    this.isFailed = true;
                                    this.showCertDob = false;
                                }
                                break;
                              case 'success':
                                this.$v.$reset();
                                this.continueBtnSpinning = false;
                                this.loginStatus = 'email-login';
                                this.isLoggingIn = false;
                                break;
                            }
                          });
                      } else {
                        const payload = {
                            certNo: this.form.certNo,
                            email: this.form.email
                          };
                  
                          this.$http.post('/api/myaccount/group-login/', payload)
                            .then(res => res.json())
                            .then(response => {
                              switch (response.status) {
                                case 'error':
                                  if (response.data && response.data.allErrorMessages[0] === 'No records found' || response.data.allErrorMessages[0] === 'DECLINED, PAYMENT' || response.data.allErrorMessages[0] === 'Declined Payment Login' || response.data.allErrorMessages[0] === 'invalid credientials') {
                                    failedLogin('failed');
                                    this.isFailed = true;
                                    this.showCertDob = false;
                                  } else if (response.data && response.data.allErrorMessages[0] === 'MyAccount is not available') {
                                    failedLogin('not-available');
                                    this.isNotAvailable = true;
                                    this.showCertDob = false;
                                  } else if (response.data && response.data.allErrorMessages[0] === 'normal') {
                                    // Switch to individual tab
                                    this.setPolicyType('login');
                                    this.continueBtnSpinning = false;
                                    this.isLoggingIn = false;
                                    this.$nextTick(()=> {
                                        this.form.certNo = payload.certNo;
                                        this.$refs.dobPicker.$refs.dob.focus();
                                    })
                                  }else {
                                    failedLogin('failed');
                                    this.isFailed = true;
                                    this.showCertDob = false;
                                  }
                                //   else if (response.data && response.data.allErrorMessages[0]) {
                                //     this.beError = response.data.allErrorMessages[0];
                                //     failedLogin();
                                //     this.isFailed = true;
                                //     this.showCertDob = false;
                                //   }
                                  break;
                                case 'success':
                                  this.$v.$reset();
                                  this.continueBtnSpinning = false;
                                  this.loginStatus = 'group-login';
                                  this.isLoggingIn = false;
                                  break;
                              }
                  
                            })
                            .catch(res => {
                              this.isLoggingIn = false;
                              let errors = {};
                              if (res && res.data) {
                                  errors.serverErrors = res.data.allErrorMessages ? res.data.allErrorMessages[0] : res.body.data;
                                  this.$emit('handleerrors', errors);
                              }
                            });
                      }
                    }
                },

                groupLogin: function() {
                this.$v.$touch();
            
                if (!this.$v.$invalid) {
                    this.startSpinner('login');
                    this.isLoggingIn = true;

                    const failedLogin = failType => {
                        if (failType) {
                          this.loginStatus = failType;
                          this.otp = '';
                        }
                        this.btnSpinning = false;
                        this.isLoggingIn = false;
                      }
            
                    const payload = {
                        certNo: this.form.certNo,
                        otp: this.otp
                    };

                    if (this.loginType === 'email') {
                        payload.email = this.form.email;
                    }

                    this.$http.post('/api/myaccount/verify-email/', payload)
                    .then(res => res.json())
                    .then(response => {
                        switch (response.status) {
                        case 'error':
                            if (response.data && (response.data.allErrorMessages[0] === 'No records found' || response.data.allErrorMessages[0] === 'DECLINED, PAYMENT' || response.data.allErrorMessages[0] === 'Declined Payment Login' || response.data.allErrorMessages[0] === 'invalid credientials')) {
                                failedLogin('failed');
                                this.isFailed = true;
                                this.showCertDob = false;
                            } else if (response.data && response.data.allErrorMessages[0] === 'MyAccount is not available') {
                                failedLogin('not-available');
                                this.isNotAvailable = true;
                                this.showCertDob = false;
                            } else if (response.data && response.data.allErrorMessages[0]) {
                            this.beError = response.data.allErrorMessages[0];
                                failedLogin();
                                this.isFailed = true;
                                this.showCertDob = false;
                            }
                            break;
                        case 'success':
                            if (ibJS.currentStep == '1') {
                                location.href = location.href;
                            } else {
                                this.saveData();
                            }
                            this.isLoggingIn = false;
                            break;
                        }
                    })
                    .catch(res => {
                        let errors = {};
                        if (res && res.data) {
                            errors.serverErrors = res.data.allErrorMessages ? res.data.allErrorMessages[0] : res.body.data;
                            this.$emit('handleerrors', errors);
                        }
                        this.isNotAvailable = true;
                        this.showCertDob = false;
                        this.isLoggingIn = false;
                        this.isLocked = false;
                    });
                }
                },
              
                login: function() {
                    this.$v.$touch();
                    const { certNo, dob } = this.form;

                    if(!this.$v.$invalid) {
                        this.startSpinner('login');
                        this.isLoggingIn = true;
                        this.isLocked = false;
                        const certReverse = this.form.certNo.split('').reverse();

                        const payload = {...this.form, dob: convertDateToEN(this.form.dob)};
                        this.$http.post('/clients/login/?cntApp='+certReverse+'&lang='+window.globalLanguage.lang, JSON.stringify(payload))
                        .then(res => res.json())
                        .then(response => {
                            switch(response.status) {
                                case 'error':
                                    if(response.data.allErrorMessages[0] === 'No records found') {
                                        this.isFailed = true;
                                        this.showCertDob = false;
                                    } else if(response.data.allErrorMessages[0] === 'MyAccount is not available') {
                                        this.isNotAvailable = true;
                                        this.showCertDob = false;
                                    } else if (response.data.allErrorMessages[0] === 'group') {
                                        // Switch to gruop tab
                                        this.setPolicyType('group');
                                        this.btnSpinning = false;
                                        this.isLoggingIn = false;
                                        this.$nextTick(()=> { 
                                          this.form.certNo = payload.certNo;
                                          this.$refs.email.focus();
                                        })
                                    } else if (response.data && !isNaN(response.data.allErrorMessages[0])) {
                                        this.isLocked = true;
                                        const mins = +response.data.allErrorMessages[0];
                                        this.pendingPeriod = {
                                            hr: Math.floor(mins / 60),
                                            min: mins % 60
                                        }
                                        this.showCertDob = false;
                                    } else if(response.data.allErrorMessages[0]) {
                                        this.isFailed = true;
                                        this.showCertDob = false;
                                        this.beError = response.data.allErrorMessages[0];
                                    }
                                    break;
                                case 'success':
                                    // this.saveData();
                                    if (ibJS.currentStep == '1') {
                                        location.href = location.href;
                                    }else {
                                        let preSaveData = this.$parent.getPreSaveData();
                                        saveApplication(this.$http, preSaveData, function() {
                                            location.href = `https://${location.host}/retrieve-application/?id=${preSaveData.id}${preSaveData.pin}&lang=${window.globalLanguage.lang}`;
                                        });
                                    }
                                    break;
                            }
                            this.isLoggingIn  = false;
                        })
                        .catch(res => {
                            const errors = {};
                            if (res && res.data) {
                                errors.serverErrors = res.data.allErrorMessages ? res.data.allErrorMessages[0] : res.body.data;
                            }
                            this.isNotAvailable = true;
                            this.showCertDob = false;
                            this.isLoggingIn = false;
                            this.isLocked = true;
                        });
                    }
                },
                saveData: function() {
                    let preSaveData = this.$parent.getPreSaveData();
                    saveApplication(this.$http, preSaveData, function() {
                        location.href = `https://${location.host}/retrieve-application/?id=${preSaveData.id}${preSaveData.pin}&lang=${window.globalLanguage.lang}`;
                    });
                },
                datePickerSetup: function() {
                    var datepickerLang = 'en';
                    var format = "mm/dd/yyyy";

                    switch (window.globalLanguage.lang) {
                        case 'es':
                            datepickerLang = 'es';
                            format = "dd/mm/yyyy";
                            break;
                        case 'zh':
                            datepickerLang = 'zh-CN';
                            format = "yyyy-mm-dd";
                            break;
                        default:
                            datepickerLang = 'en';
                    }

                    let self = this;
                    setTimeout(()=>{
                        $("#datepicker .o-Icon-menu__date").datepicker({
                            autoclose: true,
                            language: datepickerLang,
                            todayHighlight: false,                        
                            startView: 3,
                            format: format
                        }).on('changeDate', function (ev) {
                            if(ev) {
                                self.form.dob = ev.format();         
                            }
                        });
                    }, 0);
                },
                closeModal: function() {
                    $('body').removeClass('is-Locked');
                    this.$parent.showLoginModalComponent = false;
                },
                closeAgentModal: function() {
                    let fromApp = sessionStorage.getItem("fromApp");

                    if(fromApp && fromApp == 'true') {
                        sessionStorage.setItem("appModalVisited", true);
                    }

                    $('body').removeClass('is-Locked');
                    this.$parent.showLoginModalComponent = false;
                },
                showOtherModal(url) {
                    if(typeof(this.$parent.showModal) === 'function') {
                        this.$parent.showModal(url, 'Yes');
                    } else if(typeof(this.showModal) === 'function') {
                        this.showModal(url, 'Yes');
                    }
                },
                addEventListeners() {
                    window.addEventListener('keydown', (event) => {
                      if (event.which == 13 || event.keyCode == 13) {
                        if (this.landing) {
                            this.continueToLogin();
                        } else if (!this.landing && !this.group && this.loginType !== 'email') {
                            this.login();
                        } else if (!this.landing && this.group && this.loginStatus !== 'group-login') {
                            this.continueToGroupLogin();
                        } else if (!this.landing && this.group && this.loginStatus === 'group-login') {
                            this.groupLogin();
                        } else if (this.loginType === 'email' && this.loginStatus !== 'email-login') {
                            this.continueToGroupLogin();
                        } else if (this.loginType === 'email' && this.loginStatus === 'email-login') {
                            this.groupLogin();
                        }
                      }
                    });
                },
                startSpinner(type) {
                    if (type == 'login') {
                        this.btnSpinning = true;
                        const spinTarget = this.$refs.loginBtn;
                        setTimeout(() => {
                            new IBSpinner(ibSpinOpts).spin(spinTarget);
                        }, 0);
                      } else if (type == 'group') {
                        this.btnSpinning = true;
                        const spinTarget = this.$refs.loginGroup;
                        setTimeout(() => {
                            new IBSpinner(ibSpinOpts).spin(spinTarget);
                        }, 0);
                      } else if (type == 'continue') {
                        this.continueBtnSpinning = true;
                        const spinTarget = this.$refs.continueLogin;
                        setTimeout(() => {
                            new IBSpinner(ibSpinOpts).spin(spinTarget);
                        }, 0);
                      } else {
                        this.continueBtnSpinning = true;
                        const spinTarget = this.$refs.continueGroupLogin;
                        setTimeout(() => {
                            new IBSpinner(ibSpinOpts).spin(spinTarget);
                        }, 0);
                      }
                },
            }
        }
    } else {
        return {
            template: '<div></div>'
        }
    }
}

const form = Object.freeze({
    certNo: '',
    dob: '',
    email: '',
    effectiveDate: '',
  });

let LoginModalMixin = {
    data: function () {
        return {            
            renderHtml: '',
            showLoginModalComponent: false,
            isFailed: false,
            isNotAvailable: false,
            showCertDob: true,
            isInternalIP: window.globalLanguage.internalIP,
        }
    },
    computed: {
        loginModalLoadMouseoutComponent: loginModalLoadMouseoutComponent
    },
    methods: {
        requestLoginModal() {
            this.$http.get('/guide/terms?section=loginmodal&lang=' + window.globalLanguage.lang).then((responseBody) => {
                this.renderHtml = responseBody.body;
                this.showLoginModalComponent = true;
                $('body').addClass('is-Locked');
            })
        },
    }
}

export {
    LoginModalMixin,
    loginModalLoadMouseoutComponent
}