<template>
    <li class="o-List-bare c-Plan__select-item">
        <div :class="'c-Plan__select-h c-Plan__select-h-sub c-Plan__select-h-link c-Plan__content-heading c-Plan__content-icon ' + iconClass + ' ' + itemNameClass" 
        @click.prevent="showModal(modalName)">
            <span>
                <template v-if="itemName">{{ itemName }}</template>
                <span v-if="itemExplanation" class="c-Plan__select-h--second c-Plan__line-break c-Plan__select-explaination">
                    ({{itemExplanation}})
                </span>
                <slot name="item-name" />
                <span class="o-Information"></span>
            </span>
        </div>

        <div v-if="itemValue"  :class="'c-Plan__select-type' +  (!valueExplanation ? ' c-Plan__select-type--text' : '')">
            <span v-if="valueExplanation" class="c-Plan__select-type-h c-Plan__select-explaination" @click.prevent="showModal(modalName)">
                <span>
                    ({{valueExplanation}})
                </span>
            </span>
            <span v-html="itemValue"></span>
        </div>
        <slot name="item-value" />
    </li>
</template>

<script>
export default {
    props: ['icon-class', 'item-name-class', 'modal-name', 'item-name', 'item-value', 'item-explanation', 'value-explanation'],
    methods: {
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
    }
}
</script>