<template>
  <div class="page-wrap">
    <div class="inner-page">
      <div class="heading-flex-wrap">
        <div>
          <h2 class="title-receipt" style="color: #333 !important;">{{this.terms.paymentReceipt}}</h2><br/>
          <h3 v-if="purchaseDate !== 'Invalid Date'" style="color: #333 !important; font-weight: 500">{{this.terms.date}}: {{purchaseDate | translate}}</h3>
          <h3 v-if="this.isExchangeGuardGrpPlans && this.application.groupCertNumber != null" style="color: #333 !important; font-weight: 500">{{qResultsLang.egGroup.grpCertNo}}: {{application.groupCertNumber}}</h3>
          <h3 v-if="companyCode === 'TravelInsured' || companyCode === 'Travelex' || companyCode === 'IMG' || companyCode === 'iTI' || companyCode === 'Trawick' || imgTripPlan || companyCode === 'Careington'" style="color: #333 !important; font-weight: 500">{{this.terms.certificateNo}}: {{this.application.travelerInfos[0].certNumber ? this.application.travelerInfos[0].certNumber : this.application.policyNumber ? this.application.policyNumber : ''}}</h3>
          <h3 v-if="companyCode === 'GU'" style="color: #333 !important; font-weight: 500">{{this.terms.certificateNo}}: {{this.application.policyNumber ? this.application.policyNumber : ''}}</h3>
        </div>
        <div class="text-right">
          <img :src="this.imgUrl"/>
          <template v-if="companyCode === 'Trawick'">
            <div class="address-wrap" v-if="isNationWidePlans || isVoyagerPlans || isTrawickOutboundPlans || isExplorerPlans">
              <p v-html="this.address.trawickNW1"></p>
              <p>{{this.address.trawickNW2}}</p>
              <p>{{this.address.trawickNW3}}</p>
            </div>
            <div class="address-wrap" v-else-if="isCrumForsterPlans">
              <p v-html="this.address.crumForster1"></p>
              <p>{{this.address.crumForster2}}</p>
              <p>{{this.address.crumForster3}}</p>
            </div>
            <div class="address-wrap" v-else>
              <p v-html="this.address.trawickGBG1"></p>
              <p>{{this.address.trawickGBG2}}</p>
              <p>{{this.address.trawickGBG3}}</p>
            </div>
          </template>
        </div>
      </div>
      <div class="insureds-wrap" style="width: 100%">
        <h3 style="color: #333 !important;">
          {{ this.terms.allPersons }}
        </h3>
        <p v-if="this.isExchangeGuardGrpPlans" v-cloak>{{this.application.groupName}}</p>
        <div class="table" style="width: 100%">
          <div class="thead" style="width: 100%">
            <th class="table-class table-class--heading table-class--65" v-if="showCertificateNumber">
              {{this.terms.certificateNo}}
            </th>
            <th class="table-class table-class--heading table-class--50"  v-else-if="showInsuredId" >
              {{this.terms.insuredId}}
            </th>
            <th class="table-class table-class--heading table-class--50" v-else-if="showMemberNumber" >
              {{this.terms.memberNumber}}
            </th>
            <th class="table-class table-class--heading table-class--50" :class="{'table-class--100': !showCertificateNumber && !showInsuredId && !showMemberNumber && !showPersonPremium}" >
              {{this.terms.insuredName}}
            </th>
             <th class="table-class table-class--heading table-class--50" v-if="showPersonPremium" >
              {{this.terms.premium}}
            </th>
          </div>
          <div class="tbody" style="width:100%">
            <div :key="i" v-for="(x,i) in this.application.travelerInfos" class="table-body">
              <div class="table-class table-class--65"  v-if="showCertificateNumber && !imgTripPlan" >
                <div>{{x.certNumber}}</div>
              </div>
              <div class="table-class table-class--50"  v-else-if="showInsuredId || showMemberNumber || (showCertificateNumber && imgTripPlan)" >
                <div>{{x.memberId || '-'}}</div>
              </div>
              <div class="table-class table-class--50"  :class="{'table-class--100': !showCertificateNumber && !showInsuredId && !showMemberNumber && !showPersonPremium}">
                <div style="width: 100%">{{x.firstName+' '+x.lastName}}</div>
              </div>
              <div class="table-class table-class--50" v-if="showPersonPremium">
                <div style="width: 100%">{{x.premium | currency}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="payment-details-wrap" style="padding-bottom: 1em;">
        <h3 style="color: #333 !important;">
          {{this.terms.paymentDetails}}
        </h3>
        <!-- STUDENT PLANS PREMIUM -->
        <div class="flex-details"  v-if="isSHAorSS">
          <p>
            {{this.terms.premium}}:
          </p>
          <p v-if="isSSPaymentType2 && isExtension">
            {{this.application.currentPremium | currency}}
          </p>
          <p v-else>
            {{this.calculateTotalPremium | currency}}
          </p>
        </div>
        <!-- HCC EXTENSION PREMIUM -->
        <div class="flex-details" v-else-if="hCCExtPremium">
          <p>
            {{this.terms.premium}}:
          </p>
          <p>
            {{this.hCCExtPremium | currency}}
          </p>
        </div>
        <div class="flex-details" v-else>
          <p>
            {{this.terms.amountPaid}}:
          </p>
          <p>
            {{this.application.totalPrice | currency}}
          </p>
        </div>
        <!-- TOTAL DUE TODAY -->
        <div class="flex-details"  v-if="isSHAorSS">
          <p>
            {{qResultsLang.shaTotal}}:
          </p>
          <p v-if="isShaPaymentType2 && isExtension">
            {{this.policy.premium + shippingCost | currency}}
          </p>
          <p v-else-if="isSSPaymentType2 && isExtension">
            {{this.policy.premium | currency}}
          </p>
          <p v-else>
            {{this.application.totalPrice | currency}}
          </p>
        </div>
        <div class="flex-details" v-if="isSHAorSS">
          <p>
            {{qResultsLang.paymentFrequency}}:
          </p>
          <p>
            {{qResultsLang.monthlyPaymentFrequency}}
          </p>
        </div>
        <div class="flex-details" v-if="companyCode === 'Careington'">
          <p>
            {{qResultsLang.paymentFrequency}}:
          </p>
          <p>
            {{application.careingtonBillingOpt === 1 ? qResultsLang.billingOptMonthly : qResultsLang.billingOptAnnual}}
          </p>
        </div>
        <div class="flex-details" v-if="this.application.ccFirstName">
          <p>
            {{this.terms.paidBy}}:
          </p>
          <p>
            {{this.application.ccFirstName}}
          </p>
        </div>
        <div class="flex-details" v-if="this.application.ccType">
          <p>
            {{this.terms.paymentType}}:
          </p>
          <p>
            {{this.application.ccType}}
          </p>
        </div>
        <div class="flex-details" v-if="this.application.ccNumber">
          <p>
            {{this.application.ccType === 'eCheck' ? this.terms.accountNumber : this.terms.cardNumber}}:
          </p>
          <p>
          {{this.application.ccNumber}}
          </p>
        </div>
        <div class="flex-details" v-if="application.ccExpMonth && application.ccExpYear">
          <p>
            {{terms.expirationDate}}:
          </p>
          <p>
            {{expirationDateCard}}
          </p>
        </div>
        <div class="flex-details" v-if="this.application.transactionId">
          <p class="italics">
            {{this.terms.transactionCode}}:
          </p>
          <p>
            {{this.application.transactionId}}
          </p>
        </div>
        <div class="flex-details" v-if="this.application.authCode">
          <p class="italics">
            {{this.terms.authorizationCode}}:
          </p>
          <p>
            {{this.application.authCode}}
          </p>
        </div>
        <p v-if="isShaPaymentType2 && !isExtension">
           <span>*<span v-if="plan.calcMonths == 1">{{qResultsLang.futureInstallmentsSingular}}</span><span v-else>{{qResultsLang.futureInstallments}}</span> {{totalPrice | currency}} <span v-if="plan.calcMonths == 1">{{qResultsLang.willBeBilledSingular}}</span><span v-else>{{qResultsLang.willBeBilled}}</span> {{remainingMonths.toLowerCase()}}{{qResultsLang.period}}</span>
        </p>
        <p v-else-if="isShaPaymentType2 && isExtension">
            <span>*<span v-if="plan.calcMonths == 1">{{qResultsLang.futureInstallmentsSingular}}</span><span v-else>{{qResultsLang.futureInstallments}}</span> {{totalPrice | currency}}  <span v-if="plan.calcMonths == 1">{{qResultsLang.willBeBilledSingular}}</span><span v-else>{{qResultsLang.willBeBilled}}</span> {{remainingMonthsExtension.toLowerCase()}} {{qResultsLang.followingCurrentExp}}</span>
        </p>
        <p v-else-if="isSSPaymentType2">
          *<span v-if="(this.plan.scMonths - 1) === 1">{{qResultsLang.futureInstallmentsSingular}} </span>
            <span v-else>{{qResultsLang.futureInstallments}} </span> 
            {{ totalPrice | currency}} 
            <span v-if="!isExtension || application.futureStatementType === 'Type1'"> {{qResultsLang.willBeBilledSingular}} {{remainingMonthsSC.toLowerCase()}}.</span>
            <span v-else>{{qResultsLang.willBeBilled}} {{remainingMonthsSC.toLowerCase()}} {{qResultsLang.followingCurrentExp}}</span>
        </p>
        <p v-else-if="isHCCExtMonthly">  
          <span>* Future installments of {{isHCCExtMonthly | currency}} USD will be billed for the {{remainingMonthsHCCExt}} following the current expiration date.</span>
        </p>
      </div>
      <div class="payment-details-wrap" style="border-top: 1px solid #dadada;">
        <p v-if="isSHAorSS" class="purchase-disclaimer">
          <span v-if="companyCode === 'Careington'">
            {{qResultsLang.purchaseNoteInstallmentsCNT}}
          </span>
          <span v-else>
            {{qResultsLang.purchaseNoteInstallments}}
          </span>
        </p>
        <p v-else class="purchase-disclaimer">
          <span v-if="companyCode === 'Careington'">
            {{qResultsLang.purchaseNoteOneTimeCNT}}
          </span>
          <span v-else>
            {{qResultsLang.purchaseNoteOneTime}}
          </span>  
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { trawickConfig, hccConfig } from '../../../helper-functions/app-configs';

export default {
  props: ['address','application','img-url', 'terms', 'shipping-cost', 'calculate-total-premium', 
    'img-trip-plan-prop', 'is-extension-prop', 'extension', 'policy', 'new-bill-mode'],
  data: function(){
    return {
      certNumber: this.application?.policyNumber || false,
      companyCode: this.application?.companyCode || '',
      qResultsLang: qResultsLang,
      plan: this.application.planResult,
      isTripProtectPlans: trawickConfig.isTripProtectPlans(),
      isNationWidePlans: trawickConfig.isNationWidePlans(),
      isTrawickOutboundPlans: trawickConfig.isOutboundPlans(),
      isVoyagerPlans: trawickConfig.isVoyagerPlans(),
      isCrumForsterPlans: trawickConfig.isCrumForster(),
      isExplorerPlans: trawickConfig.isExplorerPlans(),
      imgMedical: false,
      planId: this.application?.planId,
      imgTripPlan: false,
      hasMemberId : false,
      isExchangeGuardGrpPlans: hccConfig.isExchangeGuardGrp()
    }
  },
  created() {
    this.isExtension = this.isExtensionProp == true || this.isExtensionProp == 'true';
    this.imgTripPlan = this.imgTripPlanProp == true || this.imgTripPlanProp == 'true';

    this.imgMedical = this.application?.companyCode === 'IMG' && !this.imgTripPlan ? true : false;
  },
  computed: {
    purchaseDate: function() {
      var serverTime = this.application?.lastUpdatedOn;

      if (this.isExtension) {
        if (this.application.policyDTO) {
          serverTime = this.application.policyDTO.createdOn;
        } else {
          serverTime = this.extension.createdOn;
        }
      }

      if (!serverTime) {
        serverTime = new Date();
      }

      return dayjs(serverTime).format('MM[/]DD[/]YYYY');
    },
    expirationDateCard: function() {
      return this.application.ccExpMonth.length === 1 ? '0' + this.application.ccExpMonth + '/' + this.application.ccExpYear
        : this.application.ccExpMonth + '/' + this.application.ccExpYear;
    },
    remainingMonths: function(){
      var plan = this.application.planResult;
      var months = 0;
      if(this.shouldRoundMonth()) months = plan.calcMonths;
      else months = plan.calcMonths - 1;

      var arr = months === 1 ? window.qResultsLang.monthSM : window.qResultsLang.monthsSM;

      if(months === 1 && window.globalLanguage.lang === 'es') {
        return " "+window.qResultsLang.monthSM;
      }

      return `${months} ${arr}`;
    },
    /* 
    *  on extension there is no todays payment for monthly plan which alters the number of months of future payments 
    */
    remainingMonthsExtension() {
      var plan = this.application.planResult;
      var months = 0;
      if(this.shouldRoundMonth()) months = plan.calcMonths + 1;
      else months = plan.calcMonths;

      var arr = months === 1 ? window.qResultsLang.monthSM : window.qResultsLang.monthsSM;

      if(months === 1 && window.globalLanguage.lang === 'es') {
        return " "+window.qResultsLang.monthSM;
      }

      return `${months} ${arr}`;
    },
    isShaPaymentType2: function() {
      return this.application.appCompanyImg && this.application.appCompanyImg.shaPaymentType === 2;
    },
    isSSPaymentType2: function() {
      return this.application.appCompanyHccmis && this.application.appCompanyHccmis.scPaymentType === 2;
    },
    totalPrice: function() {
      return this.shippingCost ? this.application.totalPrice - this.shippingCost : this.application.totalPrice;
    },
    isSHAorSS: function () {
      return this.isShaPaymentType2 || this.isSSPaymentType2;
    },
    showPersonPremium: function(){
      if(this.application && this.application.travelerInfos){
        if(this.application.companyCode == 'GU' || this.application.companyCode == 'Careington'){
          return false;
        }
        for(let i = 0; i < this.application.travelerInfos.length; i++){
          let travelerInfo = this.application.travelerInfos[i];
          if(travelerInfo && travelerInfo.premium > 0){
            return true;
          }
        }
      }
      return false;
    },
    remainingMonthsSC: function(){
      var months = +(this.coverageDates.split(' ')[0]);

      if (this.application.billForMonth) {
        return this.application.billForMonth + " " + (this.application.billForMonth === 1 ? window.qResultsLang.monthSM : window.qResultsLang.monthsSM);
      }
      
      if (this.newBillMode) {
        return parseInt(months - 1) + " " + ((months - 1) === 1 ? window.qResultsLang.monthSM : window.qResultsLang.monthsSM);
      } else {
        return parseInt(months) + " " + (months === 1 ? window.qResultsLang.monthSM : window.qResultsLang.monthsSM);
      }
    },
    coverageDates: function() {
      //If Monthly Payments
      if(this.shouldRoundMonth()) {
        var months = this.plan.calcMonths > 0 ? this.plan.calcMonths + 1 : this.plan.scMonths;

        if (!this.isExtension) {
          months -= 1;
        }

        var arr = this.plan.calcMonths === 0 ? window.qResultsLang.months : this.plan.quotedDuration.split(',')[0].split(' ');
        if(Array.isArray(arr)){
          arr[0] = months;
          return arr.join(' ');
        }
        else return months + " " + arr;
      }
      else if(this.isStudentSecure && +this.plan.scMonths === 1) return 1 + ' ' + window.qResultsLang.month;
      //One Time Payments
      return this.plan.quotedDuration;
    },
    remainingMonthsHCCExt: function() {
      const { monthSM, monthsSM } = this.qResultsLang;
      let text = this.application.planResult.quotedDuration;

      if(this.extension.billMode === 'M') {
         text = this.application.planResult.scMonths;
         //text--;
         let monthOrMonths = text === 1 ? monthSM : monthsSM;

         return text + ' '+monthOrMonths;
      }

      return text;
    },
    isHCCExtMonthly: function() {
      if(this.extension && this.extension.newBillMode === 'M' /*&& this.application.planResult.scMonths > 1*/) {
        return this.extension.totalModalCharge;
      }
      return null;
    },
    hCCExtPremium: function() {
      if(this.policy && this.application.companyCode !== 'IMG') {
        return this.policy.premium;
      }
      return null;
    },
    isBeforeSep2019: function() {
        let lastUpdate = new Date(this.application?.lastUpdatedOn).toISOString();
        let sep2019 = "2019-09-12T15:00:00.000Z"; // new Date('Sep 12 2019 10:00:00').toISOString();
        return sep2019 > lastUpdate;
    },
    showCertificateNumber() {
      return this.companyCode !== 'TravelInsured' && this.companyCode !== 'iTI' && this.companyCode !== 'IMG' && this.companyCode !== 'Travelex' && this.companyCode !== 'Trawick' && this.companyCode != 'GU'  && this.companyCode !== 'Careington';  
    },
    showInsuredId() {
      return this.companyCode === 'IMG';
    },
    showMemberNumber() {
      return this.companyCode === 'Trawick' || this.hasMemberId;
    }
  },
  mounted: function(){
    if(this.companyCode === 'Careington') {
      this.application.travelerInfos.forEach(item => {
          if(item.memberId !== null && item.memberId !== undefined) {
              this.hasMemberId = true;
          }
      });
    }
  },
  methods: {
    shouldRoundMonth: function(){
      if(this.application.appCompanyImg) return this.application.appCompanyImg.shaPaymentType === 2 && this.application.planResult.calcDays > 0;
      if(this.application.appCompanyHccmis)return this.application.appCompanyHccmis.scPaymentType === 2;
      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
  .page-wrap {
    color: #333 !important;
  }
  .table {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .thead {
    display: flex;
    align-items: stretch;
  }
  .table-body {
    display: flex;
  }
  .tbody {
    border-bottom: 1px solid #dadada;
  }
  .table-class {
    @extend .thead;
    border: 1px solid #dadada;
    padding: .5em;
    text-align: left;

    &--heading{
      background-color: #f4f4f4;
    }
    &--35{
      min-width: 35%;
      border-right: 0;
      border-bottom: 0;
    }
    &--40{
      width: 40%;
    }
    &--50{
      width: 50%;
    }
    &--60{
      width: 60%;
    }
    &--65{
      width: 65%;
      border-bottom: 0;
    }

    &--100{
      width: 100%;
    }
  }
  .full-border {
     border: 1px solid #dadada;
  }
  .heading-flex-wrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dadada;
    padding-bottom: 2em;
    p {
      margin: 0;
      line-height: 1;
    }
    p:first-child {
      margin-top: 1em;
    }
  }
  .text-right{
    text-align: right;
  }
  .address-wrap {
    margin-top: 1em;
  }
  .insureds-wrap {
    padding: 2em 0;
    border-bottom: 1px solid #dadada;
  }
  .payment-details-wrap {
    padding-top: 2em;
  }
  .italics {
    font-style: italic;
  }
  .title-receipt {
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .inner-page{
    // border: 1px solid #dadada;
    border-radius: 3px;
    padding: 2em;
    height: 100vh;
    padding-top: 2em;
  }
  .flex-details {
    display: flex;
    justify-content: flex-start;
    width: 250px;
    p{
      margin-bottom: 0;
      margin-top: .5em;
      white-space: nowrap;
    }
    p:first-child{
      min-width: 200px;
    }
  }
  .purchase-disclaimer {
    margin-top: 0;
    text-align: justify;
  }
  @media print {
    .table-class {
      &--heading{
        background-color: #f4f4f4 !important;
        -webkit-print-color-adjust: exact;
      }
    }
  }
</style>
