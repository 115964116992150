(function($) {
    "use strict";

    $.fn.ibRandomReviews = function(itemClass) {
        var $this = $(this);
        var $items = $this.find(itemClass);
        $items.addClass('js-Hide');
        
        var random = getRandom($items.length, []);
        for (let i = 0; i < random.length; i++) {
            $items.eq(random[i]).removeClass('js-Hide');
        }

        $this.removeClass('js-Init-hidden');

        return this;
    };

    function getRandom(lngt, uniqueList) {

        let random = Math.floor(Math.random() * lngt);
        if (uniqueList.indexOf(random) == -1) uniqueList.push(random);
        if (uniqueList.length < 3) {
            getRandom(lngt, uniqueList);
        }
        return uniqueList;
    }

})(jQuery);
