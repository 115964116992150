//Applies to step 5 on all applications.
import Vue from 'vue';
import VueResource from 'vue-resource';
import Vuelidate from 'vuelidate';

Vue.use(VueResource);
Vue.use(Vuelidate);

import { ibSpinOpts } from '../../helper-functions/applicationCommonValues';

import { required, email } from 'vuelidate/lib/validators';

import { validatePhoneNumberApp } from '../../helper-functions/helper-functions';

import PhoneCode from './component.phone-code.vue'; 

Vue.component('pci', {
    components: {
        'phone-code': PhoneCode
    },
    name: 'pci',
    props: {
        section: {
            type: String,
            default: 'application'
        },
        showDefault: {
            type: Boolean,
            default: true
        },
        application: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            polling: null,
            sendPaymentEmail: false,
            sendPaymentPhone: false,
            sendPaymentWhatsapp: false,
            sendPaymentError: false,
            sendingEmailLink: false,
            reSendingEmailLink: false,
            sendingPhoneLink: false,
            reSendingPhoneLink: false,
            sendingWhatsappLink: false,
            reSendingWhatsappLink: false,
            sendPaymentLinkSubmitted: false,
            paymentSuccess: this.section == 'application' ? (ibJS?.application?.pciPaymentFlow ? false : true) : (ibJS?.extension?.pciPaymentFlow ? false : true),
            paymentError: false,
            processIntCalled: false,
            type: {
                email: this.section == 'application' ? (ibJS?.application?.primaryEmail) : (ibJS.extension?.primaryEmail ? ibJS.extension.primaryEmail : ibJS.policyDTO.email1),
                text: this.section == 'application' ? (ibJS?.application?.phoneNumber) : (ibJS.extension?.phoneNumber ? ibJS.extension.phoneNumber : ibJS.policyDTO.telephone1),
                whatsapp: this.section == 'application' ? (ibJS?.application?.phoneNumber) : (ibJS.extension?.phoneNumber ? ibJS.extension.phoneNumber : ibJS.policyDTO.telephone1)
            },
            paymentLinkType: 'email',
            resetSubmitted: false,
            countriesPci: ibJS.countries
        }
    },                
    mounted () {
    },
    validations() {
        let validations = {
            type: {
                email: {
                },
                text: {
                },
                whatsapp: {
                }
            }
        };
        
        if(this.showDefault) {
            if(this.paymentLinkType == 'text') {
                validations.type.text.required = required;
                validations.type.text.validatePhoneNumberApp = validatePhoneNumberApp;
            } else if(this.paymentLinkType == 'whatsapp') {
                validations.type.whatsapp.required = required;
                validations.type.whatsapp.validatePhoneNumberApp = validatePhoneNumberApp;
            } else {
                validations.type.email.required = required;
                validations.type.email.email = email;
            }
        } else {
            delete validations.type.email.required;
            delete validations.type.text.required;
            delete validations.type.whatsapp.required;
        }

        return validations;
    },
    watch: {
        'type.email': function() {
            if(this.$v.type.$error) {
                this.sendPaymentEmail = false;
                this.sendingEmailLink = false;
                this.reSendingEmailLink = false;
            }
        },
        'type.text': function() {
            if(this.$v.type.$error) {
                this.sendPaymentPhone = false;
                this.sendingPhoneLink = false;
                this.reSendingPhoneLink = false;
            }
        },
        'type.whatsapp': function() {
            if(this.$v.type.$error) {
                this.sendPaymentWhatsapp = false;
                this.sendingWhatsappLink = false;
                this.reSendingWhatsappLink = false;
            }
        },
        paymentSuccess() {
            this.$emit('set-payment-success', this.paymentSuccess);
        },
        paymentError() {
            this.$emit('set-payment-error', this.paymentSuccess);
        }
    },
    created () {
        if(ibJS && ibJS.errors && ibJS.errors.length) {
            this.paymentError = true;
            this.paymentSuccess = false;
        } else {
            this.pollPayment();
        }
    },
    methods: {
        sendPaymentLinkEmail: function (type) {
            this.$v.type.$touch();

            let url = '';
            if(this.section == 'extension') {
                url = `/api/send-payment-link/?id=${ibJS.extension.id}&extn=1&type=${this.paymentLinkType}&value=${encodeURIComponent(this.type[this.paymentLinkType])}`;
            } else {
                url = `/api/send-payment-link/?id=${this.application.fullAppId}&type=${this.paymentLinkType}&value=${encodeURIComponent(this.type[this.paymentLinkType])}`;
            }
            
            this.sendPaymentLinkSubmitted = true;

            if(!this.$v.type.$invalid && !this.sendingEmailLink && !this.reSendingEmailLink) {
                if(type == 'initial') {
                    var spinTarget = this.$refs.btnspinneremail;
                    this.sendingEmailLink = true;
                } else {
                    var spinTarget = this.$refs.btnspinneremailresend;
                    this.reSendingEmailLink = true;
                }
                var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);

                this.$http.get(url).then((res) => {
                    setTimeout(()=> {
                        this.sendPaymentEmail = true;
                        this.sendingEmailLink = false;
                        this.reSendingEmailLink = false;
                    }, 1000);
                    if(res.body.status == 'error') {
                        this.sendPaymentError = true;
                    } else {
                        this.sendPaymentError = false;
                    }
                });
            }
        },
        sendPaymentLinkPhone: function (type) {
            this.$v.type.$touch();

            let paymentText = this.type[this.paymentLinkType];

            let url = '';
            if(this.section == 'extension') {
                url = `/api/send-payment-link/?id=${ibJS.extension.id}&extn=1&type=${this.paymentLinkType}&value=${encodeURIComponent(paymentText)}`;
            } else {
                url = `/api/send-payment-link/?id=${this.application.fullAppId}&type=${this.paymentLinkType}&value=${encodeURIComponent(paymentText)}`;
            }

            this.sendPaymentLinkSubmitted = true;

            if(!this.$v.type.$invalid && !this.sendingPhoneLink && !this.reSendingPhoneLink) {
                if(type == 'initial') {
                    var spinTarget = this.$refs.btnspinnerphone;
                    this.sendingPhoneLink = true;
                } else {
                    var spinTarget = this.$refs.btnspinnerphoneresend;
                    this.reSendingPhoneLink = true;
                }
                var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);

                this.$http.get(url).then((res) => {
                    setTimeout(()=> {
                        this.sendPaymentPhone = true;
                        this.sendingPhoneLink = false;
                        this.reSendingPhoneLink = false;
                    }, 1000)
                    if(res.body.status == 'error') {
                        this.sendPaymentError = true;
                    } else {
                        this.sendPaymentError = false;
                    }
                });
            }
        },
        sendPaymentLinkWhatsapp: function (type) {
            this.$v.type.$touch();

            let paymentText = this.type[this.paymentLinkType];

            let url = '';
            if(this.section == 'extension') {
                url = `/api/send-payment-link/?id=${ibJS.extension.id}&extn=1&type=${this.paymentLinkType}&value=${encodeURIComponent(paymentText)}`;
            } else {
                url = `/api/send-payment-link/?id=${this.application.fullAppId}&type=${this.paymentLinkType}&value=${encodeURIComponent(paymentText)}`;
            }

            this.sendPaymentLinkSubmitted = true;

            if(!this.$v.type.$invalid && !this.sendingWhatsappLink && !this.reSendingWhatsappLink) {
                if(type == 'initial') {
                    var spinTarget = this.$refs.btnspinnerwhatsapp;
                    this.sendingWhatsappLink = true;
                } else {
                    var spinTarget = this.$refs.btnspinnerwhatsappresend;
                    this.reSendingWhatsappLink = true;
                }
                var spinner = new IBSpinner(ibSpinOpts).spin(spinTarget);

                this.$http.get(url).then((res) => {
                    setTimeout(()=>{
                        this.sendPaymentWhatsapp = true;
                        this.sendingWhatsappLink = false;
                        this.reSendingWhatsappLink = false;
                    }, 1000)
                    if(res.body.status == 'error') {
                        this.sendPaymentError = true;
                    } else {
                        this.sendPaymentError = false;
                    }
                });
            } 
        },
        pollPayment: function () {
            let url = '';
            if(this.section == 'extension') {
                url = `/api/extension/verify-payment-details/?id=${ibJS.extension.id}`;
            } else {
                url = `/api/application/verify-payment-details/?id=${this.application.fullAppId}`;
            }

            this.polling = setInterval(() => {
                // Will be calling every 3 seconds
                this.$http.get(url).then(res=>{
                    const responseBody = res && res.body ? res.body : {};
                    if(responseBody && responseBody.status === 'error') {
                        // handle error
                        // this.errors = responseBody.errors;
                    } else {
                        // at success
                        this.paymentSuccess = true;
                        this.paymentError = false;
                        global.eventHub.$emit('reset-ss-error');
                        this.clearPolling();
                        if (this.$parent.form?.paymentMethod && this.$parent.form.paymentMethod.toLowerCase() == 'paypal') {
                            /**
                             * If user have tried paypal and contacted our CSR team then to reset the payment method
                             * we set paymentMethod to credit irrespective of the actual type the user has used in the pci payment method.
                             * This is just to escape the condition to show the billing address.
                             */
                            this.$parent.form.paymentMethod = 'credit';
                        }
                    }
                }, errors => {
                    const responseBody = errors.body;
                    this.errors = responseBody;
                });
            }, 3000);
        },
        clearPolling () {
            // Will Stop the polling
            clearInterval(this.polling);
        },
        resetPayment () {
            let url = '';
            if(this.section == 'extension') {
                url = '/api/reset-extension-payment-details/?id=' +ibJS.extension.id;
            } else {
                url = '/api/reset-payment-details/?id=' +this.application.fullAppId;
            }
            this.$http.get(url).then(response=>{
                const responseBody = response.body;
                this.resetSubmitted = true;
                this.$emit('set-reset-submitted', this.resetSubmitted);
                if(this.paymentError) {
                    this.pollPayment();
                }
            });
        },
        setTextNumber(val) {
            this.type.text = val;
        },
        setWhatsappNumber(val) {
            this.type.whatsapp = val;
        },
    }
});