class WorldTrips {
    constructor () {
        this.Atlas_America = 705
        this.Atlas_International = 706
        this.Atlas_Essential_America = 707
        this.Atlas_Essential_International = 708
        this.Atlas_Premium_America = 709
        this.Atlas_Premium_International = 710
        this.ExchangeGuard_Parent = 711
        this.ExchangeGuard_Choice = 712
        this.ExchangeGuard_Essential = 713
        this.VisitorSecure_Parent = 714
        this.VisitorSecure_100000 = 714040
        this.VisitorSecure_130000 = 714050
        this.VisitorSecure_50000 = 714020
        this.VisitorSecure_75000 = 714030
        this.Atlas_MedEvac = 715
        this.Atlas_Multi_Trip = 716
        this.StudentSecure_Parent = 717 
        this.StudentSecure_Elite = 718 
        this.StudentSecure_Select = 719 
        this.StudentSecure_Budget = 720 
        this.StudentSecure_Smart = 721
        this.Atlas_Group_America = 722
        this.Atlas_Group_International = 723
        this.ExchangeGuard_Group_Parent = 730
        this.ExchangeGuard_Group_Choice = 731
        this.ExchangeGuard_Group_Essential = 732
    }

    allInAppIds () {
        return [
            this.Atlas_America,
            this.Atlas_International,
            this.Atlas_Essential_America,
            this.Atlas_Essential_International,
            this.Atlas_Premium_America,
            this.Atlas_Premium_International,
            this.ExchangeGuard_Parent,
            this.ExchangeGuard_Choice,
            this.ExchangeGuard_Essential,
            this.VisitorSecure_Parent,
            this.VisitorSecure_100000,
            this.VisitorSecure_130000,
            this.VisitorSecure_50000,
            this.VisitorSecure_75000,
            this.Atlas_MedEvac,
            this.StudentSecure_Parent, 
            this.StudentSecure_Elite, 
            this.StudentSecure_Select, 
            this.StudentSecure_Budget, 
            this.StudentSecure_Smart,
            this.ExchangeGuard_Group_Parent,
            this.ExchangeGuard_Group_Choice,
            this.ExchangeGuard_Group_Essential
        ]
    }

    isTripInsurance () {
        // No Trip plans for WorldTrips
        return [];
    }

    allPlanIds () {
        return [
            this.Atlas_America,
            this.Atlas_International,
            this.Atlas_Essential_America,
            this.Atlas_Essential_International,
            this.Atlas_Premium_America,
            this.Atlas_Premium_International,
            this.ExchangeGuard_Parent,
            this.ExchangeGuard_Choice,
            this.ExchangeGuard_Essential,
            this.VisitorSecure_Parent,
            this.VisitorSecure_100000,
            this.VisitorSecure_130000,
            this.VisitorSecure_50000,
            this.VisitorSecure_75000,
            this.Atlas_MedEvac,
            this.Atlas_Multi_Trip,
            this.StudentSecure_Parent,
            this.StudentSecure_Elite,
            this.StudentSecure_Select,
            this.StudentSecure_Budget,
            this.StudentSecure_Smart,
            this.Atlas_Group_America,
            this.Atlas_Group_International,
            this.ExchangeGuard_Group_Parent,
            this.ExchangeGuard_Group_Choice,
            this.ExchangeGuard_Group_Essential
        ];
    }
}

export default WorldTrips;