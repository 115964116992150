<template>
    <div class="c-ibApp__aside-wrap">
        <div class="c-ibApp__aside-h">
            <h3>{{qResultsLang.summaryInputSummary}}</h3>
            <button tabindex="2" v-on:click="goToFirstStep" type="button"
                class="c-ibApp__aside-btn" :class="app.currentStep == 4 || app.currentStep == 5 ? 'disabled' : ''" :disabled="app.currentStep == 4 || app.currentStep == 5 ? true : false">
                <span>{{qResultsLang.summaryEdit}}</span>
                <span ref="btnspinner"
                    v-show="formSending"
                    class="o-Btn-spinner"
                    v-cloak
                ></span>
            </button>
        </div>
        <div class="c-ibApp__aside-detail">
            <ul class="c-ibApp__aside-list">
                <template v-if="stTripCancellationPlans || isTravelexPlans || isTXFlightPlan || imgTripPlan">
                    <li class="c-ibApp__aside-detail-h c-ibApp__aside-item">{{qResultsLang.summaryTravelDates}} ({{coverageDates}})</li>
                    <li class="c-ibApp__aside-item">{{qResultsLang.summaryDepartureDate}} <span>{{form.startDate}}</span></li>
                    <li class="c-ibApp__aside-item">{{qResultsLang.summaryReturnDate}} <span>{{form.endDate}}</span></li>
                    <li class="c-ibApp__aside-item">{{qResultsLang.summaryNumOfTravelersTrip}} <span>{{application.travelerInfos.length}}</span></li>
                </template>
                <template v-else-if="isCareington">
                    <li class="c-ibApp__aside-detail-h c-ibApp__aside-item">{{qResultsLang.membership}} 
                        <template v-if="careington.billingOpt == 1 && careington.noOfMonths != -1">
                            ({{careington.noOfMonths}} {{careington.noOfMonths > 1 ? qResultsLang.months : qResultsLang.month}})
                        </template>
                    </li>
                    <li class="c-ibApp__aside-item">{{qResultsLang.summaryStartDate}} <span>{{form.startDate}}</span></li>
                    <li class="c-ibApp__aside-item" v-if="form.endDate">{{qResultsLang.summaryEndDate}} <span>{{form.endDate}}</span></li>
                    <li class="c-ibApp__aside-item">{{qResultsLang.paymentFrequency}}
                        <span v-if="careington.billingOpt == '1'">{{qResultsLang.billingOptMonthly}}</span>
                        <span v-else v-cloak>{{qResultsLang.billingOptAnnual}}</span>
                    </li>
                    <li class="c-ibApp__aside-item">{{qResultsLang.noOfMembers}} <span>{{application.travelerInfos.length}}</span></li>
                </template>
                <template v-else>
                    <li class="c-ibApp__aside-detail-h c-ibApp__aside-item">{{qResultsLang.summaryCoverageDates}} ({{coverageDates}})</li>
                    <li class="c-ibApp__aside-item">{{qResultsLang.summaryStartDate}} <span>{{form.startDate}}</span></li>
                    <li class="c-ibApp__aside-item">{{qResultsLang.summaryEndDate}} <span>{{form.endDate}}</span></li>
                    <li class="c-ibApp__aside-item">{{qResultsLang.summaryNumOfTravelers}} <span>{{application.travelerInfos.length}}</span></li>
                </template>
            </ul>
        </div>
        <!--
            Coverage Summary
         -->
        <div class="c-ibApp__aside-detail" v-if="!isCareington" v-cloak>
            <div class="c-ibApp__aside-h">
                <h3>{{qResultsLang.summaryCoverageSummary}}</h3>
                <button tabindex="3" v-on:click="goToSecondStep" type="button"
                    class="c-ibApp__aside-btn" :class="app.currentStep == 4 || app.currentStep == 5 ? 'disabled' : ''" :disabled="app.currentStep == 4 || app.currentStep == 5 ? true : false">
                    <span>{{qResultsLang.summaryEdit}}</span>
                    <span ref="btnspinner"
                        v-show="formSending"
                        class="o-Btn-spinner"
                        v-cloak
                    ></span>
                </button>
            </div>
            <div class="c-ibApp__aside-detail">
                <ul class="c-ibApp__aside-list" v-if="!imgTripPlan">
                    <li v-if="((form.policyMaximum > 0 || (form.deductible !== '' && form.deductible!==null)) && application.planId !== 715) || isNationWidePlans || isVoyagerPlans"
                        class="c-ibApp__aside-detail-h c-ibApp__aside-item"
                            >{{(isNationWidePlans || isTXFlightPlan || isVoyagerPlans) ? qResultsLang.summaryBaseCoverageLabel : qResultsLang.summarBaseCoverageChosen}}
                    </li>
                    <template v-if="application.planId !== 715">
                        <li class="c-ibApp__aside-item" v-if="form.policyMaximum > 0 && !isTXFlightPlan">
                            <span v-if="!isTripProtectPlans && !isTravelexPlans && !isVoyagerPlans">{{qResultsLang.summarPolicyMaximum}} <span v-if="showDependentPolicyMaxElements">- {{qResultsLang.summaryPrimary}}</span></span>
                            <span v-else-if="!isTravelexPlans">{{qResultsLang.medicalMax}}</span>
                            <span v-if="!isTravelexPlans && !isSelectPlans">{{form.policyMaximum | currencyNoCent}}</span>
                        </li>
                        <li v-if="showDependentPolicyMaxElements" class="c-ibApp__aside-item">
                            <span>{{qResultsLang.summarPolicyMaximumDependent}}</span>
                            <span>{{100000 * (application.travelerInfos.length -1) | currencyNoCent}}</span>
                        </li>
                        <span v-if="isTravelexPlans && !isTXFlightPlan">
                            <li class="c-ibApp__aside-item">
                                {{qResultsLang.travelex.medExpense}}<span v-if="isSelectPlans">$50,000</span><span v-else>$15,000</span>
                            </li>
                            <li class="c-ibApp__aside-item">
                                <span>{{qResultsLang.tripcancellation}}</span>
                                <span>{{qResultsLang.tripCost100}}</span>
                            </li>
                            <li class="c-ibApp__aside-item">
                                <span>{{qResultsLang.tripInsurance}}</span>
                                <span v-if="!isSelectPlans">{{qResultsLang.tripCost100}}</span><span v-else>{{qResultsLang.tripCost150}}</span>
                            </li>
                        </span>
                        <li class="c-ibApp__aside-item" v-if="isTXFlightPlan">
                            <span>{{qResultsLang.travelex.flightAccidentLevel}}</span>
                            <span>{{application.policyMaximum | currencyNoCent}}</span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="form.deductible !='' && showStudentSecureOutsideDeductible()">
                            {{qResultsLang.summaryDeductible}} <span>{{form.deductible | currencyNoCent}} / {{ showStudentSecureOutsideDeductible() | currencyNoCent}}</span>
                        </li>
                        <li class="c-ibApp__aside-item" v-else-if="(form.deductible !== '' && form.deductible !== null) && !isNationWidePlans">
                            {{qResultsLang.summaryDeductible}} <span>{{form.deductible | currencyNoCent}}  {{ showDeductibleText(form.deductible) }}</span>
                        </li>
                         <li class="c-ibApp__aside-item" v-if="form.coinsurance !== null">{{qResultsLang.summaryCoInsurance}}
                            <span v-if="form.coinsurance == '100/0'">{{qResultsLang.coInsurance100}}</span>
                            <span v-else>{{qResultsLang.coinsurance8020}}</span>
                        </li>
                         <li class="c-ibApp__aside-item" v-if="isSHA || isStudentSecure">{{qResultsLang.paymentFrequency}}
                            <span v-if="isSHAMonthly || isStudentSecureMonthly">{{qResultsLang.monthlyPaymentFrequency}}</span>
                              <span v-else>{{qResultsLang.oneTimePaymentFrequency}}</span>
                        </li>
                        <li class="c-ibApp__aside-item" v-else-if="application.productType && application.productType != ''">{{qResultsLang.productType}} <span>{{greenCoverRiderLabel}}</span></li>
                        <span v-if="isNationWidePlans" v-html="showNationWideText"></span>
                        <span v-else-if="isVoyagerPlans">
                            <li class="c-ibApp__aside-item">Medical Expense 
                                <span>$250,000</span>
                            </li>
                            <li class="c-ibApp__aside-item">{{qResultsLang.tripcancellation}} <span>100% of Trip Cost</span></li>
                            <li class="c-ibApp__aside-item">{{qResultsLang.tripInsurance}} <span>150% of Trip Cost</span></li>
                        </span>
                        <span v-else-if="application.planId == 725 || application.planId == 726">
                            <li class="c-ibApp__aside-item">Medical Expense <span>$50,000</span></li>
                            <li class="c-ibApp__aside-item">{{qResultsLang.tripcancellation}} <span>100% of Trip Cost</span></li>
                            <li class="c-ibApp__aside-item">{{qResultsLang.tripInsurance}} <span>100% of Trip Cost</span></li>
                        </span>
                        <span v-else-if="application.planId == 727">
                            <li class="c-ibApp__aside-item">Medical Expense <span>$150,000</span></li>
                            <li class="c-ibApp__aside-item">{{qResultsLang.tripcancellation}} <span>100% of Trip Cost</span></li>
                            <li class="c-ibApp__aside-item">{{qResultsLang.tripInsurance}} <span>125% of Trip Cost</span></li>
                        </span>
                    </template>
                    <template v-else>
                        <li class="c-ibApp__aside-item">{{qResultsLang.emergency}}<span>{{qResultsLang.fiftyK}}</span></li>
                        <li class="c-ibApp__aside-item">{{qResultsLang.repatriation}}<span>{{qResultsLang.twentyFiveK}}</span></li>
                    </template>
                    
                    <li class="c-ibApp__aside-item" v-if="basicMaxTravelDelay && !isOutsideUSPlans"><span>{{qResultsLang.tripDelayMax}}</span>
                        <span>{{basicMaxTravelDelay | currencyNoCent}}</span>
                    </li>
                </ul>
                <ul class="c-ibApp__aside-list" v-if="imgTripPlan">
                    <li class="c-ibApp__aside-detail-h c-ibApp__aside-item">
                        {{qResultsLang.summaryBaseCoverageLabel}}
                    </li>
                    <li class="c-ibApp__aside-item">
                        Medical Expense
                        <span v-if="application.planId == 675">$100,000</span>
                        <span v-if="application.planId == 676">$500,000</span>
                        <span v-if="application.planId == 681">$250,000</span>
                    </li>
                     <li class="c-ibApp__aside-item">
                        {{qResultsLang.tripcancellation}} <span>100% of Trip Cost</span>
                    </li>
                     <li class="c-ibApp__aside-item">
                        {{qResultsLang.tripInsurance}}
                        <span v-if="application.planId == 675">125% of Trip Cost</span>
                        <span v-if="application.planId == 676 || application.planId == 681">150% of Trip Cost</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="basicMaxTravelDelay"><span>{{qResultsLang.tripDelayMax}}</span>
                        <span>{{basicMaxTravelDelay | currencyNoCent}}</span>
                    </li>
                </ul>
                <ul class="c-ibApp__aside-list" v-if="isTravelInsured">
                    <li class="c-ibApp__aside-detail-h c-ibApp__aside-item">
                        {{qResultsLang.summaryBaseCoverageLabel}}
                    </li>
                    <li class="c-ibApp__aside-item">
                        {{qResultsLang.tripcancellation}} <span>100% of Trip Cost</span>
                    </li>
                    <li class="c-ibApp__aside-item">
                        {{qResultsLang.tripInsurance}} <span>150% of Trip Cost</span>
                    </li>
                </ul>
                <ul class="c-ibApp__aside-list">
                    <li v-if="optionalCoveragesPrice > 0 ||
                        (homeCountryRider && homeCountryRider.length > 0) ||
                        totalAddOnPrice > 0 ||
                        form.rentalCarDamageCoverageDisplay ||
                        iTI.carRental ||
                        iTI.cancelAnyReason || 
                        form.enhancedMedicalUpgrade || 
                        (application.companyCode == 'Travelex' && form.policyMaximum == 100000)"
                        class="c-ibApp__aside-detail-h c-ibApp__aside-item"
                            >{{qResultsLang.summaryOptionalCoveragesChosen}}
                    </li>
                    
                    <li class="c-ibApp__aside-item" v-if="form.adndCoverage > 0"><span v-html="qResultsLang.summaryADNDCoverageP2"></span>
                        <span>+&nbsp;{{form.adndCoverage | currency}}</span>
                    </li>
                    <template v-if="application.companyCode === 'GU'">
                        <li class="c-ibApp__aside-item" v-if="gu.athleticSports"><span v-html="qResultsLang.GU.athleticSports"></span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="gu.warCoverage"><span v-html="qResultsLang.GU.warCoverage"></span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="gu.homeCountryCoverage"><span v-html="qResultsLang.GU.homeCountryCoverage"></span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="gu.aDnDbenifit"><span v-html="qResultsLang.GU.aDnDbenifit"></span>
                        </li>
                    </template>
                    <li class="c-ibApp__aside-item" v-if="form.adnd24 > 0"><span>{{qResultsLang.adnd24}}</span><span v-if="adnd24RiderValue">{{adnd24RiderValue | currencyNoCent}}<span v-if="application.planId == 689">&nbsp;Total</span></span></li>
                    <li class="c-ibApp__aside-item" v-if="form.adndCoverageTrawick > 0"><span v-html="qResultsLang.summaryADNDCoverageP2"></span>
                        <span>+&nbsp;{{form.adndCoverageTrawick | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.enhancedMedicalUpgrade > 0"><span>{{qResultsLang.enhancedMedicalUpgrade}}</span><span>$500,000 Total</span></li>
                    <li class="c-ibApp__aside-item" v-if="form.extremeSportsCoverage > 0"><span v-html="qResultsLang.summaryExtremeSportsCoverage"></span>
                        <span>+&nbsp;{{form.extremeSportsCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.crisisCoverage > 0"><span>{{qResultsLang.summaryCrisisResponseCoverage}}</span>
                        <span>+&nbsp;{{form.crisisCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.sportsRiderCoverage> 0"><span>{{qResultsLang.summarySportsRideCoverage}}</span>
                        <span>+&nbsp;{{form.sportsRiderCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.liabilityCoverage > 0"><span>{{qResultsLang.summaryPersonalLiabilityCoverage}}</span>
                        <span>+&nbsp;{{form.liabilityCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.sportsCoverage > 0"><span>{{qResultsLang.summarySportsCoverage}}</span>
                        <span>+&nbsp;{{form.sportsCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.followMeHomeCoverage > 0"><span>{{qResultsLang.summaryReturntoHomeCountryCoverage}}</span>
                        <span>+&nbsp;{{form.followMeHomeCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.adndTripCoverage > 0"><span>{{qResultsLang.summaryFlightAccidentCoverage}}</span>
                        <span>+&nbsp;{{form.adndTripCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.cancelAnyReason > 0"><span>{{qResultsLang.summaryCancelAnyReasonCoverage}}</span>
                        <span v-if="!isVoyagerPlans && application.planId != 727">+&nbsp;{{form.cancelAnyReason | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.rentalCarDamageCoverageDisplay && (application.planId === 727 || application.planId === 725 || application.planId === 726)"><span>{{qResultsLang.summaryRentalCarDamageCoverage}}</span></li>
                    <li class="c-ibApp__aside-item" v-else-if="form.rentalCarDamageCoverageDisplay"><span>{{qResultsLang.summaryRentalCarDamageCoverage}}</span>
                        <span>+&nbsp;{{form.rentalCarDamageCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.baggageCoverage > 0"><span>{{qResultsLang.summaryBaggageCoverage}}</span>
                        <span>+&nbsp;{{form.baggageCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.petAssist > 0"><span>{{qResultsLang.summaryPetAssistCoverage}}</span>
                        <span>+&nbsp;{{form.petAssist | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.adndFlight > 0"><span>{{qResultsLang.adndFlight}}</span>
                        <!-- <span>+&nbsp;{{form.adndFlight | currency}}</span> -->
                    </li>
                    <li class="c-ibApp__aside-item" v-if="maxTravelDelay > 0 && (isOutsideUSPlans || isVoyagerPlans)"><span>{{qResultsLang.tripDelayMax}}</span>
                        <span v-if="!isOutsideUSPlans">{{maxTravelDelay | currencyNoCent}} Total</span>
                        <span v-else-if="isOutsideUSPlans">+&nbsp;{{totalTripDelayPrice | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.interruptionForAnyReason > 0"><span>{{qResultsLang.interruptionForAnyReason}}</span></li>
                    <li class="c-ibApp__aside-item" v-if="form.hazSportsCoverage > 0"><span>{{qResultsLang.summaryHazSportsCoverage}}</span>
                        <span>+&nbsp;{{form.hazSportsCoverage | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalHeartCarePlus > 0"><span>{{qResultsLang.summaryHeartCarePlusCoverage}}</span>
                        <span>+&nbsp;{{totalHeartCarePlus | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalAdventureSports > 0"><span>{{qResultsLang.summaryAdventureSportsCoverage}}</span>
                        <span>+&nbsp;{{totalAdventureSports | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalTeladoc > 0"><span>{{qResultsLang.teladoc}}</span>
                        <span>+&nbsp;{{totalTeladoc | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalADND > 0"><span>{{qResultsLang.summaryEnhancedADNDCoverage}}</span>
                        <span>+&nbsp;{{totalADND | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalCitizenshipReturn > 0"><span>{{qResultsLang.summaryCitizenshipReturnCoverage}} {{qResultsLang.requiredRider}}</span>
                        <span>+&nbsp;{{totalCitizenshipReturn | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalPersonalLiability > 0"><span>{{qResultsLang.personalLiability}}</span>
                        <span>+&nbsp;{{totalPersonalLiability | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="homeCountryRider && homeCountryRider.length > 0"><span>{{qResultsLang.EoTHomeCoverage}}</span>
                        <!--<span>+&nbsp;{{totalPersonalLiability | currency}}</span>-->
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalAddOnPrice > 0">{{imgMedical ? qResultsLang.addOnPlan2 : qResultsLang.addOnPlan}}
                        <span>+&nbsp;{{totalAddOnPrice | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalEvacPlus > 0"><span>{{qResultsLang.summaryEvacPlusCoverage}}</span>
                        <span>+&nbsp;{{totalEvacPlus | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalDeviceProtect > 0 && form.deviceProtectCoverage"><span>{{qResultsLang.deviceProtection}}</span>
                        <span>+&nbsp;{{totalDeviceProtect | currency}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="form.adndCoverage > 0 && form.crisisCoverage > 0 && form.liabilityCoverage > 0">{{qResultsLang.bundleDiscount}}   <span>-&nbsp;{{hccBundleDiscount | currency}}</span>
                    </li>
                    <li v-if="iTI.carRental && imgTripPlan">
                        <div class="c-ibApp__aside-item">
                            <span v-html="qResultsLang.iTI.carRental"></span>
                            <span>+ {{iTI.carRentalPrice | currency}}</span>
                        </div>
                    </li>
                    <li v-if="iTI.cancelAnyReason && imgTripPlan">
                        <div class="c-ibApp__aside-item">
                            <span v-html="qResultsLang.iTI.cancelAnyReason" style="width:70%;"></span>
                            <span>+ {{iTI.cancelAnyReasonPrice | currency}}</span>
                        </div>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalExtremeSports > 0"><span>{{qResultsLang.extremeSports}}</span>
                    </li>
                    <li class="c-ibApp__aside-item" v-if="totalMountaineering > 0"><span>{{qResultsLang.mountaineering}}</span>
                    </li>
                    <span v-if="application.companyCode === 'Travelex'">
                        <li class="c-ibApp__aside-item" v-if="travelex.ridersToShow.adnd">{{qResultsLang.travelex.flightAdnd}}
                        </li>
                        <li class="c-ibApp__aside-item" v-if="travelex.ridersToShow.adventureSports">{{qResultsLang.travelex.adventureSportsUpgrade}}
                        </li>
                        <li class="c-ibApp__aside-item" v-if="travelex.ridersToShow.cancelForAnyReason">{{qResultsLang.travelex.cancelForAnyReason}}
                        </li>
                        <li class="c-ibApp__aside-item" v-if="travelex.ridersToShow.medCoverage">
                            <span v-html="qResultsLang.travelex.medExpensesUpgrade"></span>
                            <span>{{'100000' | currencyNoCent}} Total</span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="travelex.ridersToShow.collisionProtection">{{qResultsLang.travelex.collisionProtectionUpgrade}}
                        </li> 
                        

                       <!-- <li class="c-ibApp__aside-item" v-if="travelex.ridersToShow.totalADND > 0">{{qResultsLang.travelex.adndUpgrade}}
                            <span>+&nbsp;{{travelex.ridersToShow.totalADND | currency}}</span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="travelex.ridersToShow.totalAdventureSports > 0">{{qResultsLang.travelex.adventureSportsUpgrade}}
                            <span>+&nbsp;{{travelex.ridersToShow.totalAdventureSports | currency}}</span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="travelex.ridersToShow.totalCancelForAnyReason > 0">{{qResultsLang.travelex.cancelForAnyReason}}
                            <span>+&nbsp;{{travelex.ridersToShow.totalCancelForAnyReason | currency}}</span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="travelex.ridersToShow.totalCollisionProtection > 0">{{qResultsLang.travelex.collisionProtectionUpgrade}}
                            <span>+&nbsp;{{travelex.ridersToShow.totalCollisionProtection | currency}}</span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="form.policyMaximum == 100000"><span v-html="qResultsLang.travelex.medExpensesUpgrade"></span>
                            <span>+&nbsp;{{travelex.medCoveragePrice | currency}}</span>
                        </li> -->
                    </span>
                    <span v-if="application.companyCode === 'TravelInsured'">
                       <li class="c-ibApp__aside-item" v-if="travelInsured.flightAccident > 0">{{qResultsLang.travelInsured.flightAccident}}
                            <span v-if="showRiderPrices">+&nbsp;{{travelInsured.flightAccident | currency}}</span>
                        </li>
                        <li class="c-ibApp__aside-item" v-if="travelInsured.travelBenefitUpgrade > 0">{{qResultsLang.travelInsured.travelBenefitUpgrade}}
                            <span v-if="showRiderPrices">+&nbsp;{{travelInsured.travelBenefitUpgrade | currency}}</span>
                        </li>
                       <li class="c-ibApp__aside-item" v-if="travelInsured.primaryCoverageUpgrade > 0">{{qResultsLang.travelInsured.primaryCoverageUpgrade}}
                            <span v-if="showRiderPrices">+&nbsp;{{travelInsured.primaryCoverageUpgrade | currency}}</span>
                        </li>
                       <li class="c-ibApp__aside-item" v-if="travelInsured.cancelForWorkReason > 0">{{qResultsLang.travelInsured.cancelForWorkReason}}
                            <span v-if="showRiderPrices">+&nbsp;{{travelInsured.cancelForWorkReason | currency}}</span>
                        </li>
                       <li class="c-ibApp__aside-item" v-if="travelInsured.medicalUpgrade > 0">{{qResultsLang.travelInsured.medicalUpgrade}}
                            <span v-if="showRiderPrices">+&nbsp;{{travelInsured.medicalUpgrade | currency}}</span>
                        </li>
                       <li class="c-ibApp__aside-item" v-if="travelInsured.rentalCarDamage > 0">{{qResultsLang.travelInsured.rentalCarDamage}}
                            <span v-if="showRiderPrices">+&nbsp;{{travelInsured.rentalCarDamage | currency}}</span>
                        </li>
                       
                    </span>
                </ul>
                <ul v-if="iTI.teladoc" class="c-ibApp__aside-list">
                    <li class="c-ibApp__aside-detail-h c-ibApp__aside-item">{{qResultsLang.additionalBenfits}}
                    </li>
                    <li class="c-ibApp__aside-item">
                        {{qResultsLang.teladoc}} <span>+&nbsp;{{iTI.teladocPrice | currency }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 
            COST SUMMARY BLOCK
         -->
        <div class="c-ibApp__aside-detail" v-if="!imgTripPlan">
            <div v-if="!isCareington">
                <h3 class="c-ibApp__aside-h">
                    {{qResultsLang.summaryCostSummary}}
                </h3>
            </div>
            <div class="c-ibApp__aside-h" v-else>
                <h3>{{qResultsLang.summaryCostSummary}}</h3>
                <button tabindex="2" v-on:click="goToSecondStep" type="button"
                    class="c-ibApp__aside-btn" :class="app.currentStep == 4 || app.currentStep == 5 ? 'disabled' : ''" :disabled="app.currentStep == 4 || app.currentStep == 5 ? true : false">
                    <span>{{qResultsLang.summaryEdit}}</span>
                    <span ref="btnspinner"
                        v-show="formSending"
                        class="o-Btn-spinner"
                        v-cloak
                    ></span>
                </button>
            </div>
            <ul class="c-ibApp__aside-list">
                <span>
                    <li class="c-ibApp__aside-detail-h c-ibApp__aside-item" v-if="!imgHideItemized">{{qResultsLang.summaryItemized}} <span v-if="application.companyCode != 'GU' && showItemized">{{qResultsLang.summaryPrice}}</span></li>
                    <li :key="index" class="c-ibApp__aside-item" v-for="(traveler,index) in application.travelerInfos" v-if="!isCareington && !isExchangeGuardGrp && !imgHideItemized">
                        <span v-if="application.companyCode==='Trawick'">
                            <span v-if="stTripCancellationPlans">
                                <span>{{qResultsLang.summaryTravelerTrip}} {{index + 1}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                            </span>
                            <span v-else>
                                <span>{{qResultsLang.summaryInsured}} {{index + 1}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                            </span>

                        </span>
                        <span v-else-if="application.companyCode==='WorldTrips'">
                            <span v-if="application.planId!=714">{{qResultsLang.summaryInsured}} {{index + 1}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                            <span v-else>
                                <span v-if="traveler.travelerType===''">{{qResultsLang.summaryPrimary}} {{qResultsLang.summaryInsured}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                                <span v-if="application.travelerInfos[1] && application.travelerInfos[1].travelerType==='spouse'">
                                    <span v-if="traveler.travelerType==='spouse'">{{qResultsLang.summarySpouse}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                                    <span v-else-if="traveler.travelerType==='child'">{{qResultsLang.summaryChild}} {{childDisplay(index)}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                                </span>
                                <span v-else-if="application.planId==714 && application.travelerInfos[1] && application.travelerInfos[1].travelerType==='child'">
                                    <span v-if="traveler.travelerType==='child'">{{qResultsLang.summaryChild}} {{childDisplay(index)}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                                </span>
                            </span>
                        </span>
                        <span v-else-if="application.companyCode==='ARS'">
                            <span >
                                <span v-if="traveler.travelerType==='primary' || traveler.travelerType===''">{{qResultsLang.summaryPrimary}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                                <span v-if="application.travelerInfos[1] && application.travelerInfos[1].travelerType==='spouse'">
                                    <span v-if="traveler.travelerType==='spouse'">{{qResultsLang.summarySpouse}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                                    <span v-else-if="traveler.travelerType==='child'">{{qResultsLang.summaryChild}} {{childDisplay(index)}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                                </span>
                                <span v-else-if="application.travelerInfos[1] && application.travelerInfos[1].travelerType==='child'">
                                    <span v-if="traveler.travelerType==='child'">{{qResultsLang.summaryChild}} {{childDisplay(index)}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                                </span>
                            </span>
                        </span>
                        <span v-else>
                            <span v-if="isTravelexPlans || isTravelInsured">{{qResultsLang.summaryTravelerTrip}} {{index + 1}} <span v-if="traveler.age || traveler.age !== 0">({{traveler.age}} {{qResultsLang.summaryYears}})</span></span>
                            <span v-else-if="traveler.travelerType==='primary' || traveler.travelerType===''">{{qResultsLang.summaryPrimary}} {{qResultsLang.summaryInsured}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                            <span v-else-if="traveler.travelerType==='spouse'">{{application.companyCode != 'GU' ? qResultsLang.summarySpouse : qResultsLang.summaryAdditional}} {{application.companyCode == 'GU' ? qResultsLang.summaryInsured : ''}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                            <span v-else-if="traveler.travelerType==='child'">{{qResultsLang.summaryChild}} {{childDisplay(index)}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                            <span v-else>{{qResultsLang.summaryOther}} {{otherDisplay(index)}} ({{traveler.age}} {{qResultsLang.summaryYears}})</span>
                        </span>
                        <span v-if="application.companyCode != 'GU' && showItemized">
                            <span v-if="isSHAMonthly">
                                {{traveler.premium * (application.planResult.shaMonths) | currency}}
                            </span>
                            <span v-else-if="isStudentSecureMonthly">
                                {{traveler.premium * (application.planResult.scMonths) - (5 * application.planResult.scMonths) | currency}}
                            </span>
                            <span v-else-if="showPerPersonPrice">
                                {{traveler.premium | currency}}
                            </span>
                        </span>
                    </li>
                    <li v-if="isCareington" class="c-ibApp__aside-item">
                        <span>Membership Fee</span>
                        <span>{{careington.premium | currency}}</span>
                    </li>
                    <li v-if="isExchangeGuardGrp">
                        <template v-for="(group,index) in application.groupTravelersInfo">
                            <div class="c-ibApp__aside-item c-ibApp__aside-item--group">
                                <div @click.prevent="toggleAgeGroups(index)" class="o-Icon-bullet o-Icon-bullet__plus-brackets o-Icon-bullet__plus--sm" :class="{'o-Icon-bullet__minus-brackets o-Icon-bullet__minus--sm' : group.showItemized}">
                                    <span style="white-space: nowrap;">{{qResultsLang.egGroup.ageGrp}} {{index + 1}}
                                        (<span v-if="group.ageGroup === 0">{{qResultsLang.egGroup.ageGrp0}}</span>
                                        <span v-else-if="group.ageGroup === 25">25 - 29</span>
                                        <span v-else-if="group.ageGroup === 30">30 - 39</span>
                                        <span v-else-if="group.ageGroup === 40">40 - 49</span>
                                        <span v-else-if="group.ageGroup === 50">50 - 64</span>)&nbsp;
                                    </span>
                                </div>
                                <span>{{group.totalPrice | currency}}</span>
                            </div>
                            <div v-if="group.showItemized" v-for="(traveler, idx) in group.grpTravelerPrice" class="c-ibApp__aside-item c-ibApp__form-itemized-age">
                                {{qResultsLang.egGroup.grpMember}} {{idx + 1}} <template v-if="traveler.age > -1">({{traveler.age}} {{qResultsLang.summaryYears}})</template>
                                <span>{{traveler.premium | currency}}</span>
                            </div>
                        </template>
                    </li>
                </span>
                
                <li class="c-ibApp__aside-detail-total c-ibApp__aside-item" v-if="isStudentSecureMonthly">
                    <span>{{qResultsLang.adminFee}}</span>
                    <span>{{(5 * application.planResult.scMonths) | currency}}</span>
                </li>
                <li class="c-ibApp__aside-item" v-if="shipCharge>0">
                    <span>{{qResultsLang.shippingCharges}}</span>
                    <span>{{shipCharge | currency}}</span>
                </li>
                <li class="c-ibApp__aside-detail-text-w-brdr u-Ma-t-half" v-if="surplusTax">
                    <span class="c-ibApp__aside-detail-text--red">{{qResultsLang.surplusTax}}</span>
                </li>
                <li class="c-ibApp__aside-item" v-if="applicationFee > 0" :class="{'c-ibApp__aside-detail-total': !isCareington}">{{qResultsLang.applnFee}} <span>{{applicationFee| currency}}</span></li>

                <li class="c-ibApp__aside-detail-h c-ibApp__aside-item c-ibApp__aside-detail-total" :class="{'u-Border-top--lt-gray': showGrandTotal, 'u-border-top-0': imgHideItemized}" v-if="!isTXFlightPlan && !isCareington">
                    {{qResultsLang.summaryTotal}}<span style="flex:auto" class="o-Question" @click.prevent="showPaymentOptions"></span>
                    <span v-if="showGrandTotal">{{totalTextAmount| currency}}</span>
                    <span v-else-if="imgHideItemized">{{10 + shipCharge | currency}}</span>
                    <span v-else>{{application.totalPrice + shipCharge | currency}}</span>
                </li>
                <li class="c-ibApp__aside-detail-h c-ibApp__aside-item c-ibApp__aside-detail-total" v-if="isTXFlightPlan">
                    {{qResultsLang.summaryTotal}}<span style="flex:auto" class="o-Question" @click.prevent="showPaymentOptions"></span>
                    <span>{{application.totalPrice | currency}}</span>
                </li>
                <li class="c-ibApp__aside-detail-h c-ibApp__aside-item c-ibApp__aside-detail-total" v-if="isCareington">
                    {{qResultsLang.summaryTotal}}<span style="flex:auto" class="o-Question" @click.prevent="showPaymentOptions"></span>
                    <span>{{careingtonTotalPrice | currency}}</span>
                </li>

                <li class="c-ibApp__aside-detail-h c-ibApp__aside-item c-ibApp__aside-detail-total c-ibApp__aside-detail-total--today" v-if="showGrandTotal">
                    <span>{{qResultsLang.shaTotal}}</span>   <!--AKA today's total-->
                    <span>{{application.totalPrice + shipCharge | currency}}</span>
                </li>
                <li class="c-ibApp__aside-detail-h c-ibApp__aside-item c-ibApp__aside-detail-total" v-if="isCareingtonMonthly">
                    <span>{{qResultsLang.shaTotal}}</span>   <!--AKA today's total-->
                    <span>{{careingtonTotalDue | currency}}</span>
                </li>
                <li class="c-ibApp__aside-item c-ibApp__aside-item--no-border c-ibApp__aside-item--block c-ibApp__aside-item--note c-ibApp__aside-item--span-inline" v-if="isSHAMonthly">
                    <span>*<span v-if="plan.calcMonths == 1">{{qResultsLang.futureInstallmentsSingular}}</span><span v-else>{{qResultsLang.futureInstallments}}</span> {{application.totalPrice | currency}} <span v-if="plan.calcMonths == 1">{{qResultsLang.willBeBilledSingular}}</span><span v-else>{{qResultsLang.willBeBilled}}</span> {{remainingMonthsSHA.toLowerCase()}}{{qResultsLang.period}}</span>
                </li>
                <li class="c-ibApp__aside-item c-ibApp__aside-item--no-border c-ibApp__aside-item--block c-ibApp__aside-item--note c-ibApp__aside-item--span-inline" v-else-if="isStudentSecureMonthly" >
                    <span>*<span v-if="(plan.scMonths - 1) === 1">{{qResultsLang.futureInstallmentsSingular}}</span><span v-else>{{qResultsLang.futureInstallments}}</span> {{application.totalPrice | currency}} <span v-if="(this.plan.scMonths - 1) === 1">{{qResultsLang.willBeBilledSingular}}</span><span v-else>{{qResultsLang.willBeBilled}}</span> {{remainingMonthsSC.toLowerCase()}}{{qResultsLang.period}}</span>
                </li>
                <li class="c-ibApp__aside-item c-ibApp__aside-item--no-border c-ibApp__aside-item--block c-ibApp__aside-item--note c-ibApp__aside-item--span-inline" v-if="isCareingtonMonthly" >
                    <span>*<span v-if="(careington.noOfMonths - 1) === 1">{{qResultsLang.futureInstallmentsSingular}}</span><span v-else>{{qResultsLang.futureInstallments}}</span> {{careington.futureInstallment | currency}} <span v-if="(careington.noOfMonths - 1) === 1">{{qResultsLang.willBeBilledSingular}}</span><span v-else>{{qResultsLang.willBeBilled}}</span> {{remainingMonthsCNT.toLowerCase()}}{{qResultsLang.period}}</span>
                </li>
                <li class="c-ibApp__aside-item c-ibApp__aside-item--no-border c-ibApp__aside-item--block c-ibApp__aside-item--note c-ibApp__aside-item--span-inline" v-if="isCareingtonIndefinite" >
                    <span>*{{qResultsLang.futureInstallments}} {{careington.futureInstallment | currency}} {{qResultsLang.monthlyUntilCanceled}}{{qResultsLang.period}}</span>
                </li>
                <li class="c-ibApp__aside-item c-ibApp__aside-item--no-border c-ibApp__aside-item--block c-ibApp__aside-item--note c-ibApp__aside-item--span-inline" v-if="isCareingtonAnnual" >
                    <span>*{{qResultsLang.futureInstallments}} {{careington.futureInstallment | currency}} {{qResultsLang.yearlyUntilCanceled}}{{qResultsLang.period}}</span>
                </li>
            </ul>
        </div>
        <div class="c-ibApp__aside-detail" v-if="imgTripPlan">
            <h3 class="c-ibApp__aside-h">{{qResultsLang.summaryCostSummary}}</h3>
            <ul class="c-ibApp__aside-list">
                <li class="c-ibApp__aside-detail-h c-ibApp__aside-item">
                {{qResultsLang.summaryItemized}}
                    <span>{{qResultsLang.summaryPrice}}</span>
                </li>
                <li v-for="(traveler,index) in plan.travelersInfo" class="c-ibApp__aside-item" :key="index">
                    {{qResultsLang.iTI.travelerLabel}} {{index + 1}} ({{traveler.age}} {{qResultsLang.summaryYears}})
                    <span v-if="iTI.cancelAnyReasonPrice">{{traveler.totalPrice | currency}}</span>
                    <span v-else>{{traveler.premium  | currency}}</span>
                </li>
                <li class="c-ibApp__aside-detail-h c-ibApp__aside-item c-ibApp__aside-detail-total">
                    {{qResultsLang.summaryTotal}}<span style="flex:auto" class="o-Question" @click.prevent="showPaymentOptions"></span>
                    <span v-if="iTI.cancelAnyReasonPrice">{{iTI.totalPrice | currency}}</span>
                    <span v-else>{{application.totalPrice  | currency}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script type="text/javascript">

    import '../../section-specific/applications/types/common'

    //donot remove code breaks for some reason

    import {
        createUpdateApplicationService
    } from '../../helper-functions/services';

    import {hccConfig, IMGConfig, travelexConfig, travelInsuredConfig, trawickConfig } from "../../helper-functions/app-configs";
    import IMGPlanIds from '../../helper-functions/companies/img-planids';

    import {
        convertDateByLang,getDurationForApplications, saveApplication
    } from'../../helper-functions/helper-functions'

    const imgPlanIds = new IMGPlanIds();

    export default {
    props: ['app','shipping-charges', 'get-pre-save-data'],
    data: function () {
        return {
            form:{
                policyMaximum:this.app.policyMaximum,
                deductible:this.app.deductible,
                coinsurance: this.app.coinsurance || null,
                adndCoverage:null,
                adndTripCoverage: null,
                rentalCarDamageCoverage:null,
                rentalCarDamageCoverageDisplay:null,
                baggageCoverage:null,
                cancelAnyReason:null,
                petAssist:null,
                adndCoverageTrawick:null,
                adndFlight: null,
                adnd24: null,
                tripDelay: null,
                crisisCoverage:null,
                sportsRiderCoverage:null,
                liabilityCoverage:null,
                sportsCoverage: null,
                deviceProtectCoverage: null,
                followMeHomeCoverage: null,
                hazSportsCoverage: null,
                adventureSportsCoverage: null,
                evacPlusCoverage: null,
                enhancedADNDCoverage: null,
                greenCoverPlanType:null,
                interruptionForAnyReason: null,
                enhancedMedicalUpgrade: null,
                riderList:[],
                startDate: this.app.startDate || '',
                endDate:this.app.endDate || '',
            },
            imgTripPlan: ibJS.imgTripPlan,
            /** checking for IMG alone might include iTravelInsure plans which has their own logic */
            imgMedical: this.app.companyCode === 'IMG' && !ibJS.imgTripPlan ? true : false,
            qResultsLang: window.qResultsLang || {},
            optionalCoveragesPrice: 0,
            totalTravelersPrice: 0,
            totalChildrenPrice: 0,
            totalADNDPrice: null,
            totalCrisisPrice: null,
            totalSportsRiderPrice: null,
            totalLiabilityPrice: null,
            totalHomeCountryPrice: null,
            totalAdultTravelers: 0,
            totalHeartCarePlus: 0,
            totalAdventureSports: 0,
            totalTeladoc: 0,
            totalAddOnPrice: 0,
            totalADND: 0,
            totalADNDFlightPrice: 0,
            totalADND24Price: 0,
            totalTripDelayPrice: 0,
            totalEvacPlus: 0,
            totalDeviceProtect: 0,
            totalCitizenshipReturn: 0,
            totalPersonalLiability: 0,
            totalExtremeSports: 0,
            totalMountaineering: 0,
            homeCountryRider: [],
            adultTravelerInfos:[],
            currentLang:window.globalLanguage.lang,
            application:this.app || {},
            plan:this.app.planResult,
            formSending:false,
            surplusTax: false,
            hccBundleDiscount:0,
            shipCharge: this.shippingCharges ? this.shippingCharges : '',
            greenCoverRiderLabel: '',
            gu: {
                aDnDbenifit: false,
                homeCountryCoverage: false,
                athleticSports: false,
                warCoverage: false
            },
            iTI: {
                carRental: false,
                carRentalPrice: 0,
                cancelAnyReason: false,
                cancelAnyReasonPrice: 0,
                teladoc: false,
                teladocPrice: 0,
                totalPrice: 0
            },
            travelex: {
                totalFlightAccident: 0,
                totalADND: 0,
                totalRentalCarDamage: 0,
                totalCollisionProtection: 0,
                totalCancelForAnyReason: 0,
                totalAdventureSports: 0,
                medCoveragePrice: 0,
                ridersToShow: {
                    totalFlightAccident: 0,
                    totalADND: 0,
                    adnd: false,
                    totalRentalCarDamage: 0,
                    rentalCarDamage: false,
                    totalCollisionProtection: 0,
                    collisionProtection: false,
                    totalCancelForAnyReason: 0,
                    cancelForAnyReason: false,
                    totalAdventureSports: 0,
                    adventureSports: false,
                    medCoverage: false
                },
                flightAccidentLevel: '$300,000' // change this to load wth data from BE
            },
            travelInsured: {
                flightAccident: 0,
                cancelForWorkReason: 0,
                medicalUpgrade: 0,
                rentalCarDamage: 0,
                travelBenefitUpgrade: 0,
                primaryCoverageUpgrade: 0
            },
            isStudentSecure: hccConfig.isStudentSecure(),
            isExchangeGuard: hccConfig.isExchangeGuard(),
            isSHA: IMGConfig.isSHA(),
            isNationWidePlans: trawickConfig.isNationWidePlans(),
            isTripProtectPlans: trawickConfig.isTripProtectPlans(),
            isVoyagerPlans: trawickConfig.isVoyagerPlans(),
            isOutsideUSPlans: trawickConfig.isOutsideUSPlans(),
            isTravelexPlans: travelexConfig.isTravelex(),
            isSelectPlans: travelexConfig.isSelectPlan(),
            isTravelInsured: travelInsuredConfig.isTravelInsured(),
            planConfig: ibJS.planConfig || null,
            backEndPlan: ibJS.backEndPlan || null,
            renewable: ibJS.planConfig ? ibJS.planConfig.renewable : false,
            isTXFlightPlan: travelexConfig.isTXFlightPlan(),
            isTXFlightInsurePlus: travelexConfig.isFlightInsurePlus(),
            isTXFlightInsure: travelexConfig.isFlightInsure(),
            preSaveData: this.getPreSaveData,
            backEndPlan: ibJS?.backEndPlan,
            isPatriotTMPlans: IMGConfig.isPatriotTMPlans(),
            applicationFee: this.app?.planResult?.applicationFee,
            isExplorerPlans: trawickConfig.isExplorerPlans(),
            careington: {
                premium: 0,
                futureInstallment: 0,
                billingOpt: this.app?.careingtonBillingOpt,
                noOfMonths: this.app?.careingtonNumMonths
            },
            isExchangeGuardGrp: hccConfig.isExchangeGuardGrp(),
            maxTravelDelay: null,
            basicMaxTravelDelay: null,
            adnd24RiderValue: null,
            imgHideItemized: false
        }
    },
    created: function(){
        let i;
        var self = this;

        if(this.application.companyCode !== 'ARS') {
            if(self.application.fulfillmentType=='1'){
                this.isPatriotTMPlans ? self.shipCharge = 50 : self.shipCharge = 20;
            }else if(self.application.fulfillmentType=='4'){
                self.shipCharge = 30;
            }
        }

        if(self.application && self.application.planResult && self.application.planResult.showSurplusTaxMessage){
            self.surplusTax = true;
        }

        // TODO : Refactor this logic to make it more readable and inclusive for all the future products

        if(this.application.companyCode === 'WorldTrips'){

            if(!self.isExchangeGuardGrp) {
                for(i = 0; i<self.application.travelerInfos.length; i++){
                    var adndRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function(riderPrice){
                        return riderPrice.riderName == "AD&D Buy Up";
                    })[0];
                    var crisisRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function(riderPrice){
                        return riderPrice.riderName == "Crisis Response Buy up";
                    })[0];
                    var liabilityRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function(riderPrice){
                        return riderPrice.riderName == "Personal Liability Buy up";
                    })[0];
                    var sportsRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function(riderPrice){
                        return riderPrice.riderName == "Sports";
                    })[0];
                    var deviceProtectionPrice = self.application.travelerInfos[i].riderPrices?.filter(function(riderPrice){
                        return riderPrice.riderName == "Device Protection";
                    })[0];

                    var paymentFrequencyRiderKey = 'price';

                    if(this.isStudentSecure && this.app.appCompanyHccmis.scPaymentType === 2) paymentFrequencyRiderKey = 'monthlyPrice';


                    self.totalADNDPrice += adndRiderPrice ? adndRiderPrice[paymentFrequencyRiderKey] : 0;
                    self.totalCrisisPrice += crisisRiderPrice ? crisisRiderPrice[paymentFrequencyRiderKey] : 0;
                    self.totalLiabilityPrice += liabilityRiderPrice ? liabilityRiderPrice.price : 0;
                    self.totalSportsRiderPrice += sportsRiderPrice ? sportsRiderPrice[paymentFrequencyRiderKey] : 0;
                    self.totalDeviceProtect += deviceProtectionPrice ? deviceProtectionPrice.price : 0;

                    self.hccBundleDiscount+=self.application.travelerInfos[i].hccBundleDiscount;
                }
            } else {
                if(self.application.groupTravelersInfo && Array.isArray(self.application.groupTravelersInfo)) {
                    for (i = 0; i < self.application.groupTravelersInfo.length; i++) {
                        var travelersPrice = [];
                        travelersPrice = self.application.travelerInfos.filter(item => {
                            return item.ageGroup == self.application.groupTravelersInfo[i].ageGroup;
                        })
                        self.application.groupTravelersInfo[i].grpTravelerPrice = travelersPrice;
                    }
                }
            }
            
            if(self.application.riderList!=null){
                for(i = 0; i<self.application.riderList.length; i++){

                    if(self.application.riderList[i].riderName === 'AD&D Buy Up' && self.application.riderList[i].riderValue === 'yes'){
                        self.form.adndCoverage = self.totalADNDPrice;
                    }else if(self.application.riderList[i].riderName === 'Crisis Response Buy up' && self.application.riderList[i].riderValue === 'yes' ){
                        self.form.crisisCoverage = self.totalCrisisPrice;
                    }else if(self.application.riderList[i].riderName === 'Personal Liability Buy up' && self.application.riderList[i].riderValue === 'yes' ){
                        self.form.liabilityCoverage = self.totalLiabilityPrice;
                    }else if(self.application.riderList[i].riderName === 'Sports' && self.application.riderList[i].riderValue === 'yes' ){
                        self.form.sportsRiderCoverage = self.totalSportsRiderPrice;
                    }else if(self.application.riderList[i].riderName === 'Device Protection' && self.application.riderList[i].riderValue === 'yes' ){
                        self.form.deviceProtectCoverage = self.totalDeviceProtect;
                    }
                }
            }

            self.optionalCoveragesPrice = self.form.adndCoverage + self.form.crisisCoverage + self.form.liabilityCoverage + self.form.sportsRiderCoverage + self.form.deviceProtectCoverage;
        } else if (this.application.companyCode === 'ARS') {
            let totalExtremeSportsPrice = 0;

            self.application.travelerInfos.forEach(traveler => {
                if(traveler.arsExtremeSportsRider) {
                    totalExtremeSportsPrice+= traveler.arsExtremeSportsRiderPrice;
                }
            })

            self.form.extremeSportsCoverage = totalExtremeSportsPrice;
            self.optionalCoveragesPrice = totalExtremeSportsPrice;

            if(self.application.fulfillmentType=='2'){
                self.shipCharge=0;
            } else if (self.application.fulfillmentType=='1') {
                self.shipCharge=25; 
            } else if (self.application.fulfillmentType=='4') {
                self.shipCharge=35;
            } else {
                self.shipCharge=0;
            }

        } else if(this.application.companyCode === 'Trawick') {

            // total base price of all the travelers without any optional coverages

            var basePrice = 0;

            // calculating the base price first and storing it inside of basePrice variable

            for (i = 0; i < self.application.travelerInfos.length; i++) {
                if(self.application.planId == 506 || self.application.planId == 583) {
                    var totalBasePrice = self.plan?.travelersInfo[i]?.itemPrices?.filter(function (itemPrice) {
                        return itemPrice.policyMaximum == self.form.policyMaximum;
                    })[0] || { price: 0 };
                }
                else if(trawickConfig.isNationWidePlans()){
                    var totalBasePrice = {
                        price: self.plan?.travelersInfo[i].itemPrices[0].price
                    }
                }
                else{
                    var totalBasePrice = self.plan?.travelersInfo[i]?.itemPrices?.filter(function (itemPrice) {
                        return itemPrice.deductible == self.form.deductible && itemPrice.policyMaximum == self.form.policyMaximum;
                    })[0] || { price: 0 };
                }
                if (totalBasePrice) {
                    basePrice += totalBasePrice.price;
                }
            }
            
            // Resetting the values of the previous total cache since we need to compute the total again

            self.totalADNDPrice = 0;
            self.totalSportsPrice = 0;
            self.totalHomeCountryPrice = 0;
            self.totalAdndTripPrice = 0;
            self.totalRentalCarDamagePrice = 0;
            self.totalBaggageCoveragePrice = 0;
            self.totalCancelAnyReasonPrice = 0;
            self.totalPetAssistPrice = 0;
            self.totalADNDFlightPrice = 0;
            self.totalADND24Price = 0;
            self.totalTripDelayPrice = 0;

            let riderPercentage = 0;

            var sportsCoveragePrice = basePrice * 0.2;


            var followMeHomePrice = basePrice * 0.1;


            var cancelAnyReasonPrice = basePrice * 0.7;

            var sportsRiderClassName = self.application.riderList.filter(function (rider) {
                return rider.riderName === "Sports Coverage"
            });
            if(!trawickConfig.isTripProtectPlans()){
                var adndRiderClassName = self.application.riderList.filter(function (rider) {
                    return rider.riderName === "Additional AD&D Amount"
                });
            }
            else{
                var adndRiderClassName = self.application.riderList.filter(function (rider) {
                    return rider.riderName === "AD&D Amount"
                });
            }
            if(!trawickConfig.isTripProtectPlans()) {
                var adndTripRiderClassName = self.application.riderList.filter(function (rider) {
                    return rider.riderName === "AD&D Amount"
                });
            }
            var baggageRiderClassName = self.application.riderList.filter(function (rider) {
                return rider.riderName === "Baggage Coverage"
            });
            
            var tripDelayClassName = self.application.riderList.filter(function (rider) {
                return rider.riderName === "Trip Delay Max"
            });

            var extremSportTrek = self.application.riderList.filter(function (rider) {
                return rider.riderName === "Extreme Sports" && rider.riderValue == 'yes';
            });

            var mountaineeringTrek = self.application.riderList.filter(function (rider) {
                return rider.riderName === "Mountaineering" && rider.riderValue == 'yes';
            });

            for (i = 0; i < self.application.travelerInfos.length; i++) {

                // Get the rates of the riders for the riders selected

                if(!trawickConfig.isTripProtectPlans()) {
                    var adndRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function (riderPrice) {
                        return riderPrice.riderName === "Additional AD&D Amount" && riderPrice.riderLevel == adndRiderClassName[0]?.riderValue;
                    })[0];
                }
                else{
                    var adndRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function (riderPrice) {
                        return riderPrice.riderName === "AD&D Amount" && riderPrice.riderLevel == adndRiderClassName[0]?.riderValue;
                    })[0];
                }

               let adndFlightPrice =  this.application.travelerInfos[i].riderPrices?.filter(function (riderPrice) {
                        return riderPrice.riderName === "Flight Accident AD&D";
                    })[0];

               let adnd24 =  this.application.travelerInfos[i].riderPrices?.filter(function (riderPrice) {
                        return riderPrice.riderName === "24 Hour AD&D";
                    })[0];
               
               let tripDelay =  this.application.travelerInfos[i].riderPrices?.filter(function (riderPrice) {
                        return riderPrice.riderName === "Trip Delay Max" && riderPrice.riderLevel === tripDelayClassName[0]?.riderValue;
                    })[0];

               var athleticRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function (riderPrice) {
                   return riderPrice.riderName === "Sports Coverage" && riderPrice.riderLevel == sportsRiderClassName[0]?.riderValue;
               })[0];
                if(!trawickConfig.isTripProtectPlans() && self.application 
                    && self.application.travelerInfos[i] 
                    && self.application.travelerInfos[i].riderPrices 
                    && self.application.travelerInfos[i].riderPrices.length) {
                    var adndTripRiderPrice = self.application.travelerInfos[i].riderPrices.filter(function (riderPrice) {
                        return riderPrice.riderName === "AD&D Amount" && riderPrice.riderLevel == adndTripRiderClassName[0]?.riderValue;
                    })[0];
                }

                var rentalCarDamageRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function (riderPrice) {
                    return riderPrice.riderName === "Rental Car Damage";
                })[0];

                var baggageCoverageRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function (rider) {
                    return rider.riderName === "Baggage Coverage" && rider.riderLevel == baggageRiderClassName[0]?.riderValue;
                })[0];

                var petAssistRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function (rider) {
                    return rider.riderName === "Pet Assistance Coverage";
                })[0];

                if (extremSportTrek && extremSportTrek.length) {
                    var extremSportPrice = self.application.travelerInfos[i].riderPrices?.filter(function (rider) {
                        const isTrue = rider.riderName === "Extreme Sports";
                        if (isTrue) {
                            let riderFactor = rider.riderFactor;
                            if (riderFactor > 1) riderFactor -= 1;
                            riderPercentage = riderFactor;
                            self.totalExtremeSports = riderFactor;
                        }
                        return isTrue;
                    });
                }

                if (mountaineeringTrek && mountaineeringTrek.length) {
                    var mountaineeringPrice = self.application.travelerInfos[i].riderPrices?.filter(function (rider) {
                        const isTrue = rider.riderName === "Mountaineering"; 
                        if (isTrue) {
                            let riderFactor = rider.riderFactor;
                            if (riderFactor > 1) {
                                riderFactor -= 1;
                                riderPercentage = riderPercentage + riderFactor;
                            }
                            else riderPercentage += riderFactor;
                            self.totalMountaineering = riderFactor;
                        }
                        return isTrue;
                    });
                }



                self.totalADNDPrice += adndRiderPrice ? adndRiderPrice.price : 0;
                self.totalSportsPrice = sportsRiderClassName && sportsRiderClassName.length && sportsRiderClassName[0]?.riderValue ==='Class 2' ?
                                        (self.application.travelerInfos.length * athleticRiderPrice.price) + sportsCoveragePrice :
                                        sportsRiderClassName && sportsRiderClassName.length && sportsRiderClassName[0]?.riderValue ==='Class 1' ?
                                        sportsCoveragePrice : 0;
                self.totalHomeCountryPrice = followMeHomePrice;
                self.totalAdndTripPrice += adndTripRiderPrice ? adndTripRiderPrice.price : 0;
                self.totalRentalCarDamagePrice += rentalCarDamageRiderPrice ? rentalCarDamageRiderPrice.price : 0;
                self.totalBaggageCoveragePrice += baggageCoverageRiderPrice ? baggageCoverageRiderPrice.price : 0;
                self.totalCancelAnyReasonPrice = cancelAnyReasonPrice;
                self.totalPetAssistPrice += petAssistRiderPrice ? petAssistRiderPrice.price : 0;
                self.totalADNDFlightPrice += adndFlightPrice?.price || 0;
                self.totalADND24Price += adnd24?.price || 0;
                self.totalTripDelayPrice += tripDelay?.price || 0;

            }
            /*
                Setting up the riders that the user has selected in the previous step
            */
            if(self.application.riderList){
                for(i = 0; i<self.application.riderList.length; i++) {

                    var riderName = self.application.riderList[i].riderName;

                    var riderValue = self.application.riderList[i]?.riderValue;

                    if(!trawickConfig.isTripProtectPlans()) {
                        if (riderName === "Additional AD&D Amount" && riderValue !== 'None') {
                            self.form.adndCoverageTrawick = self.totalADNDPrice;
                        }
                    }
                    else{
                        if (riderName === "AD&D Amount" && riderValue !== 'None') {
                            self.form.adndCoverageTrawick = self.totalADNDPrice;
                        }
                    }

                    if (riderName === "Sports Coverage" && riderValue !== 'None') {
                        self.form.sportsCoverage = self.totalSportsPrice;
                    }

                    if (riderName === "Home Country/Follow me Home" && riderValue === "yes") {
                        self.form.followMeHomeCoverage = self.totalHomeCountryPrice;
                    }
                    if(!trawickConfig.isTripProtectPlans()) {
                        if (riderName === "AD&D Amount" && riderValue !== "None") {
                            self.form.adndTripCoverage = self.totalAdndTripPrice;
                        }
                    }

                    if (riderName === "Rental Car Damage" && riderValue === 'yes') {
                        self.form.rentalCarDamageCoverageDisplay = true;
                        self.form.rentalCarDamageCoverage = self.totalRentalCarDamagePrice
                    }

                    if (riderName === "Baggage Coverage" && riderValue !== 'None') {
                        self.form.baggageCoverage = self.totalBaggageCoveragePrice;
                    }

                    if (riderName === "Cancel For Any Reason" && riderValue === 'yes') {
                        if (trawickConfig.isVoyagerPlans() || self.application.planId === 727) {
                            self.form.cancelAnyReason = 1; // price is hidden with Voyager, we use fake number in order to show rider.
                        } else {
                            self.form.cancelAnyReason = self.totalCancelAnyReasonPrice;
                        }
                    }

                    if (riderName === "Pet Assistance Coverage" && riderValue === 'yes') {
                        self.form.petAssist = self.totalPetAssistPrice;
                    }
                    if (riderName === "Flight Accident AD&D" && riderValue !== 'None') {
                        self.form.adndFlight = self.totalADNDFlightPrice;
                    }
                    if (riderName === "24 Hour AD&D" && riderValue !== 'None') {
                        self.form.adnd24 = self.totalADND24Price;
                        if (self.application.planId === 689) self.adnd24RiderValue = +riderValue + 25000; 
                        else if (self.application.planId === 727) self.adnd24RiderValue = +riderValue;
                    }
                    const defaultTripDelayMax = self.application.planId === 689 ? '3000' : '2000';
                    if (riderName === "Trip Delay Max" && riderValue !== defaultTripDelayMax) {
                        self.form.tripDelay = self.totalTripDelayPrice;
                    }
                    if (riderName === "Enhanced Medical Upgrade" && riderValue !== 'no') {
                        self.form.enhancedMedicalUpgrade = true;
                    }
                    if (riderName === "Interruption For Any Reason" && riderValue !== 'no') {
                        self.form.interruptionForAnyReason = true;
                    }
                }
            }


            self.optionalCoveragesPrice = self.form.adndCoverageTrawick + self.form.sportsCoverage + self.form.followMeHomeCoverage
                                          + self.form.adndTripCoverage + self.form.rentalCarDamageCoverage + self.form.baggageCoverage + self.form.cancelAnyReason
                                          + self.form.petAssist + self.form.adndFlight + self.form.adnd24 + self.form.tripDelay + riderPercentage;

        }
        else if(this.application.companyCode === 'Seven Corners') {


            /*
             Setting up the riders that the user has selected in the previous step
            */
            if(self.application.riderList){
                var Rider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Hazardous Sport Coverage" && rider.riderValue == 'yes'
                });


            }

            // total base price of all the travelers without any optional coverages

            var basePrice = 0;

            // total price of the quote including all travellers and the optional coverages

            self.totalTravelersPrice = 0;

            // Resetting the values of the previous total cache since we need to compute the total again

            self.totalRiderPrice = 0;


            // calculating the base price first and storing it inside of basePrice variable

            for (i = 0; i < self.application.travelerInfos.length; i++) {
                if(self.application.travelerInfos[i].itemPrices){
                    var totalBasePrice = self.application.travelerInfos[i].itemPrices.filter(function (itemPrice) {
                        return itemPrice.deductible == self.form.deductible && itemPrice.policyMaximum == self.form.policyMaximum;
                    })[0];
                }

                if (totalBasePrice) {
                    basePrice += totalBasePrice.price;
                }
            }


            // calculating the optional coverages that are applied across the base price


            /*
              adding new logic to compute the prices for hazard sports since the toggle has the following logic
              - if yes then find the corresponding price that matches the following criteria : deductible 250 and policy max as selected by the user
              - this becomes the base price for calculation.
              - multiply the value by 0.15

              */

            var sportsCoveragePrice = 0;


            for (i = 0; i < self.application.travelerInfos.length; i++) {
                let totalPrice;
                if(self.application.travelerInfos[i].itemPrices) {
                    totalPrice = self.application.travelerInfos[i].itemPrices.filter(function (itemPrice) {
                        return itemPrice.deductible == self.form.deductible && itemPrice.policyMaximum == self.form.policyMaximum;
                    })[0];
                }

                // set the rider prices to every traveler if the rider is selected
                if (Rider && Rider.length > 0) {
                    
                    var travelLevelsportsCoverage  =  self.application.travelerInfos[0].itemPrices.filter(function (itemPrice){
                        return itemPrice.deductible == 250 && itemPrice.policyMaximum == self.form.policyMaximum;
                    });
                    var travelLevelsportsCoveragePrice = travelLevelsportsCoverage && travelLevelsportsCoverage.length ? travelLevelsportsCoverage[0].price : null;
                    if (travelLevelsportsCoveragePrice != null) {
                        travelLevelsportsCoveragePrice = travelLevelsportsCoveragePrice * 0.15;
                        self.application.travelerInfos[i].hazSportsRiderPrice = travelLevelsportsCoveragePrice;
                        sportsCoveragePrice += travelLevelsportsCoveragePrice;
                    }
                }
                else{
                    self.application.travelerInfos[i].hazSportsRiderPrice = 0;
                }

                self.form.hazSportsCoverage += self.application.travelerInfos[i].hazSportsRiderPrice ? self.application.travelerInfos[i].hazSportsRiderPrice : 0;



                var optionalCoveragePrice = self.application.travelerInfos[i].hazSportsRiderPrice;

                self.application.travelerInfos[i].totalPrice = (totalPrice ? totalPrice.price : 0) + optionalCoveragePrice;
                self.totalTravelersPrice += self.application.travelerInfos[i].totalPrice;
            }
            self.optionalCoveragesPrice = self.form.hazSportsCoverage;

            if(self.application.productType !== ''){
                if(self.application.productType =='Part A'){
                    this.greenCoverRiderLabel = window.qResultsLang.riderPartA;
                }else if(self.application.productType == "Part B"){
                    this.greenCoverRiderLabel = window.qResultsLang.riderPartB;
                }
                else{
                    this.greenCoverRiderLabel = window.qResultsLang.riderPartAB;
                }
            }
        }
        else if(this.imgMedical) {

            /*
             Setting up the riders that the user has selected in the previous step
            */
            if (self.application.riderList) {
                var adventureSportsRider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Adventure Sports" && rider.riderValue == 'yes'
                });
                var teladocRider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Telehealth" && rider.riderValue == 'yes'
                });
                var evacPlusRider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Evacuation Plus" && rider.riderValue == 'yes'
                });
                var deviceProtectRider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Device Protection" && rider.riderValue == 'yes'
                });
                var enhancedADNDRider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Enhanced AD&D" && rider.riderValue !== 'None'
                });
                var heartCarePlusRider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Heart Care Plus" && rider.riderValue == 'yes'
                });
                // todo: ask the backend to confirm how is the citizenship return rider going to be passed as a part of the rider DTO
                var citizenshipReturnRider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Citizenship Return" && rider.riderValue == 'yes'
                });
                var personalLiabilityRider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Personal Liability" && rider.riderValue == 'yes'
                });
                self.homeCountryRider = self.application.riderList.filter(function (rider) {
                    return rider.riderName == "Home Country Coverage" && rider.riderValue !== '0'
                });
            }


            // total base price of all the travelers without any optional coverages

            var basePrice = 0;

            // total price of the quote including all travellers and the optional coverages

            self.totalTravelersPrice = 0;

            // Resetting the values of the previous total cache since we need to compute the total again

            self.totalRiderPrice = 0;

            // Resetting the values of the previous total cache since we need to compute the total again

            self.totalAdventureSports = 0;
            self.totalTeladoc = 0;
            self.totalEvacPlus = 0;
            self.totalDeviceProtect = 0;
            self.totalADND = 0;
            self.totalHeartCarePlus = 0;
            self.totalCitizenshipReturn = 0;
            self.totalPersonalLiability = 0;



            // calculating the base price first and storing it inside of basePrice variable

            for (i = 0; i < self.application.travelerInfos.length; i++) {
                if (self.application.travelerInfos[i].itemPrices) {
                    var totalBasePrice = self.application.travelerInfos[i].itemPrices.filter(function (itemPrice) {
                        return itemPrice.deductible == self.application.deductible && itemPrice.policyMaximum == self.application.policyMaximum;
                    })[0];
                }

                if (totalBasePrice) {
                    basePrice += totalBasePrice.price;
                }
            }


            // calculating the optional coverages that are applied across the base price


            /*
            adding new logic to compute the prices for adventure sports since the toggle has the following logic
            - if yes then find then add 20% of the total price as the coverage charge
            - since this price is not uniformly spread the price needs to be calculated and applied to only those who qualify
            - find the extra price and divide it by the number of people eligible and apply the rate only to them
            */

            /*
            adding new logic to compute the prices for evacuation plus since since the selection has the following logic
            - if yes then find then get the total price of the rider for each traveler
            - not every traveler would have the rider so only apply to those who qualify
            - add the total of the rider price to the totalEvacPlusPrice
            */

            /*
            adding new logic to compute the prices for enhanced ADND since the selection has the following logic
            - find the extra price and apply that rate to the primary insured traveler
            */

            /*
            adding new logic to compute the prices for Heart Care Plus since the toggle has the following logic
            - if yes then find then add 29% of the total price as the coverage charge
            - since this price is not uniformly spread the price needs to be calculated and applied to only those who qualify
            - find the extra price and divide it by the number of people eligible and apply the rate only to them
            */


            var sportsCoveragePrice = 0;
            var evacPrice = 0;
            var ADNDprice = 0;
            var heartCarePlusPrice = 0;
            var personalLiabilityPrice = 0;
            var citizenshipReturnPrice = 0;
            var teladocPrice = 0;
            var totalPrice = 0;
            var nonRoundedTotalPrice = 0;

            for (i = 0; i < self.application.travelerInfos.length; i++) {
                if(self.application.planId === 406){
                    if(self.application.travelerInfos[i].itemPrices && self.application.deductible != undefined && self.application.deductible != '') {
                        nonRoundedTotalPrice = self.application.travelerInfos[i].itemPrices?.filter(function (itemPrice) {
                            return itemPrice.deductible == self.application.deductible && itemPrice.policyMaximum == self.application.policyMaximum && itemPrice.planType == self.application.appCompanyImg.pepPlanType;
                        })?.[0]?.price || 0;   
                        totalPrice = parseFloat(nonRoundedTotalPrice.toFixed(2));
                    }
                }else{
                    if(self.application.travelerInfos[i].itemPrices && self.application.deductible != undefined && self.application.deductible != '') {
                        nonRoundedTotalPrice = self.application.travelerInfos[i].itemPrices?.filter(function (itemPrice) {
                            return itemPrice.deductible == self.application.deductible && itemPrice.policyMaximum == self.application.policyMaximum;
                        })?.[0]?.price || 0;
                        totalPrice = parseFloat(nonRoundedTotalPrice.toFixed(2));
                    }
                }


                if (adventureSportsRider.length > 0) {
                    var numOfEligibleTravelersForAdventureSports = 0;
                    for(var j=0; j<self.application.travelerInfos.length; j++){
                        numOfEligibleTravelersForAdventureSports += self.application.travelerInfos[j].riderPrices?.filter(function (rider) {
                            return rider.riderName == "Adventure Sports"
                        }).length;
                    }

                    if (self.application.travelerInfos[i].riderPrices?.filter(function (rider) {
                        return rider.riderName == "Adventure Sports"
                    }).length > 0) {
                        let thisPrice = totalPrice * 0.2;
                        self.application.travelerInfos[i].sportsCoveragePrice = parseFloat(thisPrice.toFixed(2));
                        sportsCoveragePrice += self.application.travelerInfos[i].sportsCoveragePrice;
                    }
                } else {
                    self.application.travelerInfos[i].sportsCoveragePrice = 0;
                }

                if (evacPlusRider.length > 0) {
                    // since we only need the total price for all the evac plus the traveler DTO will be looped through for the evacPrice and will be added to the totalEvacPrice
                    var travelerlLevelEvacPrice = 0;
                    var numOfEligibleEvacTravelers = 0;

                    for(var j = 0; j < self.application.travelerInfos.length; j++){
                        if(self.application.travelerInfos[j].riderPrices?.filter(function (rider) {
                            return rider.riderName == "Evacuation Plus"
                        }).length > 0){
                            travelerlLevelEvacPrice =  self.application.travelerInfos[j].riderPrices?.filter(function (rider) {
                                return rider.riderName == "Evacuation Plus"
                            })[0].price;
                            break;
                        }
                    }

                    // find the number of eligible travelers separately since putting them in the same loop was breaking the browser

                    for(var t in self.application.travelerInfos){
                        var travelerList =  self.application.travelerInfos.filter(function (rider) {
                            return rider.imgEvacPlusRider == 'yes';
                        });
                        numOfEligibleEvacTravelers =  travelerList.length;
                    }
                    self.totalEvacPlus = travelerlLevelEvacPrice * numOfEligibleEvacTravelers;
                }

                if (deviceProtectRider.length > 0) {
                    // since we only need the total price for all the evac plus the traveler DTO will be looped through for the evacPrice and will be added to the totalEvacPrice
                    var travelerlLevelDevicePrice = 0;
                    var numOfEligibleDeviceTravelers = 0;

                    for(var j = 0; j < self.application.travelerInfos.length; j++){
                        if(self.application.travelerInfos[j].riderPrices?.filter(function (rider) {
                            return rider.riderName == "Device Protection"
                        }).length > 0){
                            travelerlLevelDevicePrice =  self.application.travelerInfos[j].riderPrices?.filter(function (rider) {
                                return rider.riderName == "Device Protection"
                            })[0].price;
                            break;
                        }
                    }

                    // find the number of eligible travelers separately since putting them in the same loop was breaking the browser

                    for(var t in self.application.travelerInfos){
                        var travelerList =  self.application.travelerInfos.filter(function (rider) {
                            return rider.imgDeviceProtectionRider == 'yes';
                        });
                        numOfEligibleDeviceTravelers =  travelerList.length;
                    }
                    self.totalDeviceProtect = travelerlLevelDevicePrice * numOfEligibleDeviceTravelers;
                }

                if (teladocRider.length > 0) {
                    
                    var teladocRiderPrice =  self.application.travelerInfos[i].riderPrices?.filter(function (rider) {
                        return rider.riderName == "Telehealth"
                    });
                    if(teladocRiderPrice.length) {
                        self.application.travelerInfos[i].teladocPrice = parseFloat(teladocRiderPrice[0].price.toFixed(2));
                        teladocPrice += self.application.travelerInfos[i].teladocPrice;

                    }
                }
                else {
                    self.application.travelerInfos[0].teladocPrice = 0;
                }

                if (enhancedADNDRider.length > 0) {

                    var adndValueFromBackend = self.application.riderList.filter(function(rider){
                        return rider.riderName === "Enhanced AD&D"
                    });

                    if(adndValueFromBackend.length > 0) adndValueFromBackend =  adndValueFromBackend[0].riderValue;

                    var adndPrice = self.application.travelerInfos[0].riderPrices?.filter(function (rider) {
                        return rider.riderLevel === adndValueFromBackend;
                    });

                    if (adndPrice.length > 0) {
                        self.application.travelerInfos[0].adndprice = adndPrice[0].price;
                        ADNDprice = adndPrice[0].price;
                    }
                }
                else {
                    self.application.travelerInfos[0].adndprice = 0;
                }

                if (heartCarePlusRider.length > 0) {
                    heartCarePlusPrice = basePrice * 0.29;

                    var numOfEligibleTravelersForHeartCarePlus = 0;
                    for (var j=0; j<self.application.travelerInfos.length;j++){
                        if (self.application.travelerInfos[j].riderPrices) {
                            numOfEligibleTravelersForHeartCarePlus += self.application.travelerInfos[j].riderPrices.filter(function (rider) {
                                return rider.riderName == "Heart Care Plus"
                            }).length;
                        }
                    }
                    if (self.application.travelerInfos[i].riderPrices) {
                        if (self.application.travelerInfos[i].riderPrices.filter(function (rider) {
                            return rider.riderName == "Heart Care Plus"
                        }).length > 0) {
                            self.application.travelerInfos[i].heartCarePrice = heartCarePlusPrice / numOfEligibleTravelersForHeartCarePlus;
                        }
                    }
                } else {
                    self.application.travelerInfos[i].heartCarePrice = 0;
                }

                if (personalLiabilityRider.length > 0) {

                    personalLiabilityPrice = basePrice * 0.10;

                    var numOfEligibleTravelersForPersonalLiability = 0;
                    for (var j=0; j<self.application.travelerInfos.length;j++){
                        if (self.application.travelerInfos[j].riderPrices) {
                            numOfEligibleTravelersForPersonalLiability += self.application.travelerInfos[j].riderPrices.filter(function (rider) {
                                return rider.riderName == "Personal Liability"
                            }).length;
                        }
                    }
                    if (self.application.travelerInfos[i].riderPrices) {
                        if (self.application.travelerInfos[i].riderPrices.filter(function (rider) {
                            return rider.riderName == "Personal Liability"
                        }).length > 0) {
                            self.application.travelerInfos[i].personalLiabilityPrice = personalLiabilityPrice / numOfEligibleTravelersForPersonalLiability;
                        }
                    }
                } else {
                    self.application.travelerInfos[i].personalLiabilityPrice = 0;
                }

                if (citizenshipReturnRider.length > 0) {
                    citizenshipReturnPrice = basePrice * 0.05;
                }

                self.totalAdventureSports = sportsCoveragePrice;
                self.totalTeladoc = teladocPrice;
                self.totalADND = ADNDprice;
                self.totalHeartCarePlus = heartCarePlusPrice;
                self.totalCitizenshipReturn = citizenshipReturnPrice;
                self.totalPersonalLiability = personalLiabilityPrice;


                var optionalCoveragePrice = 0;
                if (self.application.travelerInfos[i].evacPrice) {
                    optionalCoveragePrice += self.application.travelerInfos[i].evacPrice;
                }
                if (self.application.travelerInfos[i].devicePrice) {
                    optionalCoveragePrice += self.application.travelerInfos[i].devicePrice;
                }
                if (self.application.travelerInfos[i].sportsCoveragePrice) {
                    optionalCoveragePrice += self.application.travelerInfos[i].sportsCoveragePrice;
                }
                if (self.application.travelerInfos[i].adndprice) {
                    optionalCoveragePrice += self.application.travelerInfos[i].adndprice;
                }
                if (self.application.travelerInfos[i].heartCarePrice) {
                    optionalCoveragePrice += self.application.travelerInfos[i].heartCarePrice;
                }
                if (self.application.travelerInfos[i].teladocPrice) {
                    optionalCoveragePrice += self.application.travelerInfos[i].teladocPrice;
                }
                if (self.application.travelerInfos[i].personalLiabilityPrice) {
                    optionalCoveragePrice += self.application.travelerInfos[i].personalLiabilityPrice;
                }
                self.application.travelerInfos[i].totalPrice = (totalPrice > 0 ? totalPrice : 0) + optionalCoveragePrice;
                self.totalTravelersPrice += self.application.travelerInfos[i].totalPrice;

            }
            self.optionalCoveragesPrice =  self.totalAdventureSports + self.totalTeladoc +  self.totalEvacPlus + self.totalDeviceProtect + self.totalADND +  self.totalHeartCarePlus + self.totalCitizenshipReturn + self.totalPersonalLiability;


            if(this.app.appCompanyImg && this.app.appCompanyImg.pepPlanType === 2) {
                this.getAddOnDifference();
            } else {
                this.totalAddOnPrice = 0;
            }

            if ([imgPlanIds.Patriot_America_Lite,
                imgPlanIds.Patriot_America_Platinum,
                imgPlanIds.Patriot_America_Plus,
                imgPlanIds.Patriot_International_Lite,
                imgPlanIds.Patriot_International_Platinum].indexOf(this.plan.planId) > -1 && (this.app.totalPrice <= 10 || this.app.totalPrice <= '10.00')) {
                    this.imgHideItemized = true;
                } else {
                    this.imgHideItemized = false;
                }
        }
        else if(this.application.companyCode === 'GU') {

            if(self.application.riderList!=null){
                for(i = 0; i<self.application.riderList.length; i++){

                    if(self.application.riderList[i].riderName === "Additional AD&D Amount" && self.application.riderList[i].riderValue !== 'None'){
                        self.gu.aDnDbenifit = true;
                    }else if(self.application.riderList[i].riderName === "Sports Coverage Option" && self.application.riderList[i].riderValue !== 'no' ){
                        self.gu.athleticSports = true;
                    }else if(self.application.riderList[i].riderName === "Home Country Coverage" && self.application.riderList[i].riderValue === 'yes' ){
                        self.gu.homeCountryCoverage = true;
                    }else if(self.application.riderList[i].riderName === "War Risk Coverage" && self.application.riderList[i].riderValue !== 'no' ){
                        self.gu.warCoverage = true;
                    }
                }
            }
            self.optionalCoveragesPrice = self.gu.aDnDbenifit || self.gu.athleticSports || self.gu.homeCountryCoverage || self.gu.warCoverage;
        }
        else if(self.imgTripPlan) {
            if(self.application.planId == 682 && this.hasRider("Car Rental")) {
                self.iTI.carRental = true;
                for(let j = 0 ; j < self.application.travelerInfos.length ; j++) {
                    /** @type {RiderPrice[]} */
                    let riderPrices = self.application.travelerInfos[j].riderPrices;
                    let riderIndex = self.getRiderIndex("Car Rental", riderPrices);
                    self.iTI.carRentalPrice += riderPrices[riderIndex].price;
                }
            }

            if(imgPlanIds.Travel_LX === self.application.planId || imgPlanIds.Travel_SE === self.application.planId) {
                self.iTI.teladoc = true;
                for(let j = 0 ; j < self.application.travelerInfos.length ; j++) {
                    /** @type {RiderPrice[]} */
                    let riderPrices = 2;;
                    self.iTI.teladocPrice += riderPrices;;
                }
            }

            if (self.application.planId == 676 && this.hasRider("Cancel For Any Reason")) {
                self.iTI.cancelAnyReason = true;
                let riderPrice = 0;
                this.totalPrice = 0;
                for(let x = 0 ; x < self.application.travelerInfos.length ; x++) {
                    let riderPercentage = 0;
                    self.plan.travelersInfo[x].riderPrices?.filter(rider => {
                        if (rider.riderName === 'Cancel For Any Reason') {
                            riderPercentage = rider.riderFactor ? rider.riderFactor : 0;
                        }
                    })

                    let totalItemPrice = 0;
                    const itemBasePrice = self.plan.travelersInfo[x].itemPrices[0]?.price || 0;
                    if(self.plan.travelersInfo[x].itemPrices.length) {
                        totalItemPrice = Math.round( itemBasePrice * 100 ) / 100;
                    }
                    
                    riderPrice = riderPercentage * totalItemPrice;
                    self.iTI.cancelAnyReasonPrice += riderPrice - totalItemPrice;
                    
                    self.plan.travelersInfo[x].totalPrice = riderPrice;
                    self.iTI.totalPrice += self.plan.travelersInfo[x].totalPrice;
                }
            }
        }
        else if (self.application.companyCode === 'Travelex' && self.application.planId != '671' && self.application.planId != '672') {
            if (this.application.policyMaximum == 100000) {
                this.travelex.medCoveragePrice = 0;
                if(this.isSelectPlans && ibJS.application.travelerInfos && Array.isArray(ibJS.application.travelerInfos)) {
                    let upgradePrice;
                    let regularPrice;
                    for (let i = 0; i < ibJS.application.travelerInfos.length; i++) {
                        if (!ibJS.application.travelerInfos[i].freeChild) {
                            upgradePrice = ibJS.application.travelerInfos[i].itemPrices?.filter((item) => {
                                return item.policyMaximum == 100000
                            })[0];
                            regularPrice = ibJS.application.travelerInfos[i].itemPrices?.filter((item) => {
                                return item.policyMaximum == 50000
                            })[0];
                            if (this.application.policyMaximum == '100000' && upgradePrice && regularPrice) {
                                this.travelex.medCoveragePrice += (upgradePrice.price - regularPrice.price);
                            } else {
                                this.travelex.medCoveragePrice = 0;
                            }
                        }
                    }
                }
            }

            for (let i = 0; i < self.application.travelerInfos.length; i++) {
                const adndRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function(riderPrice){
                    return riderPrice.riderName === 'AD&D'
                })[0];
                // reset AD&D rider price to 0 if traveler gets freeChild flag
                if (adndRiderPrice && self.application.travelerInfos[i].freeChild) {
                    adndRiderPrice.price = 0;
                }
                
                const collisionProtectionRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function(riderPrice){
                    return riderPrice.riderName === 'Rental Vehicle Damage'
                })[0];

                const adventureSportsRiderPrice = self.application.travelerInfos[i].riderPrices?.filter(function (riderPrice) {
                    return riderPrice.riderName === 'Adventure Sports';
                })[0];
                // reset adventureSports rider price to 0 if traveler gets freeChild flag
                if (self.application.travelerInfos[i].freeChild && adventureSportsRiderPrice) {
                    adventureSportsRiderPrice.price = 0;
                }

                self.travelex.totalADND += adndRiderPrice ? adndRiderPrice.price : 0;
                if(self.isSelectPlans) {
                    let riderPercentage = 0;
                    self.plan.travelersInfo[i].riderPrices?.filter(rider => {
                        if (rider.riderName === 'Cancel For Any Reason') {
                            riderPercentage = rider.riderFactor ? rider.riderFactor : 0;
                        }
                    })
                    const totalBasePriceTravelex = self.application.travelerInfos[i].itemPrices?.filter(itemPrice => {
                        return itemPrice.policyMaximum == 50000;
                    })[0].price;
                    self.travelex.totalCancelForAnyReason += totalBasePriceTravelex * riderPercentage;
                    self.travelex.totalAdventureSports += adventureSportsRiderPrice ? adventureSportsRiderPrice.price : 0;
                }
            }
            
            if(self.application.riderList != null){
                for(let j = 0; j<self.application.riderList.length; j++){
                    if(self.application.riderList[j].riderName === 'AD&D' && self.application.riderList[j].riderValue === 'yes'){
                        self.travelex.ridersToShow.totalADND =  self.travelex.totalADND;
                        self.travelex.ridersToShow.adnd =  true;
                    } else if (self.application.riderList[j].riderName === 'Rental Vehicle Damage' && self.application.riderList[j].riderValue === 'yes' ){
                        if(self.application.planResult.travelexRentalDurationDays > 0) {
                            let dailyCollisionProtectionRate = 0;
                            if(self.application.travelerInfos) {
                                for(let i = 0; i < self.application.travelerInfos.length; i++) {
                                    dailyCollisionProtectionRate += self.application.travelerInfos[i].riderPrices?.filter((rider) => {
                                        return rider.riderName === 'Rental Vehicle Damage';
                                    })[0].price;
                                }
                            }
                            self.travelex.totalCollisionProtection = self.application.planResult.travelexRentalDurationDays * dailyCollisionProtectionRate;
                            self.travelex.ridersToShow.totalCollisionProtection = self.travelex.totalCollisionProtection;
                            self.travelex.ridersToShow.collisionProtection = true;
                        }
                    } else if (self.application.riderList[j].riderName === 'Cancel For Any Reason' && self.application.riderList[j].riderValue === 'yes' ){
                        self.travelex.ridersToShow.totalCancelForAnyReason = self.travelex.totalCancelForAnyReason;
                        self.travelex.ridersToShow.cancelForAnyReason = true;
                    } else if (self.application.riderList[j].riderName === 'Emergency Medical Upgrade' && self.application.riderList[j].riderValue === 'yes' ){
                        self.travelex.ridersToShow.medCoverage = true;
                    } else if (self.application.riderList[j].riderName === 'Adventure Sports' && self.application.riderList[j].riderValue === 'yes' ){
                        self.travelex.ridersToShow.totalAdventureSports = self.travelex.totalAdventureSports;
                        self.travelex.ridersToShow.adventureSports = true;
                    }
                }
            }

            self.optionalCoveragesPrice = self.travelex.ridersToShow.totalADND
                                          + self.travelex.ridersToShow.totalCollisionProtection
                                          + self.travelex.ridersToShow.totalCancelForAnyReason
                                          + self.travelex.ridersToShow.totalAdventureSports
                                          + self.travelex.ridersToShow.medCoverage;
        }
        else if (self.application.companyCode === 'TravelInsured') {
            this.optionalCoveragesPrice = 0;

            if (this.hasRider('Flight Accident', { checkForNumbers: true })) {
                this.travelInsured.flightAccident = this.getRiderTotalPrice('Flight Accident', { riderLevel: this.getRiderValue('Flight Accident') });
                this.optionalCoveragesPrice += this.travelInsured.flightAccident
            }
            if (this.hasRider('Cancel for Work Reasons')) {
                this.travelInsured.cancelForWorkReason = this.getRiderTotalPrice('Cancel for Work Reasons');
                this.optionalCoveragesPrice += this.travelInsured.cancelForWorkReason
            }
            if (this.hasRider('Medical Upgrade')) {
                this.travelInsured.medicalUpgrade = this.getRiderTotalPrice('Medical Upgrade');
                this.optionalCoveragesPrice += this.travelInsured.medicalUpgrade
            }
            if (this.hasRider('Rental Car Damage')) {
                this.travelInsured.rentalCarDamage = this.getRiderTotalPrice_carRental();
                this.optionalCoveragesPrice += this.travelInsured.rentalCarDamage
            }
            if (this.hasRider('Travel Benefits Upgrade')) {
                this.travelInsured.travelBenefitUpgrade = this.getRiderTotalPrice('Travel Benefits Upgrade');
                this.optionalCoveragesPrice += this.travelInsured.travelBenefitUpgrade
            }
            if (this.hasRider('Primary Coverage Upgrade')) {
                this.travelInsured.primaryCoverageUpgrade = this.getRiderTotalPrice('Primary Coverage Upgrade');
                this.optionalCoveragesPrice += this.travelInsured.primaryCoverageUpgrade
            }

            this.shipCharge = 0;
        }
        else if(this.isCareington) {
            var self = this;

            if(self.application.travelerInfos && Array.isArray(self.application.travelerInfos) && self.application.travelerInfos.length) {
                var billingPrice = '';

                if(self.application.travelerInfos[0].itemPrices){
                    if(this.careington.billingOpt == '1') {
                        billingPrice = self.application.travelerInfos[0].itemPrices[0].monthlyPrice;
                    } else {
                        billingPrice = self.application.travelerInfos[0].itemPrices[0].yearlyPrice;
                    }
                }

                var totalPrice = {price : billingPrice};
                self.application.travelerInfos[0].totalPrice = (totalPrice ? totalPrice.price : 0);
                self.careington.futureInstallment = self.application.travelerInfos[0].totalPrice;
                var installment = self.careington.futureInstallment;
                self.careington.premium = self.careington.billingOpt === 1 && self.careington.noOfMonths !== -1 ? (installment)*self.careington.noOfMonths : installment;

            }
        }
        
        if (this.application.companyCode === 'Trawick') {
            if (this.isNationWidePlans || this.isTripProtectPlans || this.isExplorerPlans || this.isOutsideUSPlans || this.isVoyagerPlans) {
                this.basicMaxTravelDelay = ibJS.planConfig?.maxTravelDelay || null;
            }
            
            if (ibJS.application.riderList) {
                ibJS.application.riderList.forEach(rider => {
                    if (rider.riderName === 'Trip Delay Max') {
                        this.maxTravelDelay = rider.riderValue == this.basicMaxTravelDelay ? null : rider.riderValue;
                    }
                })
            }
        } else {
            let otherPlanIds = [362,676,361,681,675,662,666];
            if (otherPlanIds.indexOf(this.application.planId) > -1) {
                this.basicMaxTravelDelay = ibJS.planConfig?.maxTravelDelay || null;
            }
        }
    },
    watch:{
        shippingCharges:function(){
            this.shipCharge=this.shippingCharges ? this.shippingCharges : '';
        }
    },
    computed: {
        coverageDates: function() {
            //If Monthly Payments
            if(this.shouldRoundMonth()) {
                var months = this.plan.calcMonths > 0 ? this.plan.calcMonths + 1 : this.plan.scMonths;
                var arr = this.plan.calcMonths === 0 ? window.qResultsLang.months : this.plan.quotedDuration.split(',')[0].split(' ');
                if(Array.isArray(arr)){
                    arr[0] = months;
                    return months > 1 ? months+" "+qResultsLang.months : months+" "+qResultsLang.month;
                    //return arr.join(' ');
                }
               else{
                  return months + " " + arr;
                }
            }
            else if(this.isStudentSecure && +this.plan.scMonths === 1){
                return this.plan.calcDays + ' ' + window.qResultsLang.days;
            }
            //One Time Payments
            return this.plan?.quotedDuration || null;
        },
        remainingMonthsSC: function(){
            if(this.isStudentSecure) {
                var months = +(this.coverageDates.split(' ')[0]) - 1;
                if(window.globalLanguage.lang === 'es' && months === 1) {
                    return " "+window.qResultsLang.monthSM;
                }
                return months + " " + (months === 1 ? window.qResultsLang.monthSM : window.qResultsLang.monthsSM);
            }
            return "0";
        },
        remainingMonthsSHA: function(){
            var months = 0;
            if(this.shouldRoundMonth())months = this.plan.calcMonths;
            else months = this.plan.calcMonths - 1;

            if(window.globalLanguage.lang === 'es' && months === 1) {
                return " "+window.qResultsLang.monthSM;
            }

            return months + " " + (months === 1 ? window.qResultsLang.monthSM : window.qResultsLang.monthsSM);
        },
        showGrandTotal: function () {
          return this.isStudentSecureMonthly || this.isSHAMonthly;
        },
        totalTextAmount: function () {
                if(this.app.appCompanyImg) return (this.application.totalPrice * this.application.planResult.shaMonths ) + this.shipCharge;
                return (this.application.totalPrice * this.application.planResult.scMonths) + this.shipCharge ;
        },
        isStudentSecureMonthly: function () {
            return this.isStudentSecure && +this.application.appCompanyHccmis.scPaymentType === 2;
        },
        isSHAMonthly: function () {
            return this.isSHA  && +this.application.appCompanyImg.shaPaymentType === 2;
        },
        isCareington: function () {
            return this.application.companyCode === 'Careington';
        },
        careingtonTotalPrice: function() {
            return this.careington.premium + this.applicationFee;
        },
        careingtonTotalDue: function() {
            return this.careington.futureInstallment + this.applicationFee;
        },
        isCareingtonMonthly: function() {
            return this.isCareington && this.careington.billingOpt === 1 && (this.careington.noOfMonths !== 1 && this.careington.noOfMonths !== -1);
        },
        isCareingtonIndefinite: function() {
            return this.isCareington && this.careington.billingOpt === 1 && this.careington.noOfMonths === -1;
        },
        isCareingtonAnnual: function() {
            return this.isCareington && this.careington.billingOpt === 2;
        },
        remainingMonthsCNT: function() {
            let months = this.careington.noOfMonths - 1;
            return months + " " + (months === 1 ? window.qResultsLang.monthSM : window.qResultsLang.monthsSM);
        },
        showNationWideText() {
            switch (this.application.planId) {
                case 634:
                    return `<li class="c-ibApp__aside-item">Medical Expense <span>$10,000</span></li><li class="c-ibApp__aside-item">Trip Cancellation <span>100% of Trip Cost</span></li><li class="c-ibApp__aside-item">Trip Interruption <span>100% of Trip Cost</span></li>`;
                case 635:
                case 756:
                case 757:
                    return `<li class="c-ibApp__aside-item">Medical Expense <span>$75,000</span></li><li class="c-ibApp__aside-item">Trip Cancellation <span>100% of Trip Cost</span></li><li class="c-ibApp__aside-item">Trip Interruption <span>150% of Trip Cost</span></li>`;
                case 636:
                case 728:
                case 729:
                    return `<li class="c-ibApp__aside-item">Medical Expense <span>$150,000</span></li><li class="c-ibApp__aside-item">Trip Cancellation <span>100% of Trip Cost</span></li><li class="c-ibApp__aside-item">Trip Interruption <span>150% of Trip Cost</span></li>`;
                case 686:
                    return `<li class="c-ibApp__aside-item">Medical Expense <span>$100,000</span></li><li class="c-ibApp__aside-item">Trip Cancellation <span>100% of Trip Cost</span></li><li class="c-ibApp__aside-item">Trip Interruption <span>100% of Trip Cost</span></li>`;
                case 687:
                    return `<li class="c-ibApp__aside-item">Medical Expense <span>$100,000</span></li><li class="c-ibApp__aside-item">Trip Cancellation <span>100% of Trip Cost</span></li><li class="c-ibApp__aside-item">Trip Interruption <span>100% of Trip Cost</span></li>`;
                case 724:
                    return `<li class="c-ibApp__aside-item">Medical Expense <span>$100,000</span></li><li class="c-ibApp__aside-item">Trip Cancellation <span>100% of Trip Cost</span></li><li class="c-ibApp__aside-item">Trip Interruption <span>125% of Trip Cost</span></li>`;
                default:
                    return '';
            }
        },
        showDependentPolicyMaxElements() {
            let isSHA = [532, 546].indexOf(this.application.planId) !== -1;

            if(isSHA && this.application.travelerInfos.length > 1) {
                return true;
            } else {
                return false;
            }

        },
        showPerPersonPrice() {
            if (this.isTravelInsured) {
                let hasTripCostOver = this.hasTripCostOver(25000);
    
                return !hasTripCostOver;
            } else {
                return true;
            }
        },
        showRiderPrices() {
            if (this.isTravelInsured) {
                let hasTripCostOver = this.hasTripCostOver(25000);
    
                return !hasTripCostOver;
            } else {
                return true;
            }
        },
        stTripCancellationPlans () {
            return this.isNationWidePlans || this.isTripProtectPlans || this.isVoyagerPlans || this.isExplorerPlans;
        },
        showItemized () {
            let show = [689,725,726,727].indexOf(this.application.planId) === -1 ? true : false;
            return show;
        }
    },
    methods: {
        hasTripCostOver(price, travelerInfos) {
            if (price === null || price === undefined) return false;
            travelerInfos = travelerInfos || ibJS.application.travelerInfos;

            return travelerInfos?.filter?.(traveler => traveler.tripCost >= price)?.length > 0;
        },
        showPaymentOptions() {
            if (this.plan.planId) {
                this.$emit('payment-options', this.plan.planId, this.renewable);
            }
        },
        showModal(url) {
            global.eventHub.$emit('show-modal', url);
        },
        shouldRoundMonth: function(){
            if(this.app.appCompanyImg) return this.app.appCompanyImg.shaPaymentType === 2 && this.app.planResult.calcDays > 0;
            if(this.app.appCompanyHccmis)return this.app.appCompanyHccmis.scPaymentType === 2;
            return false;
        },
        showStudentSecureOutsideDeductible() {
            if(this.isStudentSecure && this.planConfig.dedOutsidePPO) {
                return " / " + this.planConfig.dedOutsidePPO;
            }
        },
        showDeductibleText: function(option) {
            if(this.isExchangeGuard) return option === '100' ? qResultsLang.perIncident : qResultsLang.perCertPeriod;
        },
        getAddOnDifference() {
            var self = this;

            //calculate addOn
            var prices = {
                withAddOn: 0,
                withoutAddOn: 0
            };

            Object.keys(prices).forEach(function(key) {
                var nonRoundedTotalPrice;
                var addOnValue = key === 'withAddOn' ? '2' : '1';
                var plan = self.app.planResult;

                for(var i = 0; i < self.app.travelerInfos.length; i++) {
                    if(plan.travelersInfo[i].itemPrices && self.form.deductible !== undefined && self.form.deductible !== '') {
                        var nonRoundedTotalPriceList = plan.travelersInfo[i].itemPrices.filter(function (itemPrice) {
                            return itemPrice.deductible == self.form.deductible && itemPrice.policyMaximum == self.form.policyMaximum && itemPrice.planType == addOnValue;
                        });
                        if(nonRoundedTotalPriceList.length) {
                            nonRoundedTotalPrice = nonRoundedTotalPriceList[0].price;
                        }

                        prices[key] = parseFloat(nonRoundedTotalPrice.toFixed(2));
                    }
                }

            });

            this.totalAddOnPrice = parseFloat((prices.withAddOn - prices.withoutAddOn).toFixed(2));
        },
        goToFirstStep: function () {
            if (this.app.currentStep == 4 || this.app.currentStep == 5) {
                return 0;
            }
            const preSaveData = this.preSaveData && typeof this.preSaveData === 'function' ? this.preSaveData() : null;
            saveApplication(this.$http, preSaveData, function () {
                window.location.href = window.ibJS.purchaseLink;
            }, null, 'edit1-'+this.app.currentStep);

        },
        goToSecondStep: function () {
            if (this.app.currentStep == 4 || this.app.currentStep == 5) return 0;
            const preSaveData = this.preSaveData && typeof this.preSaveData === 'function' ? this.preSaveData() : null;
            saveApplication(this.$http, preSaveData, function () {
                window.location.href = "/" + window.ibJS.productName + window.qResultsLang.step1;
            }, null, 'edit2-'+this.app.currentStep);
        },
        childDisplay: function (i) {
            var x = this.application.travelerInfos;
            var otherCount = 0;
            for(var j=0; j<x.length; j++) {
                if(x[j] && x[j].travelerType === 'other') {
                    otherCount++;
                }
            }

            return x[1] && x[1].travelerType === 'spouse' && x.length - otherCount > 3 ? i - 1
                : x[1] && x[1].travelerType !== 'spouse' && x.length-otherCount > 2 ? i
                    : '';

        },
        otherDisplay: function (i) {
            var x = this.application.travelerInfos;
            var childCount = 0;
            var otherCount = 0;
            this.application.travelerInfos.forEach(function(x) {
                if(x.travelerType === 'child'){
                    childCount++;
                }
                else if(x.travelerType === 'other') {
                    otherCount++;
                }
            });
            var displayIndex = x[1] && x[1].travelerType === 'spouse' && x.length > 3 ? i - 1
                : x[1] && x[1].travelerType !== 'spouse' && x.length > 2 ? i
                    : '';

            return otherCount > 1 ? displayIndex - childCount : '';
        },
        /**
         * @param {string} riderName
         */
        hasRider(riderName, options = {}) {
            if (!riderName) return false;
            let hasRider = false;

            /** @type {Rider[]} */
            let riders = this.application.riderList || [];

            riders.forEach(rider => { 
                if (rider.riderName === riderName) {
                    if (options.checkForNumbers) {
                        if (!isNaN(rider.riderValue)) hasRider = true;
                    } else if (rider.riderValue === 'yes') {
                        hasRider = true;
                    }
                }
            })

            return hasRider;
        },
        /**
         * @param {string} riderName
         * @param {RiderPrice[]} riderPrices
         */
        getRiderIndex(riderName, riderPrices) {
            if (!Array.isArray(riderPrices)) return null;
            if (!riderName) return null;
            let index = null;

            riderPrices.forEach((rider, i) => {
                if (rider.riderName === riderName) {
                    index = i;
                }
            });

            return index;
        },
        /**
         * Loops over travelersInfo and adds up total price for given rider
         */
        getRiderTotalPrice(riderName, options = {}) {
            if (!riderName) return 0;
            let totalPrice = 0;

            this.application.travelerInfos?.forEach(traveler => {
                traveler?.riderPrices?.forEach(rider => {
                    if (rider.riderName === riderName) {
                        let riderPrice = 0;

                        if (options.riderLevel) {
                            if (rider.riderLevel === options.riderLevel) {
                                riderPrice = rider.price
                            }
                        } else {
                            riderPrice = rider.price
                        }

                        if (typeof riderPrice !== 'number') riderPrice = 0;
                        totalPrice+= riderPrice;
                    }
                })
            })

            return totalPrice;
        },
        getRiderTotalPrice_carRental() {
            let totalDays = this.application.planResult.travelexRentalDurationDays;
            let totalPrice = 0;

            if (!totalDays) return 0;

            this.application.travelerInfos?.forEach(traveler => {
                let rider = this.getRider('Rental Car Damage', traveler.riderPrices)?.[0];

                if (!rider) return;
                totalPrice += (rider.price * totalDays); 
            })

            return totalPrice;
        },
        getRider(riderName, riderList) {
            if (!riderList || !Array.isArray(riderList)) return null;

            return riderList.filter(rider => rider.riderName === riderName);
        },
        getRiderValue(riderName) {
            if (!riderName) return false;
            let riderValue = null;

            /** @type {Rider[]} */
            let riders = this.application.riderList || [];

            riders.forEach(rider => { 
                if (rider.riderName === riderName) {
                    riderValue = rider.riderValue;
                }
            })

            return riderValue;
        },
        toggleAgeGroups: function(idx) {
            this.application.groupTravelersInfo[idx].showItemized = !this.application.groupTravelersInfo[idx].showItemized;
            this.$forceUpdate();
        }
    },
    mounted:function () {
        getDurationForApplications(this.plan);
        convertDateByLang(this.form,window.globalLanguage.lang)
    }

}
</script>
