<template>
    <div class="o-Field-set c-Narrow-search__field c-Narrow-search__field--two-col">
        <label :for="name" 
            @click.prevent="showModal(modalUrl)" 
            class="c-Narrow-search__field-label"
        >
            {{headingLabel}}<span class="o-Information"></span>
        </label>
        <multiselect
            :id="name"
            :class="{ 'multi-select__default-selection': defaultValue == selectedValue.value || disabled}"
            v-model="selectedValue"
            :options="optionList"
            :placeholder="defaultText"
            track-by="value"
            label="text"
            :searchable="false"
            :close-on-select="true"
            :deselect-label="allowEmpty? qResultsLang.deselectLabel : qResultsLang.selectedLabel"
            :selected-label="qResultsLang.selectedLabel"
            :select-label="qResultsLang.selectLabel"
            :disabled="disabled"
            :allow-empty="allowEmpty"
        ></multiselect>
    </div>
</template>

<script>
// import MultiSelect from 'vue-multiselect';
export default {
    components: {
        'multiselect': require('vue-multiselect').default,
    },
    props: {
        modalUrl: {
            type: String,
            default: ''
        }, 
        headingLabel: {
            type: String,
            default: ''
        }, 
        name: {
            type: String,
            default: ''
        }, 
        defaultValue: {
            type: [String, Number, Boolean],
            default: ''
        },
        defaultText: {
            type: String,
            default: ''
        }, 
        inputSelectedValue: {
            type: [String, Number, Boolean],
            default: ''
        },
        optionList: {
            type: Array,
            default: () => []
        },
        qResultsLang: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowEmpty: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        selectedValue: {
            get: function() {
                if(!this.optionList || !this.optionList.length) return {value: this.defaultValue, text: this.defaultText};
                return this.optionList.filter(item => item.value == this.inputSelectedValue)[0] || {value: this.defaultValue, text: this.defaultText};
            },
            set: function(value) {
                this.$emit('set-value', value && value.value ? value.value : '');
            }
        }
    },
    methods: {
        showModal(url){
            this.$emit('show-modal', url);
        },
        selectValue(value) {
            if(value && value.value != undefined) {
                if(value.value == this.inputSelectedValue) { //deselect
                    this.$emit('set-value', this.defaultValue);
                } else{
                    this.$emit('set-value', value.value);
                }
            }
        },
        removeValue() {
            this.$emit('set-value', this.defaultValue);
        }
    }
}
</script>