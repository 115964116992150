import Vue from 'vue';

let dynamicVueComponent = function() {
    if (this.content) {
        return {
            template: this.content,
            data () {
                return {
                }
            },
            methods: {
                showOtherModal(url) {
                    this.$parent.getAppModal(url, 'Yes');
                }
            }
        }
    }
};

export {
    dynamicVueComponent
}