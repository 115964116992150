class Trawick {
    constructor () {
        /**
         * DO NOT delete discontinued planIds from here.
         */
        /**
         * Safe_Travels_USA 461
         */
        this.Safe_Travels_USA = 461;
        /**
         * Safe_Travels_International 462
         */
        this.Safe_Travels_International = 462;
        /**
         * Safe_Travels_USA_Cost_Saver 543
         */
        this.Safe_Travels_USA_Cost_Saver = 543;
        /**
         * Safe_Travels_International_Cost_Saver 544
         */
        this.Safe_Travels_International_Cost_Saver = 544;
        /**
         * Safe_Travels_USA_Comprehensive 594
         */
        this.Safe_Travels_USA_Comprehensive = 594;
        /**
         * Safe_Travels_Single_Trip_Parent 635
         */
        this.Safe_Travels_Single_Trip_Parent = 635;
        /**
         * Safe_Travels_First_Class_Parent 636
         */
        this.Safe_Travels_First_Class_Parent = 636;
        /**
         * Safe_Travels_Outbound_Parent 655
         */
        this.Safe_Travels_Outbound_Parent = 655;
        /**
         * Safe_Travels_Outbound_Cost_Saver 656
         */
        this.Safe_Travels_Outbound_Cost_Saver = 656;
        /**
         * SafeTreker_Extreme 686
         */
        this.SafeTreker_Extreme = 686;
        /**
         * SafeTreker_Extreme_Plus 687
         */
        this.SafeTreker_Extreme_Plus = 687;
        /**
         * Safe_Travels_Voyager 689
         */
        this.Safe_Travels_Voyager = 689;
        /**
         * SafeTreker_Parent 724
         */
        this.SafeTreker_Parent = 724;
        /**
         * Safe_Travels_Explorer 725
         */
        this.Safe_Travels_Explorer = 725;
        /**
         * Safe_Travels_Explorer_Plus 726
         */
        this.Safe_Travels_Explorer_Plus = 726;
        /**
         * Safe_Travels_Journey 727
         */
        this.Safe_Travels_Journey = 727;
        /**
         * Safe_Travels_First_Class_All_Other_States 728
         */
        this.Safe_Travels_First_Class_All_Other_States = 728;
        /**
         * Safe_Travels_First_Class_Penn_Alaska_Missouri 729
         */
        this.Safe_Travels_First_Class_Penn_Alaska_Missouri = 729;
        /**
         * Safe_Travels_Elite_Parent 740
         */
        this.Safe_Travels_Elite_Parent = 740;
        /**
         * Safe_Travels_Elite_100000 740040
         */
        this.Safe_Travels_Elite_100000 = 740040;
        /**
         * Safe_Travels_Elite_175000 740050
         */
        this.Safe_Travels_Elite_175000 = 740050;
        /**
         * Safe_Travels_Elite_25000 740005
         */
        this.Safe_Travels_Elite_25000 = 740005;
        /**
         * Safe_Travels_Elite_50000 740020
         */
        this.Safe_Travels_Elite_50000 = 740020;
        /**
         * Safe_Travels_Elite_75000 740030
         */
        this.Safe_Travels_Elite_75000 = 740030;
        /**
         * Safe_Travels_Elite_Diamond 741
         */
        this.Safe_Travels_Elite_Diamond = 741;
        /**
         * Safe_Travels_Elite_Diamond_50000 741020
         */
        this.Safe_Travels_Elite_Diamond_50000 = 741020;
        /**
         * Safe_Travels_Elite_Diamond_100000 741040
         */
        this.Safe_Travels_Elite_Diamond_100000 = 741040;
        /**
         * Safe_Travels_Single_Trip_All_Other_States 756
         */
        this.Safe_Travels_Single_Trip_All_Other_States = 756;
        /**
         * Safe_Travels_Single_Trip_Penn_Alaska_Missouri 757
         */
        this.Safe_Travels_Single_Trip_Penn_Alaska_Missouri = 757;
        /**
         * Safe_Travels_Outbound_All_Other_States 758
         */
        this.Safe_Travels_Outbound_All_Other_States = 758;
        /**
         * Safe_Travels_Outbound_Penn_Missouri 759
         */
        this.Safe_Travels_Outbound_Penn_Missouri = 759;
    }

    allInAppIds () {
        return [
            this.Safe_Travels_USA,
            this.Safe_Travels_International,
            this.Safe_Travels_USA_Cost_Saver,
            this.Safe_Travels_International_Cost_Saver,
            this.Safe_Travels_USA_Comprehensive,
            this.Safe_Travels_Single_Trip_Parent,
            this.Safe_Travels_First_Class_Parent,
            this.Safe_Travels_Outbound_Parent,
            this.SafeTreker_Parent,
            this.SafeTreker_Extreme,
            this.SafeTreker_Extreme_Plus,
            this.Safe_Travels_Voyager,
            this.Safe_Travels_Explorer,
            this.Safe_Travels_Explorer_Plus,
            this.Safe_Travels_Journey,
            this.Safe_Travels_First_Class_All_Other_States,
            this.Safe_Travels_First_Class_Penn_Alaska_Missouri,
            this.Safe_Travels_Elite_Parent,
            this.Safe_Travels_Elite_100000,
            this.Safe_Travels_Elite_175000,
            this.Safe_Travels_Elite_25000,
            this.Safe_Travels_Elite_50000,
            this.Safe_Travels_Elite_75000,
            this.Safe_Travels_Elite_Diamond,
            this.Safe_Travels_Elite_Diamond_50000,
            this.Safe_Travels_Elite_Diamond_100000,
            this.Safe_Travels_Single_Trip_All_Other_States,
            this.Safe_Travels_Single_Trip_Penn_Alaska_Missouri,
            this.Safe_Travels_Outbound_All_Other_States,
            this.Safe_Travels_Outbound_Penn_Missouri
        ]
    }

    isTripInsurance () {
        return [
            this.Safe_Travels_Explorer,
            this.Safe_Travels_Explorer_Plus,
            this.Safe_Travels_Journey,
            this.Safe_Travels_First_Class_Parent,
            this.Safe_Travels_First_Class_All_Other_States,
            this.Safe_Travels_First_Class_Penn_Alaska_Missouri,
            this.Safe_Travels_Voyager,
            this.Safe_Travels_Single_Trip_Parent,
            this.Safe_Travels_Single_Trip_All_Other_States,
            this.Safe_Travels_Single_Trip_Penn_Alaska_Missouri,
            this.SafeTreker_Parent,
            this.SafeTreker_Extreme,
            this.SafeTreker_Extreme_Plus
        ];
    }

    allPlanIds () {
        return [
            this.Safe_Travels_USA,
            this.Safe_Travels_International,
            this.Safe_Travels_USA_Cost_Saver,
            this.Safe_Travels_International_Cost_Saver,
            this.Safe_Travels_USA_Comprehensive,
            this.Safe_Travels_Single_Trip_Parent,
            this.Safe_Travels_First_Class_Parent,
            this.Safe_Travels_Outbound_Parent,
            this.SafeTreker_Extreme,
            this.SafeTreker_Extreme_Plus,
            this.Safe_Travels_Voyager,
            this.SafeTreker_Parent,
            this.Safe_Travels_Explorer,
            this.Safe_Travels_Explorer_Plus,
            this.Safe_Travels_Journey,
            this.Safe_Travels_First_Class_All_Other_States,
            this.Safe_Travels_First_Class_Penn_Alaska_Missouri,
            this.Safe_Travels_Elite_Parent,
            this.Safe_Travels_Elite_100000,
            this.Safe_Travels_Elite_175000,
            this.Safe_Travels_Elite_25000,
            this.Safe_Travels_Elite_50000,
            this.Safe_Travels_Elite_75000,
            this.Safe_Travels_Elite_Diamond,
            this.Safe_Travels_Elite_Diamond_50000,
            this.Safe_Travels_Elite_Diamond_100000,
            this.Safe_Travels_Single_Trip_All_Other_States,
            this.Safe_Travels_Single_Trip_Penn_Alaska_Missouri,
            this.Safe_Travels_Outbound_All_Other_States,
            this.Safe_Travels_Outbound_Penn_Missouri
        ]
    }

    /**
     * 
     * @returns PlanIds  635, 756, 757, 724, 686, 687, 636, 728, 729
     */
    nationWidePlanIds () {
        const allPlanIds = [
            ...this.stSinglePlanIds(),
            ...this.stExtremePlanIds(),
            ...this.stFirstClassPlanIds()
        ];
        return allPlanIds;
    }

    /**
     * 
     * @returns PlanIds 655, 656, 758, 759
     */
    outboundPlanIds () {
        const allPlanIds = [
            this.Safe_Travels_Outbound_Parent,
            this.Safe_Travels_Outbound_All_Other_States,
            this.Safe_Travels_Outbound_Penn_Missouri,
            this.Safe_Travels_Outbound_Cost_Saver
        ];

        return allPlanIds;
    }

    /**
     * 
     * @returns PlanIds 636, 728, 729
     */
    stFirstClassPlanIds () {
        const allPlanIds = [
            this.Safe_Travels_First_Class_Parent,
            this.Safe_Travels_First_Class_All_Other_States,
            this.Safe_Travels_First_Class_Penn_Alaska_Missouri
        ];

        return allPlanIds;
    }

    /**
     * 
     * @returns PlanIds 635, 756, 757
     */
    stSinglePlanIds () {
        const allPlanIds = [
            this.Safe_Travels_Single_Trip_Parent,
            this.Safe_Travels_Single_Trip_All_Other_States,
            this.Safe_Travels_Single_Trip_Penn_Alaska_Missouri
        ];

        return allPlanIds;
    }

    /**
     * 
     * @returns PlanIds 725, 726, 727
     */
    stExplorePlanIds () {
        const allPlanIds = [
            this.Safe_Travels_Explorer,
            this.Safe_Travels_Explorer_Plus,
            this.Safe_Travels_Journey
        ];

        return allPlanIds;
    }

    /**
     * 
     * @returns PlanIds 686, 687, 724
     */
    stExtremePlanIds () {
        const allPlanIds = [
            this.SafeTreker_Parent,
            this.SafeTreker_Extreme,
            this.SafeTreker_Extreme_Plus
        ];

        return allPlanIds;
    }

    /**
     * 
     * @returns PlanIds 462, 544, 655, 656, 758, 759
     */
    stOutsideUSAPlanIds () {
        const allPlanIds = [
            this.Safe_Travels_International,
            this.Safe_Travels_International_Cost_Saver,
            this.Safe_Travels_Outbound_Parent,
            this.Safe_Travels_Outbound_All_Other_States,
            this.Safe_Travels_Outbound_Penn_Missouri,
            this.Safe_Travels_Outbound_Cost_Saver
        ];

        return allPlanIds;
    }
}

export default Trawick;





