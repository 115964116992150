<template>
    <ul class="o-List-bare c-Plan__select-list">

        <!-- // FEATURES LIST -->

        <!-- // US Urgent Care -->
        <po-item 
            v-if="!!plan.planBlockDetails.urgentCare"
            icon-class="c-Plan__content-icon--urgentcare"
            modal-name="/guide/terms?section=urgentcare"
            :item-name="qResultsLang.usUrgentCare"
            :item-value="plan.planBlockDetails.urgentCare"
            @show-modal="showModal"
        />

        <!-- // Walk-In Clinic -->
        <po-item 
            v-if="!!plan.planBlockDetails.walkinClinic"
            icon-class="c-Plan__content-icon--clinic"
            modal-name="/guide/terms?section=walkinclinic"
            :item-name="qResultsLang.walkInClinic"
            :item-value="plan.planBlockDetails.walkinClinic"
            @show-modal="showModal"
        />

        <!-- // Student Health Center -->
        <po-item 
            v-if="(plan.planId == 526 && plan.planDetails.config.studentHealthCenterCheck && showDeductibleForNavigator) || (showStudentHealthCenter && plan.planId != 526)"
            icon-class="c-Plan__content-icon--student-health-center"
            modal-name="/guide/terms?section=studenthealthcenter"
            :item-name="qResultsLang.studentHealthCenter"
            :item-value="plan.planDetails.studentHealthCenterText"
            :item-name-class="'u-White-space-nw'"
            @show-modal="showModal"
        />

        <!-- // Wellness Visit -->
        <po-item 
            v-if="!!plan.planDetails.showWellnessLink && plan.planDetails.showWellnessLink === true"
            icon-class="c-Plan__content-icon--welnessvisit"
            modal-name="/guide/terms?section=wellnessvisit"
            :item-name="qResultsLang.wellnessVisit"
            @show-modal="showModal"
        >
            <template v-slot:item-value>
                <div class="c-Plan__select-type c-Plan__select-type--text">
                    <div v-if="plan.planDetails.config.companyCode === 'Trawick'">
                        <a :href="qResultsLang.wellnessVisitUrl" target="_blank" v-if="section !== 'student' && section !== 'opt' && section !== 'j1Visa'">
                            <span>
                                {{qResultsLang.wellnessVisitText}}
                            </span>
                        </a>
                        <span v-else>
                            {{qResultsLang.wellnessVisitText}}
                        </span>
                    </div>
                    <div v-else>
                        <span>
                            {{plan.planDetails.wellness}}
                        </span>
                    </div>
                </div>
            </template>
        </po-item>

        <!-- // Evacuation -->
        <po-item 
            v-if="(isTravelOutsideSchengen && isCoverageAreaUsa !== true && section !== 'multitrip' && section !== 'groupTravelMedical') || (section === 'trip' && plan.planDetails.emergencyEvacuation !== '-') || plan.planId === 715"
            icon-class="c-Plan__content-icon--evacuation"
            :modal-name="'/guide/terms?section=emergencyevac&parentSection=' + section"
            :item-name="qResultsLang.evacuation"
            @show-modal="showModal"
        >
            <template v-slot:item-value>
                <div class="c-Plan__select-type c-Plan__select-type--text">
                    <span v-if="plan.planId == 715 || section === 'expatriate' || section === 'missionary' || section === 'marine'">{{qResultsLang.emergencyMedEvacVal}}</span>
                    <span v-else-if="section === 'trip'">{{plan.planDetails.emergencyEvacuation}}</span>
                    <span v-else>{{plan.planDetails.evacuation}}</span>
                </div>
            </template>
        </po-item>

        <!-- // Repatriation -->
        <po-item 
            v-if="(isTravelOutsideSchengen && isCoverageAreaUsa !== true && section !== 'multitrip' && section !== 'groupTravelMedical') || (section === 'trip' && plan.planDetails.repatriationOfRemains !== '-') || plan.planId === 715"
            icon-class="c-Plan__content-icon--repatriation"
            :modal-name="'/guide/terms?section=repatriationofremains&parentSection=' + section"
            :item-name="qResultsLang.repatriation"
            @show-modal="showModal"
        >
            <template v-slot:item-value>
                <div class="c-Plan__select-type c-Plan__select-type--text">
                    <span v-if="plan.planId == 715 || section === 'expatriate' || section === 'missionary' || section === 'marine'">{{qResultsLang.repatriationVal}}</span>
                    <span v-else-if="section === 'trip'">{{plan.planDetails.repatriationOfRemains}}</span>
                    <span v-else>{{plan.planDetails.returnRemains}}</span>
                </div>
            </template>
        </po-item>

        <!-- // COVID-19 -->
        <po-item 
            icon-class="c-Plan__content-icon--covid"
            :modal-name="'/guide/terms?section=covidcoverage&parentSection=' + section"
            :item-name="qResultsLang.covid"
            @show-modal="showModal"
        >
            <template v-slot:item-value>
                <div class="c-Plan__select-type c-Plan__select-type--text" v-if="section !== 'flight' && [161, 163].indexOf(plan.planId) > -1">
                    <div class="c-Plan__select-type">
                        <select class="c-Plan__select-selection" v-model="piuCovidIncluded" @change="triggerPIUchange">
                        <option class="c-Plan__select-option" :value="false">{{ qResultsLang.excluded }}</option>
                        <option class="c-Plan__select-option" :value="true">{{ qResultsLang.included }}</option>
                        </select>
                    </div>
                </div>
                <div class="c-Plan__select-type c-Plan__select-type--text" v-else-if="section !== 'flight'">
                    <div v-if="!isTravelOutsideSchengen && !hideCovidSection">
                        <span v-if="plan.covidCoverage || plan.planDetails.covidCoverage || (plan.planDetails.config && plan.planDetails.config.doesPlanCoverCovid)">
                            {{ plan.planDetails.covidCoverage || qResultsLang.included }}
                        </span>
                        <span v-else>{{ qResultsLang.excluded }}</span>
                    </div>

                    <span v-else-if="isTravelOutsideSchengen">
                        {{ plan.planDetails.config.doesPlanCoverCovid ? plan.planDetails.covidCoverage : plan.planDetails.covidCoverage ? plan.planDetails.covidCoverage : qResultsLang.excluded }}
                    </span>
                </div>
                <div class="c-Plan__select-type c-Plan__select-type--text" v-else-if="section === 'flight'">
                    <span v-if="(plan.covidCoverage || plan.planDetails.covidCoverage) && plan.planDetails.covidCoverage !== '-'">
                        {{ plan.planDetails.covidCoverage || qResultsLang.included }}
                    </span>
                    <span v-else>{{ qResultsLang.excluded }}</span>
                </div>
            </template>
        </po-item>
    </ul>
</template>

<script>
    import planBlockPriceOptionItem from './pb-po-item.vue'
    export default {
        components:{
            'po-item': planBlockPriceOptionItem
        },
        props: ['plan', 'is-coverage-area-usa', 'narrow-covid-coverage', 'piu-covid-default'],
        data: function () {
            return {
                qResultsLang: window.qResultsLang || '',
                currentLang:window.globalLanguage.lang, 
                isTravelOutsideSchengen: (window.ibJS.section === 'schengen' || window.ibJS.section === 'travelOutsideUSA' || window.ibJS.section === 'covid19' || window.ibJS.section === 'studyAbroad' || this.isCoverageAreaUsa===false ? true : false),
                retrievedQuotes: window.ibJS.retrievedQuotes,
                hideCovidSection: window.ibJS.section === 'flight' || [161, 163].indexOf(this.plan.planId) > -1,
                section: window.ibJS.section,
                studyCountry: ibJS.studyCountry,
                piuCovidIncluded: (this.piuCovidDefault || (this.narrowCovidCoverage && [161, 163].indexOf(this.plan.planId) > -1)) ? true : false,
                userSelectedCovidValue: null
            }
        },
        created: function () {
            this.$emit('piu-covid', this.piuCovidIncluded);
        },
        watch:{
            'narrowCovidCoverage'(value) {
                if (value) this.piuCovidIncluded = true;
                else if (this.userSelectedCovidValue) this.piuCovidIncluded = this.userSelectedCovidValue;
                else this.piuCovidIncluded = false;
                this.$emit('piu-covid', this.piuCovidIncluded)
            }
        },
        computed:{
            showDeductibleForNavigator: function(){
                var navigatorDeductible = true;
                if(this.section==='student' && this.studyCountry !== 'USA'){
                    navigatorDeductible = false;
                }
                return navigatorDeductible;
            },
            showStudentHealthCenter: function(){
                var showSHC = false;
                if(this.section === 'student'){
                    if(this.studyCountry === 'USA' && this.plan.planDetails.config.studentHealthCenterCheck){
                        showSHC = true;
                    }
                }else{
                    if(this.plan.planDetails.config.studentHealthCenterCheck){
                        showSHC = true;
                    }
                }
                return showSHC;
            }
        },
        methods: {
            showModal: function (url) {
                this.$emit('show-modal', url);
            },
            triggerPIUchange () {
                console.log("piu: " + this.piuCovidIncluded);
                this.userSelectedCovidValue = this.piuCovidIncluded; 
                this.$emit('piu-covid', this.piuCovidIncluded)
            }
        }
    }

</script>
