import { isInPlanList } from './helper-functions';
import PlanIds from './all-planIds';

const planIds = new PlanIds(); 

// Get plan ID with undefined/null checks
const planId = () => {
  if(window.ibJS) {
    if(ibJS.planId) {
      return ibJS.planId;
    } else if(ibJS.application && ibJS.application.planId) {
      return ibJS.application.planId;
    } else if(ibJS.extension && ibJS.extension.planId) {
      return ibJS.extension.planId;
    }
  }
  return '';
}

const hccConfig = {
  planId: planId(),
    //Plan Lists
    allExchangeGuardPlans: [...[599, 600], ...[712,713], 731, 732],
    studentSecurePlans: [...[561, 453, 454, 455], ...[718, 719, 720, 721]],
    excGuardGrpPlans: [731,732],
    international: [52, 706, 710, 708],
    //Plan List Functions
    isStudentSecure: function() { return isInPlanList(this.studentSecurePlans, this.planId) },
    isExchangeGuard: function() { return isInPlanList(this.allExchangeGuardPlans, this.planId) },
    isExchangeGuardGrp: function() { return isInPlanList(this.excGuardGrpPlans, this.planId) },
    isInternational: function() { return isInPlanList(this.international, this.planId) }
};

const trawickConfig = {
  planId: planId(),
    //Plan Lists
    tripProtectPlans: [506,583],
    nationWidePlans: planIds.trawick.nationWidePlanIds(),
    outboundPlans: planIds.trawick.outboundPlanIds(),
    voyager: [planIds.trawick.Safe_Travels_Voyager],
    outsideUSPlans: planIds.trawick.stOutsideUSAPlanIds(),
    STfrstClsPlans: planIds.trawick.stFirstClassPlanIds(),
    STSingleTrip: planIds.trawick.stSinglePlanIds(),
    explorerPlans: planIds.trawick.stExplorePlanIds(),
    outBoundCostSaver: planIds.trawick.outboundPlanIds(),
    crumForster: [461,543,556,594,462,544,740,741],
    // nationWidePlans: [655,656,634,635,636,728,729,724,756,757,758,759],
    GBG : [506, 583],
    //Plan List Functions
    isTripProtectPlans: function() { return isInPlanList(this.tripProtectPlans, this.planId) },
    isNationWidePlans: function() { return isInPlanList(this.nationWidePlans, this.planId) },
    isVoyagerPlans: function() { return isInPlanList(this.voyager, this.planId) },
    isOutboundPlans: function () { return isInPlanList(this.outboundPlans, this.planId) },
    isOutsideUSPlans: function() { return isInPlanList(this.outsideUSPlans, this.planId) },
    isSTfrstClsPlans: function() { return isInPlanList(this.STfrstClsPlans, this.planId) },
    isSTSingleTrip: function() { return isInPlanList(this.STSingleTrip, this.planId) },
    isExplorerPlans: function() { return isInPlanList(this.explorerPlans, this.planId) },
    isOutboundCost: function() { return isInPlanList(this.outBoundCostSaver, this.planId) },
    isCrumForster: function() { return isInPlanList(this.crumForster, this.planId) },
    isGBG: function () { return isInPlanList(this.GBG, this.planId) }
};

const travelInsuredConfig = {
  planId: planId(),
  //Plan Lists
  allTravelInsuredplans: [698, 699, 700, 701, 702, 703, 704, 694, 695, 696, 697, 690, 691, 692, 693],
  travelInsuredLite: [698, 699, 700, 701, 702, 703, 704],
  travelInsuredRegular: [694, 695, 696, 697],
  travelInsuredPlus: [690, 691, 692, 693],
  //Plan List Functions
  isTravelInsuredLite: function() { return isInPlanList(this.travelInsuredLite, this.planId) },
  isTravelInsuredRegular: function() { return isInPlanList(this.travelInsuredRegular, this.planId) },
  isTravelInsuredPlus: function () { return isInPlanList(this.travelInsuredPlus, this.planId) },
  isTravelInsured: function (planId) { return isInPlanList(this.allTravelInsuredplans, planId || this.planId) }
};

const IMGConfig = {
  planId: planId(),
    allSHAPlans: [532, 546],
    patriotTMPlans: [4, 21, 319, 320, 563, 751, 752, 755],
    isSHA: function () {
        return isInPlanList(this.allSHAPlans, this.planId)
    },
    isPatriotTMPlans: function () {
        return isInPlanList(this.patriotTMPlans, this.planId)
    }
};

const travelexConfig = {
    planId: planId(),
    allTravelexPlans: [361,362,662,663,664,665,666,667,668,669,671,672],
    basicPlans: [361,662,663,664,665],
    selectPlans: [362,666,667,668,669],
    flightInsurePlans: [671],
    flightInsurePlusPlans: [672],
    allTXFlightPlans: [671,672],
    isTravelex: function () {
        return isInPlanList(this.allTravelexPlans, this.planId);
    },
    isBasicPlan: function() {
        return isInPlanList(this.basicPlans, this.planId);
    },
    isSelectPlan: function() {
        return isInPlanList(this.selectPlans, this.planId);
    },
    isFlightInsure: function() {
        return isInPlanList(this.flightInsurePlans, this.planId);
    },
    isFlightInsurePlus: function() {
        return isInPlanList(this.flightInsurePlusPlans, this.planId);
    },
    isTXFlightPlan: function() {
        return isInPlanList(this.allTXFlightPlans, this.planId);
    }
};

const azimuthConfig = {
  planId: planId(),
    //Plan Lists
    international: [52, 706, 710, 708],
    //Plan List Functions
    isInternational: function() { return isInPlanList(this.international, this.planId) }
};



export {
    hccConfig,
    trawickConfig,
    IMGConfig,
    travelexConfig,
    travelInsuredConfig,
    azimuthConfig
}
